import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  computeLabel,
  isDescriptionHidden
} from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import merge from 'lodash/merge';
import { StageContext } from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

export const ArrayOfStringsControl = (props) => {
  const { 
    classNames, 
    data, 
    className, 
    visible,
    id,
    enabled, 
    uischema, 
    path, 
    handleChange, 
    label, 
    required, 
    config,
    errors,
    description
  } = props;
	const { clientData: {controlsWithDescription} } = useContext(StageContext);

  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const isValid = errors.length === 0;
  const divClassNames = [classNames.validation]
    .concat(isValid ? classNames.description : classNames.validationError)
    .join(' ');
  const showDescription = !isDescriptionHidden(
    visible,
    description,
    isFocused,
    appliedUiSchemaOptions.showUnfocusedDescription
  );
  
  const error_description = showDescription ? description : null;

  const onChange = ev => {
		const seperatedPathArray = path.split('.');
		seperatedPathArray.pop();
		const pathField = seperatedPathArray.join('.');
    setValue(ev.target.value);
		handleChange(`${pathField}.OccupancyTypeAnswer`, ev.target.selectedIndex === 0 ? undefined : ev.target.value);
  };

  const attribute_description = getAttributeDescription(path, controlsWithDescription);

  return <div
    className={classNames.wrapper}
    hidden={!visible}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
  >
    <label>
      {computeLabel(
        label,
        required,
        appliedUiSchemaOptions.hideRequiredAsterisk
      )}
    </label>
    {attribute_description && <p className='description'>{description}</p>}
    <select
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={uischema.options && uischema.options.focus}
      value={value}
      onChange={onChange}
    >
      {
        [<option value={null} key={'jsonforms.enum.none'}></option>]
          .concat(
            data?.map((optionValue, i) =>
              (
                <option value={optionValue} label={optionValue} key={optionValue + '_' + i}/>
              )
            )
          )}
    </select>
    <div className={divClassNames}>
      {!isValid ? errors : error_description}
    </div>
  </div>;
};
ArrayOfStringsControl.propTypes = {
  classNames: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  config: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withVanillaControlProps(
  withJsonFormsEnumProps(ArrayOfStringsControl)
);
