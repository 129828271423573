import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from './styles';

const ParagraphCustom = ({
  themeStyles,
  children,
  textAlign = 'left',
  bold,
  color,
  smallSize,
  className = 'p3',
}) => {
  return (
    <Paragraph style={themeStyles} textAlign={textAlign} className={className} bold={bold} color={color} smallSize={smallSize}>
      {children}
    </Paragraph>
  );
};
ParagraphCustom.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  textAlign: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bold: PropTypes.bool.isRequired,
  smallSize: PropTypes.bool,
};
export default ParagraphCustom;
