import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {getCarTrim} from 'api/IXWidgetApi';
import FormContext from '../../FormContext/FormContext';
import VehicleSelect from './VehicleSelect';
import { StyledForm } from 'shared/ui';
import VehicleInput from './VehicleInput';
import {VehiclesContext} from '../../FormContext/VehiclesContext';

const VehicleTrimControl = ({data, label, required, id, handleChange, path, errors}) => {
	const [trimData, setTrimData] = useState([{name: '', description: '', id: ''}]);
	const { data: { Year: modelYear, Make: modelMakes, Model: model } } = useContext(FormContext);
	const { setVehicles } = useContext(VehiclesContext);
	useEffect(() => {
		const getTrimsData = async () => {
			try {
				const response = await getCarTrim({make: modelMakes.toLowerCase(), model: model.toLowerCase(), year: modelYear});
				setTrimData(response.data && response.data.data.length > 0 ?  [{name: '', description: '', id: ''}, ...response.data.data] : []);
			} catch (e) {
				setTrimData([{description: '', name: '', id: ''}, {description: '',name: data || '', id: ''}]);
				handleChange(path, data || undefined);
			}
		};
		model && modelMakes && getTrimsData().then(r => r);
	}, [model, modelMakes, modelYear]);

	useEffect(() => {
		if(!model || !modelMakes) {
			setTrimData([{name: '', description: '', id: ''}]);
		}
	}, [model, modelMakes]);

	const formattedData = useMemo(() => {
		return trimData.map(item => ({name: item.description, id: item.id}));
	}, [trimData]);

	const inputChangeHandler = (event) => {
		handleChange(path, event.target.value || undefined);
	};

	const changeHandler = (event) => {
		handleChange(path, event.target.value || undefined);
		const item = trimData.find((item) => item.description === event.target.value);
		setVehicles((prevState) => ({ ...prevState, [path]: item}));
	};
	useEffect(() => {
		if(trimData.length <= 0) {
			setVehicles((prevState) => {
				return Object.keys(prevState).reduce((accum, item) => {
					if(!accum[item]) {
						accum[item] = prevState[item];
					}
					return accum;
				}, prevState);
			});
			handleChange(path, trimData[0]?.name);
			return;
		} else {
			handleChange(path, data || undefined);
		}
		const element = trimData.find((item) => item.description.toLowerCase() === data?.toLowerCase());
		if(!element) {
			setVehicles((prevState) => ({ ...prevState, [path]: trimData[0]}));
		}
	}, [trimData]);
	return (
		<div>
			{formattedData.length > 0 ?
				<VehicleSelect label={label} changeHandler={changeHandler} id={id} required={required} data={formattedData} value={data} errors={errors} path={path}/>
				:
					<StyledForm showWarnings={false}>
						<VehicleInput name={name} id={id} required={required} data={data} label={label} onChange={inputChangeHandler} errors={errors} path={path}/>
					</StyledForm>
			}
		</div>
	);
};
VehicleTrimControl.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default withJsonFormsControlProps(VehicleTrimControl);
