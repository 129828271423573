import CustomVehicleTemplate from '../Templates/CustomVehicleTemplate';
import AssetsTemplate from '../Templates/AssetsTemplate';
import DefaultTemplate from '../Templates/DefaultTemplate';
import { fetchApplicationProcess } from './requests';

export const stepVariantCheck = (template) => {
	const templates = {
		vehicle: CustomVehicleTemplate,
		vehicles: AssetsTemplate,
		assets: AssetsTemplate,
		coverage: AssetsTemplate,
		coverages: AssetsTemplate,
		default: DefaultTemplate,
	};
	return templates[template];
};
export const collectResultData = (data) => {
	for (const [key, dataValue] of Object.entries(data)) {
		if (Array.isArray(dataValue)) {
			data[key] = dataValue.filter((item) => {
				if (item.checked) {
					delete item.checked;
					return item;
				}
			});
		}
	}
	return data;
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const checkApplicationProcess = async ({id, setError, onFinished, onWaitingForUserInput, onAbandoned, clientId}) => {
	const applicationprocess = await fetchApplicationProcess(id, setError, clientId);
	const applicationProcessStatus = applicationprocess.applicationProcessStatus;
	if (applicationProcessStatus === 'Finished') {
		onFinished();
	} else if (applicationProcessStatus === 'WaitingForUserInput') {
		onWaitingForUserInput();
	} else if (applicationProcessStatus === 'Abandoned') {
		onAbandoned();
	} else {
		setTimeout(() => {
			checkApplicationProcess({id, setError, onFinished, onWaitingForUserInput, onAbandoned, clientId});
		}, 400);
	}
};
