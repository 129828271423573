import React, {useContext} from 'react';
import {Interweave} from 'interweave';
import PropTypes from 'prop-types';

import {Block, Container} from 'pages/Main/styles';
import BannerImage from '../BannerImage';
import Header from '../Header/StepperHeader';

import {Button, Col, FormWrapper, MarginTopBox, Paragraph, Row, Description, Footer} from 'shared/ui';

import {StyleContext} from 'pages/Main';
import {StageContext} from '../Stages';

function UserNotQualified({
  message,
  link,
  stepName,
  toPrevStep, 
  stepNumber, 
}) {
  const { clientWidth } = useContext(StyleContext);
  const { commonStyles, clientData: { fullSize, smallSize, isMicroSite, button, heroImage, UserNotQualified } } = useContext(StageContext);

  const handleClick = () => window.open(link, '_blank');

  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
  return (
    <Container
      style={commonStyles}
      position={heroImage?.position || 'top'}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      {heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block>
        <Header stepNumber={stepNumber} handleBack={toPrevStep}/>
        <Description step={stepName} position='top'/>
        <FormWrapper commonStyles={commonStyles}>
          <div className='ix-content' style={{display: 'flex', flexDirection: 'column'}}>
            <Col>
                <>
                  <MarginTopBox className={'ix-results-message-div'}>
                    <Paragraph
                      variant={isMicroSite && clientWidth >= 470 ? 'p1' : 'p3'}
                      className={isMicroSite && clientWidth >= 470 ? 'p1' : 'p3'}
                      textAlign='center'
                      bold={isMicroSite}
                      color={commonStyles.components.paragraphs.color || '#2b2c41'}
                    >
                      <Interweave
                        content={message}
                      />
                    </Paragraph>
                  </MarginTopBox>
                  {link && <MarginTopBox className={'ix-submit-button-div'}>
                    <Row justifyContent={'center'}>
                      <Button
                        variant='contained'
                        color='secondary'
                        className='secondary'
                        onClick={handleClick}
                        text={UserNotQualified?.button?.content || button?.content || 'Get a quote'}
                        flex={button?.placement === 'fullwidth'}
                      />
                    </Row>
                  </MarginTopBox>
                  }
                  <Description step={stepName} position='bottom'/>
                </>
            </Col>
          </div>
        </FormWrapper>
        <Footer />
      </Block>
    </Container>
  );
}
UserNotQualified.propTypes = {
  toPrevStep: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

export default UserNotQualified;
