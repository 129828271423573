import React, {createContext, useLayoutEffect, useRef, useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import Stages from 'components/Stages';

export const StyleContext = createContext(null);

function MainComponent({
  widgetId,
}) {
  const widgetComponent = useRef(document.getElementById(widgetId));
  const [clientWidth, setClientWidth] = useState(widgetComponent?.current?.clientWidth);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWidth);

    return () => {
      window.removeEventListener('resize', handleWidth);
    };
  }, []);

  const handleWidth = () => {
    setClientWidth(widgetComponent?.current?.clientWidth);
  };

  const data = useMemo(() => ({clientWidth}), [clientWidth]);
  return (
    <StyleContext.Provider value={data}>
      <Stages />
    </StyleContext.Provider>
  );
}
MainComponent.propTypes = {
  widgetId: PropTypes.string.isRequired,
};

export default MainComponent;
