import {generateUISchema} from './UIschemaGenerator';
import {cloneDeep} from 'lodash';

// Processes uischema to steps
export const UiSchemaToSteps = (requestedSchema, requestedUiSchema) => {
    const steps = [];
    const processedSchemas = [];
    const uiSchemaEntries = Object.entries(requestedUiSchema);

    uiSchemaEntries.forEach((entry) => {
        steps.push(entry[1]);
    });
    // split ui schema to steps
    steps.forEach((step) => {
        const stepKeys = step.elements.map((item) => {
            return item.scope.replace('#/properties/', '');
        });
        const partialFormSchema = {
            type: 'object',
            properties: {},
            required: [],
        };
        stepKeys.forEach((key) => {
            partialFormSchema.properties[key] = requestedSchema.properties[key];

        });
        processedSchemas.push(partialFormSchema);
    });

    return  [processedSchemas, steps];
};

const getRequired = (categoryFields,requestedSchema) => {
    let required = [];
    categoryFields.forEach((field) => {
        if (requestedSchema?.required && requestedSchema.required?.indexOf(field) !== -1) {
            required.push(field);
        }
    });
    return required;
};

const getDefinitionKey = (value) => {
    const definitions = value?.$ref;
    return definitions?.replace('#/definitions/','');
};
const getDefinitions = (definition_key, requestedSchema) => {
    return requestedSchema.definitions[definition_key];
};

const renderProperties = (schema, properties) => {
    if(properties) {
        for (const [key, value] of Object.entries(properties)) {
            if (value.$ref) {
                const definition_key = getDefinitionKey(value);
                let definitions = getDefinitions(definition_key, schema);
                properties[key] = definitions;
                delete schema.definitions[definition_key];
                if (properties[key]?.properties) {
                    renderProperties(schema, properties[key].properties);
                } else if (properties[key]?.type?.includes('object') && !properties[key]?.properties) {
                    delete properties[key];
                }
            } else if (value.type?.includes('array')) {
                if (value.items.$ref) {
                    const definition_key = getDefinitionKey(value.items);
                    let definitions = getDefinitions(definition_key, schema);
                    properties[key].items = definitions?.title ? definitions : {
                        ...definitions,
                        type: 'object'
                    };
                    delete schema.definitions[definition_key];
                    if (properties[key]?.items?.properties) {
                        renderProperties(schema, properties[key].items?.properties);
                    } else if (properties[key]?.items?.type?.includes('object') && !properties[key]?.items?.properties) {
                        delete properties[key];
                    }
                }
            }
        }
    }
};
const generateSchema = (schema) => {
    const requestedSchema = cloneDeep(schema);
    const stepSchema = {
      type: 'object',
      properties: {},
      required: schema.required,
    };

    renderProperties(requestedSchema, requestedSchema.properties);

    for (const [key, value] of Object.entries(requestedSchema.properties)) {
        if (value.type?.includes('object') && !Object.keys(value.properties).length) {
            delete requestedSchema.properties[key];
        }
        if (value.type?.includes('array') && !value.items) {
            delete requestedSchema.properties[key];
        }
    }
    stepSchema.properties = requestedSchema.properties;

    return stepSchema;
  };
// Processes schema to steps and generate uischemas;
export const schemaToSteps = (requestedSchema, categories, labelPlacement, layout) => {
    let processedSchemas, processedUiSchemas;

    // generated Schema, based on requestedSchema; with fixed definitions properties
    const schema = generateSchema(requestedSchema);


    if(categories) {
        const categoryEntries = Object.entries(categories);
    
        // schema with attributes from stepCategories
        categoryEntries?.forEach(([categoryName, categoryFields]) => {
            const stepSchema = {
                stepName: categoryName,
                type: 'object',
                properties: {},
                definitions: requestedSchema.definitions,
                required: getRequired(categoryFields, schema),
            };

            categoryFields.forEach(field => {
                if (schema.properties[field]) {
                    stepSchema.properties[field] = schema.properties[field];
                }
            });

            if (categoryEntries.length === 1 || Object.keys(stepSchema.properties).length) processedSchemas = {... stepSchema};
            requestedSchema.properties = schema.properties;
        });
    } else {
        const stepSchema = {
          stepName: 'stepName',
          type: 'object',
          properties: schema.properties,
          definitions: requestedSchema.definitions,
          required: requestedSchema.required,
        };
        processedSchemas = {... stepSchema};
        requestedSchema.properties = schema.properties;
    }
    
    processedUiSchemas = generateUISchema(processedSchemas, labelPlacement, layout);

    return [processedSchemas, processedUiSchemas];
};
