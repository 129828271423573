import {
  getSchemas,
  postApplicationForms,
  getDynamicSchema,
  getSystemGlossary,
  getAppFormWorkflows,
  getApplicationProcess,
  patchApplicationForm,
  getWorkflowResult,
  getBackApplicationProcess,
  postContactInformation,
  postWidgetDeploys
} from 'api/IXWidgetApi';

export const getFormSchema = async (params, setError, clientId, setDefault) => {
  try {
    const res = await getSchemas(params, clientId);
    const schema = res?.data?.schema;
    const id = res?.data?.id;
    return await extendSchemaWithSystemGlossary(schema, setError, id, clientId);
  } catch (e) {
    setDefault(true);
  }
};
export const recordWidgetDeploys = async (data, setError, clientId) => {
  try {
    const res = await postWidgetDeploys(data, clientId);
    const id = res?.data?.id;
    return id;
  } catch (e) {
    setError(e.response?.data || e);
  }
};

export const sendApplicationForms = async (params, setError, cb, clientId) => {
  try {
    const { id, latestWorkflowId } = await postApplicationForms(params, {clientId}).then((res) => {
      return res.data;
    });
    if(latestWorkflowId) {
      await getAppFormWorkflows(latestWorkflowId, clientId);
    }
    return id;
  } catch (e) {
    if(e.response.data.status !== 409) {
      setError(e.response?.data || e);
    } else if(cb) {
      cb();
    }
  }
};

export const updateApplicationForm = async (
  applicationFormId,
  data,
  setError,
  clientId,
) => {
  const dataWithoutNulls = Object.keys(data).reduce((acc, curr) => {
    return data[curr] != null ? { ...acc, [curr]: data[curr] } : acc;
  }, {});
  try {
    const res = await patchApplicationForm(applicationFormId, {
      attributesData: dataWithoutNulls,
      startProcessing: true,
    }, {clientId});
    const id = res?.data?.id;
    return id;
  } catch (e) {
    setError(e.response?.data || e);
  }
};

export const fetchDynamicSchema = async (applicationFormId, setError, clientId) => {
  try {
    const res = await getDynamicSchema(applicationFormId, clientId);
    const schema = res?.data?.dynamicSchema;
    return await extendSchemaWithSystemGlossary(schema, setError, null,clientId);
  } catch (e) {
    setError(e.response?.data || e);
  }
};

export const fetchApplicationProcess= async (applicationFormId, setError, clientId) => {
  try {
    const res = await getApplicationProcess(applicationFormId, clientId);
    const data = res?.data;
    return data;
  } catch (e) {
    setError(e.response?.data || e);
  }
};
export const fetchBackApplicationProcess= async (applicationFormId, setError, clientId) => {
  try {
    const res = await getBackApplicationProcess(applicationFormId, undefined,clientId);
    const data = res?.data;
    return data;
  } catch (e) {
    setError(e.response?.data || e);
  }
};

const fetchSystemGlossary = async (id, setError, clientId) => {
  try {
    const response = await getSystemGlossary(id, clientId).then((res) => {
      return res.data;
    });
    const arrValues = [];
    response.values.map((item) => {
      arrValues.push(item.actualValue);
    });
    return arrValues;
  } catch (e) {
    setError(e.response?.data || e);
  }
};

const extendSchemaWithSystemGlossary = async (schema, setError, id, clientId) => {
  try {
    for (const field of Object.values(schema?.properties)) {
      if (field.SystemFieldId) {
        const glossaryArr = await fetchSystemGlossary(field.SystemFieldId, setError, clientId);
        field.enum = glossaryArr;
      }
    }
    return id ? { schema, id } : schema;
  } catch (e) {
    setError(e.response?.data || e);
  }
};

export const fetchResults = async (workflowResultsId, setError, clientId) => {
  try {
    const res = await getWorkflowResult(workflowResultsId, clientId);
    const workflowResult = res?.data?.workflowResult;
    if(workflowResult) {
      return workflowResult;
    } else {
      setError('workflowResult is null');
    }
  } catch (e) {
    setError(e.response?.data || e);
  }
};

export const sendContactInformation = async (data, setError, clientId) => {
  try {
    const res = await postContactInformation(data, clientId);
    const id = res?.data?.id;
    if(id) {
      return id;
    }
  } catch (e) {
    setError(e.response?.data || e);
  }
};
