import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const StyledForm = styled.div`
  label {
    font-family: ${(props) => props.style?.components.headings.fontFamily};
    color: ${(props) => props.style?.components?.label?.color || props.headingsColor || props.style?.components.headings.color};
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.style?.components?.label?.fontSize};
    line-height: 24px;
    display: block;
    margin-bottom: 5px;

    @media screen and (min-width: 1921px) {
      font-size: ${(props) => px2vw(props.style?.components.label.fontSize || '15px')};
      line-height: ${px2vw(24)};
      margin-bottom: ${px2vw(5)};
    }
  }

  .description {
    font-family: ${(props) => props.style?.components.headings.fontFamily};
    color: ${(props) => props.style?.components?.label?.color || props.headingsColor || props.style?.components.headings.color};
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    display: block;
    margin-bottom: 5px;
    margin-top: -5px;

    @media screen and (min-width: 1921px) {
      font-size: ${(props) => px2vw(props.style?.components.label.fontSize || '15px')};
      line-height: ${px2vw(24)};
      margin-bottom: ${px2vw(5)};
    }
  }

  input[type='password'],
  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  select {
    background: ${(props) => props.style?.components.input.background};
    border-radius: ${(props) => props.style?.components.input.borderRadius};
    font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
    color: ${(props) => props.style?.components.input.color};
    min-height: ${(props) => props.style?.components.input.minHeight || '42px'};
    border: ${(props) => props.style?.components.input.border};
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.style?.components.input.fontSize};
    width: 100%;
    padding: ${(props) => props.style?.components.input.padding};
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;

    @media screen and (min-width: 1921px) {
      border-radius: ${(props) => px2vw(props.style?.components.input.borderRadius)};
      min-height: ${px2vw(42)};
      border: ${px2vw(1)} solid #b9b9c9;
      font-size: ${(props) => px2vw(props.style?.components.input.fontSize)};
      line-height: ${px2vw(20)};
      padding: ${(props) => px2vw(props.style?.components.input.padding)}
    }

    &:focus {
      border-color: ${(props) => props.style?.colors.secondary};
      -webkit-box-shadow: ${(props) => props.style?.components.input.boxShadow};
      box-shadow: ${(props) => props.style?.components.input.boxShadow};
      outline: 0;
    }

    &::placeholder {
      font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
      color: ${(props) => props.labelPlacement === 'top' ? 'transparent' : props.style?.components.input.color};
      font-size: ${(props) => props.style?.components.input.fontSize};

      @media screen and (min-width: 1921px) {
        font-size: ${(props) => px2vw(props.style?.components.input.fontSize)};
      }
    }

    &.validate.invalid {
      border-color: ${(props) => props.style?.colors.danger};
      -webkit-box-shadow: ${(props) =>
              props.style?.components.input.dangerShadow};
      box-shadow: ${(props) => props.style?.components.input.dangerShadow};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  select {
    background-image: ${(props) => props.style?.components.select.image};
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;

    @media screen and (min-width: 1921px) {
      background-position: calc(100% - ${px2vw(12)}) center;
    }
  }

  textarea {
    background: ${(props) => props.style?.components.input.background};
    border-radius: ${(props) => props.style?.components.input.borderRadius};
    font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
    color: ${(props) => props.style?.components.headings.color};
    border: 1px solid #b9b9c9;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    max-width: 100%;
    padding: 12px 14px;
    box-sizing: border-box;
    outline: none;

    @media screen and (min-width: 1921px) {
      border-radius: ${(props) => px2vw(props.style?.components.input.borderRadius)};
      border: ${px2vw(1)} solid #b9b9c9;
      font-size: ${px2vw(15)};
      line-height: ${px2vw(24)};
      padding: ${px2vw('12px 14px')};
    }

    &:focus {
      outline: 0;
      border-color: ${(props) => props.style?.colors.secondary};
      -webkit-box-shadow: ${(props) => props.style?.components.input.boxShadow};
      box-shadow: ${(props) => props.style?.components.input.boxShadow};
    }
  }

  .horizontal-layout {
    display: flex;
    width: calc(100% + 12px);
    margin-left: -12px;

    @media screen and (min-width: 1921px) {
      width: calc(100% + ${px2vw(12)});
      margin-left: -${px2vw(12)};
    }
  }
  .horizontal-layout-1 {
      padding-left: 10px;

      @media screen and (max-width: 500px) {
        padding-left: 0;
      }

      @media screen and (min-width: 1921px) {
          padding-left: ${px2vw(10)};
      }
  }
  .horizontal-layout-2 {
    width: 50%;
    padding-left: 10px;

    @media screen and (min-width: 1921px) {
      padding-left: ${px2vw(10)};
    }
  }

  .horizontal-layout-3 {
    width: 33.3%;
    padding-left: 10px;

    @media screen and (max-width: 470px) {
      padding-left: 0;
    }
    
    @media screen and (min-width: 1921px) {
      padding-left: ${px2vw(10)};
    }
  }

  @media screen and (max-width: 500px) {
    .horizontal-layout {
      flex-direction: column;
      margin: 0;
      width: 100%;
    }

    .horizontal-layout-2 {
      width: 100%;
      padding-left: 0;
    }
  }

  .control {
    margin-bottom: 8px;
    text-align: left;

    @media screen and (min-width: 1921px) {
      margin-bottom: ${px2vw(8)};
    }
  }

  .validation {
    color: ${(props) => props.style?.colors.danger};
    font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: left;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(12)};
      line-height: ${px2vw(16)};
    }
  }
  .horizontal-layout-item > div{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .horizontal-layout-item .valid + .validation_error {
    display: none;
  }

  .horizontal-layout-item, .vertical-layout-item {
    margin-bottom: 5px;
    width: 100%;

    @media screen and (min-width: 1921px) {
      margin-bottom: ${px2vw(5)};
    }

    .input-description {
      display: none;
    }

  }

  .vertical-layout-item {
    margin-bottom: ${(props) => props.clientWidth < 470 ? '0' : '5px'};

    @media screen and (min-width: 1921px) {
      margin-bottom: ${px2vw(5)};
    }
  }

  .radio {
    display: block;
  }

  ${(props) =>
          props.clientWidth < 470 &&
          `
    .horizontal-layout{
      flex-wrap: wrap;
    }
    .horizontal-layout-item{
      width: 100%;
    }
  `}
  
  &.fullSize {
    .horizontal-layout-2 {
      @media screen and (min-width: 1921px) {
        padding-left: ${px2vw(20)};
      }
    }

    @media screen and (max-width: 500px) {
      .horizontal-layout-2 {
        padding-left: 0;
      }
    }
  }

  &.smallSize {
    input[type='password'],
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    select {
      min-height: 36px;
    }
  }
`;
