import React, {useContext} from 'react';

import ErrorPageTemplate from './ErrorPageTemplate';

import {ThemeProvider} from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

function ErrorPage(props) {
  const { commonStyles, clientData: { theme, labelPlacement } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const customizedTheme = materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  });

  return theme === 'material'
    ? (
      <ThemeProvider theme={customizedTheme}>
        <ErrorPageTemplate {...props} />
      </ThemeProvider>
    ) : (
      <ErrorPageTemplate {...props} />
    );
}

export default ErrorPage;
