import React from 'react';
import PropTypes from 'prop-types';
import { Form } from './styles';

const ThemedForm = ({
  themeStyles,
  children,
  className,
}) => {
  return (
    <Form
      style={themeStyles}
      className={className}
      data-testid="form-wrapper"
    >
      {children}
    </Form>
  );
};
ThemedForm.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
export default ThemedForm;
