import React, {useContext, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {Wrapper} from './styles';
import FormContext from '../../FormContext/FormContext';
import {StageContext} from '../../Stages';
import {Input, InputLabel} from '@mui/material';
import {useDebounce} from 'utils/useDebounce';
import {validateEmail} from 'api/IXWidgetApi';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';

const EmailControl = ({ data, handleChange, path, id, label, uischema, required }) => {
	const { errors, showValidation, setAdditionalErrors, setErrors } = useContext(FormContext);
	const {clientData: {theme}} = useContext(StageContext);
	const debouncedValue = useDebounce(data, 500);

	const filterxErrors = item => item.keyword !== path;

	useEffect(() => {
		const checkValidEmail = async () => {
			const newError = {
				instancePath: `/${path}`,
				message: 'Not valid email',
				schemaPath: '',
				keyword: path,
				params: {},
			};
			try {
				const validEmailResponse = await validateEmail({email: debouncedValue});
				if(validEmailResponse.data?.result?.verdict === 'Invalid' && validEmailResponse.data.result?.checks?.domain?.is_suspected_disposable_address) {
					setAdditionalErrors((prevErrors) => {
						const errors = prevErrors.filter(filterxErrors);
						return [...errors, newError];
					});
				} else {
					setAdditionalErrors((prevErrors) => prevErrors.filter(filterxErrors));
					setErrors((prevErrors) => prevErrors.filter(filterxErrors));
				}
			} catch (e) {
				setAdditionalErrors((prevErrors) => {
					const errors = prevErrors.filter(filterxErrors);
					return [...errors, newError];
				});
			}
		};
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if(debouncedValue?.match(validRegex)) {
			checkValidEmail().then(r => r);
		}
	}, [debouncedValue]);
	const handleSelfChange = (event) => {
		handleChange(path, event.target.value);
	};
	const currentError = useMemo(() => {
		return errors.length > 0 ? errors.find((item) => item.keyword === path || item.params?.missingProperty === path || item.instancePath	=== `/${path}`) : null;
	}, [errors]);
	return (
		<Wrapper id={id}>
			{theme === 'material' && (
				<>
					{uischema.labelPosition === 'top' &&
						<InputLabel htmlFor={`${id}-input`}>{label}{required && '*'}</InputLabel>}
					<Input
						type="text"
						id={`${id}-input`}
						placeholder={label}
						className={currentError && showValidation ? 'validate invalid input' : 'validate valid input'}
						value={data || ''}
						onChange={handleSelfChange}
					/>
					{currentError && <ErrorMessage errors={currentError.message} path={path}/>}
				</>
			)}
			{theme !== 'material' && (
				<>
					{uischema.labelPosition === 'top' && <label htmlFor={`${id}-input`}>{label}{required && '*'}</label>}
					<input
						type="text"
						id={`${id}-input`}
						placeholder={label}
						className={currentError && showValidation ? 'validate invalid input' : 'validate valid input'}
						value={data || ''}
						onChange={handleSelfChange}
					/>
					{currentError && <ErrorMessage errors={currentError.message} path={path}/>}
				</>
			)}
		</Wrapper>

	);
};
EmailControl.propTypes = {
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default withJsonFormsControlProps(EmailControl);
