import styled from 'styled-components';

export const Group = styled.div`
  margin: auto;
  display: flex;
  border-radius: var(--36, 36px);
  border: 1px solid rgba(140, 157, 167, 0.20);
  background: var(--Grey-Blue, #DDE5EA);
`;
export const TabButton = styled.button`
  color: var(--Text-field, #8C9DA7);
  font-family: ${(props) => props.style?.components?.headings?.fontFamily};
  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  line-height: 140%; /* 14px */
  letter-spacing: 0.2px;
  border-radius: var(--36, 36px);
  background: transparent;
  padding: 5px 20px;
  cursor: pointer;
  outline: none;
  border: none;
  &.active {
    color: var(--grey-white, #FFF);
    background: var(--Bg-color-blue, #007DD7);
    font-weight: 700;
  }
`;

