import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { CheckboxControl, Label } from './styles';

import {StageContext} from '../../Stages';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';

const BooleanIconControl = ({ data, handleChange, path, id, label, errors, ...props }) => {
	const {commonStyles, clientData } = useContext(StageContext);
	const valuesProps = clientData?.BooleanIconControl?.valuesProps;
    return (
        <CheckboxControl id={id} className='control' style={commonStyles}>
            <Label htmlFor={`${id}-input`} style={commonStyles}>
                {valuesProps && <img src={data ? valuesProps[label]?.activeIcon : valuesProps[label]?.icon } alt={`${label} icon`} />}
                <input
                    type="checkbox"
                    id={`${id}-input`}
                    className={errors ? 'validate invalid input' : 'validate valid input'}
                    value={!!data}
                    onChange={() => handleChange(path, !data)}
                    {...props}
                />
            </Label>
			{errors && <ErrorMessage errors={'Is Required field'} path={path} /> }
        </CheckboxControl>

    );
};
BooleanIconControl.propTypes = {
  data: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
};
export default withJsonFormsControlProps(BooleanIconControl);
