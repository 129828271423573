import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { maxBy, merge } from 'lodash';
import { DispatchCell, withJsonFormsControlProps } from '@jsonforms/react';
import { isDescriptionHidden, NOT_APPLICABLE, rankWith, } from '@jsonforms/core';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';
import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

const LabelTextControl = (
    {
        path,
        label,
        classNames,
        description,
        id,
        errors,
        uischema,
        schema,
        rootSchema,
        visible,
        enabled,
        cells,
        config,
        required,
        data,
    }
) => {
	const { clientData: {controlsWithDescription} } = useContext(StageContext);
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const showDescription = !isDescriptionHidden(
        visible,
        description,
        false,
        appliedUiSchemaOptions.showUnfocusedDescription
    );
    const labelPlacement = uischema.labelPosition;

    const testerContext = {
        rootSchema: rootSchema,
        config: config
    };

    const attribute_description = getAttributeDescription(path, controlsWithDescription);

    // Filter schema values from null value
    if (schema.enum) {
        const result = schema.enum.filter((value) => {
            if (value) {
                return value;
            }
        });
        schema.enum = result;
    }

    const cell = maxBy(cells, r => r.tester(uischema, schema, testerContext));

    if (
        cell === undefined ||
        cell.tester(uischema, schema, testerContext) === NOT_APPLICABLE
    ) {
        console.warn('No applicable cell found.', uischema, schema);
        return null;
    } else {
        return (
            <div
                className={classNames?.wrapper}
                hidden={!visible}
                id={id}
            >
                {labelPlacement === 'top' && <label
                    htmlFor={id + '-input'}
                >
                    {label + (required ? '*' : '')}
                </label>}
                {attribute_description && <p className='description'>{description}</p>}
                <DispatchCell
                    uischema={uischema}
                    schema={schema}
                    path={path}
                    id={id + '-input'}
                    enabled={enabled}
                />
                <ErrorMessage errors={errors} description={description} showDescription={showDescription} data={data} path={path}/>
            </div>
        );
    }
};
LabelTextControl.propTypes = {
    classNames: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    uischema: PropTypes.any.isRequired,
    schema: PropTypes.any.isRequired,
    rootSchema: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    cells: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
};

export const labelTest = () => (
    uischema
) => uischema.labelPosition === 'top' || uischema.labelPosition === 'inside';
export const LabelControlTester = rankWith(
    3,
    labelTest()
);
export default withJsonFormsControlProps(LabelTextControl);
