import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StageContext} from '../../Stages';
import {Input, InputLabel} from '@mui/material';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';

const VehicleInput = ({label, required, data, onChange, name, id, path, errors}) => {
	const { clientData: { theme } } = useContext(StageContext);
	if(theme === 'material') {
		return (
			<div>
				<InputLabel htmlFor={`${id}-input`}>{label}{required && '*'}</InputLabel>
				<Input value={data} onChange={onChange} name={name}/>
			</div>
		);
	}
	return (
		<div>
			<label htmlFor={`${id}-input`}>{label}</label>
			<input type="text" value={data} onChange={onChange} name={name}/>
			<ErrorMessage path={path} errors={errors}/>
		</div>
	);
};
VehicleInput.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VehicleInput;
