import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import AttributesAndValues from '../AttributesAndValues';
import Panel from '../Panel';
import Tabs from '../Tabs';
import {BorderedPaddingBox, Col, Divider} from 'shared/ui';

import {StageContext} from '../../Stages';

function Coverage({ attributesAndValues }) {
  const [data, setData] = useState({});
  const [additionalData, setAdditionalData] = useState();
  const [coverageData, setCoverageData] = useState({});
  const [active, setActive] = useState(0);

  const { clientData: { templateAttributes, border = true, template } } = useContext(StageContext);

  useEffect(() => {
    let сoverageData;
    const сoveragePlans = attributesAndValues.CoveragePlans;
    if (сoveragePlans) {
      const value = сoveragePlans.Value[active];
      сoverageData = generateData(value);
      setCoverageData(сoverageData.newCoverageData);
      setData(сoverageData.newData);
    }

  }, [active]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const {CoveragePlans, ...otherAttributes} = attributesAndValues;
    if(otherAttributes && Object.keys(otherAttributes).length > 0) {
      setAdditionalData(otherAttributes);
    }
  }, [attributesAndValues]);


  const generateData = (attributes) => {
    let newData = {}, newCoverageData = {};
    for (let key in attributes) {
      if (key === 'CoveragePrice' || key === 'CoverageDuration' || key === 'CoverageType') {
        newCoverageData[key] = attributes[key];
      } else {
        newData[key] = attributes[key];
      }
    }
    return {newData, newCoverageData};
  };
  const Wrapper = border ? BorderedPaddingBox : React.Fragment;
  const coveragePlansValue = attributesAndValues?.CoveragePlans?.Value;
  return (
    <Wrapper>
      <Col alignItems={'flex-start'} data-testid="resultPage-coverage">
        {template === 'coverage' && coveragePlansValue?.length > 1 && <Tabs coveragePlans={coveragePlansValue} active={active} setActive={setActive} />}
        {coverageData?.[templateAttributes?.CoveragePrice] && <Panel
          coveragePrice={coverageData?.[templateAttributes?.CoveragePrice]}
          coverageDuration={coverageData?.[templateAttributes?.CoverageDuration]}
          coverageType={coverageData?.[templateAttributes?.CoverageType]}
        />}
        {border && <Divider />}
        <AttributesAndValues attributesAndValuesObj={data} />

        <AttributesAndValues attributesAndValuesObj={additionalData} />
      </Col>
    </Wrapper>
  );
}
Coverage.propTypes = {
  attributesAndValues: PropTypes.any.isRequired,
};
export default Coverage;
