import React, {useContext, useEffect, useState} from 'react';
import {List, ListItem} from './styles';
import {StageContext} from '../Stages';

function ProgressBar() {
  const { commonStyles, stepName, clientData: {progressBar: { barSteps }} } = useContext(StageContext);

  const [active, setActive] = useState();

  useEffect(() => {
    if(stepName) {
      barSteps.map((step, i) => {
        if (step.steps.indexOf(stepName) > -1) {
          setActive(i);
        }
      });
    }
  }, [stepName]);

  return (active === 0 || active > 0 ) && (
    <div>
      <List length={barSteps?.length} progress={active + 1} style={commonStyles} >
        {barSteps?.map(item => (
          <ListItem 
            key={item.title} 
            style={commonStyles} 
            length={barSteps?.length} 
            className={(item.steps.indexOf(stepName) > -1) && 'active'}
          >
            {item.title}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ProgressBar;
