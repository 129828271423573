import React, { useState, useEffect, useContext } from 'react';
import { Paths } from '@jsonforms/core';
import PropTypes from 'prop-types';

import { Block, StyledLink, Wrapper } from './styles';
import { Paragraph } from 'shared/ui';
import {StageContext} from '../../Stages';

import { IconButton } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

export const ArrayOption = (props) => {
  const {
		schema,
		path,
    index,
    _child,
    onChange,
    handleSelectAll,
    allChecked,
    setAllChecked,
    handleRadioCheck,
  } = props;
  const { commonStyles, clientData: {template, templateAttributes, heroImage} } = useContext(StageContext);

	const [data, setData] = useState(_child);

  useEffect(() => {
    if(_child && Object.keys(_child).length) {
      setData(_child);
    } else if (schema) {
      let form_data = {};
      for (let key in schema.properties) {
        form_data[key] = '';
      }
      setData(form_data);
    }
  }, [_child]);

  const childPath = Paths.compose(path, `${index}`);

  const handleCheck = () => {
    if (template === 'coverages') {
      setAllChecked(false);
      setData({...data, checked: !data.checked});
      onChange(childPath + '.checked', !data.checked);
    } else {
      handleRadioCheck(index);
    }
  };

  const handleAllCheck = () => {
    setAllChecked(!allChecked);
    handleSelectAll(!allChecked);
  };

  if (handleSelectAll) {
    return (
      <Wrapper key={childPath} heroImage={heroImage?.position} className={allChecked && 'checked'}>
        <IconButton onClick={handleAllCheck}>
          {allChecked ? 
            <CheckCircle sx={{ color: commonStyles.colors.secondary }} /> : 
              <RadioButtonUnchecked sx={{ color: commonStyles.components.paragraphs.color || '#2b2c41' }} />
          }
        </IconButton>
        <Block>
          <Paragraph className="coverage-name" color={commonStyles.components.paragraphs.color || '#2b2c41'}>
            Select All
          </Paragraph>
        </Block>
      </Wrapper>
    );
  }

  return (
    <Wrapper key={childPath} className={data.checked && 'checked'} style={commonStyles} heroImage={heroImage?.position}>
      <IconButton onClick={handleCheck}>
        {data.checked ? 
          <CheckCircle sx={{ color: commonStyles.colors.secondary }} /> : 
            <RadioButtonUnchecked sx={{ color: commonStyles.components.paragraphs.color || '#2b2c41' }} />
        }
      </IconButton>
      <Block>
        {schema.properties && (
          <>
            <Paragraph className="coverage-name" color={commonStyles.components.paragraphs.color || '#2b2c41'}>
              {_child[templateAttributes?.CoverageName] }
            </Paragraph>
            <Paragraph className="coverage-price" color={commonStyles.components.paragraphs.color || '#2b2c41'}>
              {_child[templateAttributes?.CoveragePrice] ? <>${_child[templateAttributes.CoveragePrice]}<span>/mo <br />+tax</span></> : _child[templateAttributes?.CoverageDescription] }
            </Paragraph>
          </>
        )}
        <StyledLink href="#" style={commonStyles}>Coverage summary</StyledLink>
      </Block>
    </Wrapper>
  );
};
ArrayOption.propTypes = {
  schema: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  _child: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  allChecked: PropTypes.any.isRequired,
  setAllChecked: PropTypes.func.isRequired,
  handleRadioCheck: PropTypes.func.isRequired,
};
export default ArrayOption;
