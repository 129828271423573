import React, {createContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import Main from 'pages/Main';

export const ClientDataContext = createContext(null);

function App({clientData, widgetId}) {
  const data = useMemo(() => ({clientData, widgetId}), [clientData, widgetId]);
  return (
    <ClientDataContext.Provider
      value={data}
    >
      <Main
        widgetId={widgetId}
      />
    </ClientDataContext.Provider>
  );
}
App.propTypes = {
  clientData: PropTypes.any.isRequired,
  widgetId: PropTypes.string.isRequired,
};
export default App;
