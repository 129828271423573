import React from 'react';
import PropTypes from 'prop-types';
import {vanillaCells, vanillaRenderers} from '@jsonforms/vanilla-renderers';

import LabelTextControl, {LabelControlTester} from '../../CustomControls/LabelControl';
import DatePickerControl, {DatePickerControlTester} from '../../CustomControls/DatePickerControl';
import EnumControl, {EnumControlTester} from '../../CustomControls/EnumControl';

function SchemaWidgetCustom(props) {
  const renderers = [
    ...vanillaRenderers,
    ...props.renderers,
    { tester: LabelControlTester, renderer: LabelTextControl },
    { tester: DatePickerControlTester, renderer: DatePickerControl },
    { tester: EnumControlTester, renderer: EnumControl},
  ];

  const ComponentTemplate = props.template;

  return (
    <ComponentTemplate
      {...props}
      jsonFormCells={vanillaCells}
      jsonFormRenderers={renderers}
    />
  );
}
SchemaWidgetCustom.propTypes = {
  renderers: PropTypes.any.isRequired,
  template: PropTypes.any.isRequired,
};
export default SchemaWidgetCustom;
