import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {East} from '@mui/icons-material';
import {Button} from './styles';
import {StyleContext} from 'pages/Main';
import {StageContext, stagesNames} from 'components/Stages';

const ButtonCustom = ({
  themeStyles,
  className,
  onClick,
  text,
  hasArrow,
  flex,
  borderRadius,
  disabled=false,
}) => {
  const { clientData: { smallSize, trackers = {} }, currentStageNumber } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const ref = useRef(null);

  const onHandleClick = () => {
    const dataEvent = trackers[`${stagesNames[currentStageNumber]}_button_click`];
    if(dataEvent?.url) {
      const img = document.createElement('img');
      img.setAttribute('src', dataEvent.url);
      ref.current.appendChild(img);
    }
    onClick();
  };

  return (
    <Button
      style={themeStyles}
      className={className}
      onClick={onHandleClick}
      smallSize={smallSize || clientWidth < 470}
      flex={flex}
      disabled={disabled}
      borderRadius={borderRadius}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={ref}>
        {text}
        {hasArrow && (
          <East
            fontSize={'small'}
            sx={{ marginLeft: '10px' }}
          />
        )}
      </Box>
    </Button>
  );
};
ButtonCustom.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  hasArrow: PropTypes.bool,
  flex: PropTypes.any.isRequired,
  borderRadius: PropTypes.any,
  disabled: PropTypes.bool,
};

export default ButtonCustom;
