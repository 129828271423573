import React, {useContext} from 'react';

import CoverageRedirectTemplate from './CoverageRedirect';

import {ThemeProvider} from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

function CoverageRedirect() {
  const { commonStyles, clientData: { theme, labelPlacement } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const customizedTheme = theme === 'material' && materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  });

  return theme === 'material'
    ? (
      <ThemeProvider theme={customizedTheme}>
        <CoverageRedirectTemplate />
      </ThemeProvider>
    ) : (
      <CoverageRedirectTemplate />
    );
}

export default CoverageRedirect;
