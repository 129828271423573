import styled from 'styled-components';
import { Title } from 'shared/ui';

export const StyledTitle = styled(Title)`
  font-size: 14px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  border-radius: var(--8, 8px);
  background: ${props => props.heroImage === 'contain' ? 'rgba(255,255,255,0.1)' : '#F5F8F9'};
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary-baby-blue, #F5F8F9);
  transition: border-color .15s ease-in-out;
  &.checked, &:hover {
    border: 1px solid var(--primary-baby-blue, #73AFF8);
    .coverage-name{
      color: ${(props) => props.style?.colors.secondary};
    }
  }

  .coverage-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.2px;
  }
  .coverage-price {
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%; /* 39.2px */
    letter-spacing: 0.2px;
    span {
      font-family: Assistant;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 80%; /* 11.2px */
      letter-spacing: 0.2px;
      display: inline-block;
    }
  }
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled.a`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: ${(props) => props.style?.colors.secondary};
`;
