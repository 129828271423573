import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Button = styled.button`
  padding: ${(props) => props.style?.components.button.padding};
  border-radius: ${(props) => props.borderRadius || props.style?.components.button.borderRadius};
  font-family: ${(props) => props.style?.components.button.fontFamily || props.style?.components.headings.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => props.style?.components.button.fontSize};
  text-align: center;
  letter-spacing: 1px;
  color: ${(props) => props.style?.components.button.color || '#ffffff'};
  order: 0;
  border: ${(props) => props.style?.components.button.border ?? 'none'};
  cursor: pointer;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  @media screen and (min-width: 1921px) {
    svg {
      width: ${px2vw(20)};
      height: ${px2vw(20)};
      margin-left: ${px2vw(10)};
    }

    padding: ${(props) => px2vw(props.style?.components.button.padding)};
    border-radius: ${(props) => px2vw(props.borderRadius || props.style?.components.button.borderRadius)};
    font-size: ${(props) => px2vw(props.style?.components.button.fontSize)};
    line-height: ${px2vw(24)};
    letter-spacing: ${px2vw(1)};
  }

  &:hover {
    opacity: 0.7;
  }

  &.secondary {
    background: ${(props) =>
      props.style?.components.button.background ||
      props.style?.colors?.secondary};
  }
  &.primary {
    background: ${(props) => props.style?.colors?.primary};
  }
  &.accent {
    background: ${(props) => props.style?.colors?.accent};
  }
  &.danger {
    background: ${(props) => props.style?.colors?.danger};
  }
  &:disabled {
    background: ${(props) => props.style?.colors?.primary};
    opacity: 0.35;
  }
  &.outline {
    background: transparent;
    color: ${(props) =>
      props.style?.components.button.color ||
      props.style?.colors?.secondary};
    border: 1px solid ${(props) => props.style?.colors?.primary};
    &.danger {
      color: ${(props) =>
        props.style?.components.button.color ||
        props.style?.colors?.danger};
      border: 1px solid ${(props) => props.style?.colors?.danger};
    }
    &:disabled {
      color: ${(props) => props.style?.colors?.primary};
      opacity: 0.35;
    }

    @media screen and (min-width: 1921px) {
      border: ${px2vw(1)} solid ${(props) => props.style?.colors?.primary};
    }
  }
  &.text {
    background: transparent;
    color: ${(props) =>
      props.style?.components.button.color ||
      props.style?.colors?.secondary};
    border: transparent;
    &:disabled {
      color: ${(props) => props.style?.colors?.primary};
    }
  }

  ${(props) =>
    props.flex && `
      width: 100%;
  `}
`;
