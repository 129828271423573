import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import {tokenParams} from 'utils/tokenParams';
import {getWidget} from 'api/IXWidgetApi';

const fontLoader = function (families) {
	let link = document.createElement('link');
	link.type = 'text/css';
	link.rel = 'stylesheet';
	document.head.appendChild(link);
	let family = '';
	families.map(item => {
		family = family + `family=${item}:wght@300;400;500;600;700&`;
	});
	link.href = `https://fonts.googleapis.com/css${families.length > 1 && '2'}?` + family + 'display=swap';
};
const renderWidget = (clientData, widgetId, container) => {
	const {styles, smallSize} = clientData;
	const smWidth = smallSize ? '300px' : '100%';
	container.style.width = clientData.width ? clientData.width : smWidth;
	container.style.height = clientData.height ? clientData.height : '100%';
	container.style.margin = 'auto';
	const root = ReactDOMClient.createRoot(container);
	let fonts = ['Poppins', 'Mulish', 'Open Sans'];
	if (styles?.components?.headings?.fontFamily) fonts.push(styles?.components?.headings.fontFamily);
	if (styles?.components?.paragraphs?.fontFamily) fonts.push(styles?.components?.paragraphs.fontFamily);
	fontLoader(fonts);
	root.render(<App clientData={clientData} widgetId={widgetId}/>);
};

const IXWidget = (widgetId, data) => {
	let clientData;
	const container = document.getElementById(widgetId);
	tokenParams.clientId = data.clientId;
	if (data.widgetId) {
		const getWidgetConfig = async () => {
			const response = await getWidget(data.widgetId);
			clientData = {...(response.data || {}), ...data};
			renderWidget(clientData, widgetId, container);
		};
		getWidgetConfig().then();
	} else {
		renderWidget(data, widgetId, container);
	}
};

if (window.MICROSITE) {
	IXWidget('ix-platform-widget',{
		'clientId': '70f8f8ad-b8b9-4205-b92f-ecd005681016',
		'productId': 'c8b231dd-b3ad-4a7e-9e40-55a43e696bb5',
		'resultRuleNames': {
			'UserQualified': 'Qualified',
			'UserNotQualified': 'Not Qualified',
			'NoMatchingData': 'No Matching Data'
		},
		'FirstStage': {
			'stepCategories': {
				'step1': [
					'QuoteInfo',
					'Person',
					'ContactInformation',
					'Underwriting'
				]
			}
		},
		'ConfirmationStage': {
			'stepCategories': {
				'step2': [
					'Underwriting'
				],
				'step3': [
					'Underwriting'
				]
			}
		},
		'isMicroSite': true,
		'fullSize': false,
		'logo': {
			'src': 'https://www.assurantrenters.com/assets/images/assurant-logo.png',
			'visible': true,
			'width': '200px',
			'height': 'auto',
			'position': 'center'
		},
		'heroImage': {
			'position': '',
			'src': ''
		},
		'emailField': true,
		'styles': {
			'components': {
				'input': {
					'fontSize': '',
					'borderRadius': '5px'
				},
				'progressBar': {
					'bar': {
						'background': '#08AEEE'
					},
					'text': {
						'fontSize': ''
					}
				},
				'headings': {
					'color': '#007dd7',
					'fontSize': '18px'
				},
				'button': {
					'borderRadius': '5px',
					'background': '#007dd7',
					'padding': '',
					'fontSize': ''
				},
				'paragraphs': {
					'fontSize': '',
					'color': '#ffffff'
				},
				'label': {
					'fontSize': '',
					'color': '#f7f5f5'
				},
				'radioGroup': {
					'type': 'button',
					'fontSize': '16px',
					'color': '#000000',
					'background': '#ffffff',
					'borderRadius': '12px',
					'checkedStyles': {
						'background': '#0874f4',
						'color': '#ffffff',
						'markBorder': '#0874f4',
						'borderRadius': '12px',
						'border': '1px solid transparent'
					},
					'border': '1px solid transparent'
				},
				'container': {
					'background': '#4b5156'
				}
			},
			'colors': {
				'secondary': '#007dd7',
				'primary': '#ecebeb'
			}
		},
		'layout': {
			'columns': 2
		},
		'ResultStage': {
			'styles': {
				'components': {},
				'colors': {}
			},
			'UserQualified': {
				'button': {
					'content': '',
					'placement': ''
				},
				'styles': {
					'components': {},
					'colors': {}
				}
			},
			'UserNotQualified': {
				'button': {
					'content': 'Learn More',
					'placement': 'fullwidth'
				},
				'styles': {
					'components': {},
					'colors': {}
				}
			},
			'footer': {
				'content': '<a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Privacy Policy</a>, <a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Do not Sell / Share my Information</a>',
				'visible': true
			}
		},
		'customControls': [
			{
				'attributeName': 'FullAddress',
				'renderer': 'AutocompleteControl'
			},
			{
				'attributeName': 'OwnAnimalInThePropertyHarmedSomeone',
				'renderer': 'RadioGroupControl'
			},
			{
				'attributeName': 'PriorLossExcludingNaturalDisaster',
				'renderer': 'RadioGroupControl'
			},
			{
				'attributeName': 'ManufacturedOrMobileHome',
				'renderer': 'RadioGroupControl'
			},
			{
				'attributeName': 'IsRentersInsuranceRequired',
				'renderer': 'RadioGroupControl'
			}
		],
		'RadioGroupControl': {
			'orientation': 'row'
		},
		'step1': {
			'labelPlacement': 'inside',
			'uiSchema': {
				'type': 'VerticalLayout',
				'elements': [
					{
						'type': 'HorizontalLayout',
						'elements': [
							{
								'type': 'Control',
								'scope': '#/properties/QuoteInfo/properties/AssetAddress/properties/FullAddress',
								'options': {
									'placeholder': 'Address'
								},
								'labelPosition': 'inside'
							}
						]
					},
					{
						'type': 'HorizontalLayout',
						'elements': [
							{
								'type': 'Control',
								'scope': '#/properties/Person/properties/FullName',
								'options': {
									'placeholder': 'Full Name'
								},
								'labelPosition': 'inside'
							}
						]
					},
					{
						'type': 'HorizontalLayout',
						'elements': [
							{
								'type': 'Control',
								'scope': '#/properties/ContactInformation/properties/Email',
								'options': {
									'placeholder': 'Email'
								},
								'labelPosition': 'inside'
							},
							{
								'type': 'Control',
								'scope': '#/properties/QuoteInfo/properties/CoverageStartDate',
								'options': {
									'placeholder': 'Coverage Start Date'
								},
								'labelPosition': 'inside'
							}
						]
					},
					{
						'type': 'HorizontalLayout',
						'elements': [
							{
								'type': 'Control',
								'scope': '#/properties/Underwriting/properties/PropertyUnderwriting/properties/PropertyType',
								'options': {
									'placeholder': 'Property Type'
								},
								'labelPosition': 'top'
							}
						]
					}
				]
			},
			'logo': {
				'src': 'https://www.assurantrenters.com/assets/images/assurant-logo.png',
				'width': '200px',
				'height': 'auto',
				'visible': true,
				'backButton': {
					'width': '',
					'height': ''
				}
			},
			'title': {
				'content': 'Let\'s get to know you better!',
				'visible': true,
				'backButton': {
					'visible': true
				},
				'position': 'left'
			},
			'button': {
				'borderRadius': '',
				'content': 'CONTINUE',
				'placement': 'fullwidth'
			},
			'footer': {
				'content': '<a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Privacy Policy</a>, <a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Do not Sell / Share my Information</a>',
				'visible': true
			},
			'description': {
				'bottom': {
					'content': '',
					'styles': {
						'fontSize': '',
						'color': '#000000',
						'textAlign': 'center'
					}
				},
				'top': {
					'styles': {
						'fontSize': '18px',
						'backgroundColor': '#ffffff',
						'color': '#000000'
					}
				}
			}
		},
		'RadioIconsControl': {
			'valuesProps': {}
		},
		'BooleanIconControl': {
			'valuesProps': {}
		},
		'CtaStage': {
			'styles': {
				'components': {},
				'colors': {}
			},
			'title': {
				'content': 'Let\'s get to know you better',
				'visible': true
			},
			'description': {
				'top': {
					'styles': {
						'textAlign': 'center'
					},
					'content': '    '
				}
			}
		},
		'CTA': false,
		'modal': false,
		'allowSetDataIfUnableToVerify': false,
		'step2': {
			'title': {
				'content': 'Let\'s get to know you better',
				'visible': true,
				'position': 'left',
				'backButton': {
					'visible': true
				}
			},
			'button': {
				'url': 'https://calm-tree-0f67cc10f.4.azurestaticapps.net/',
				'redirectUrl': '',
				'placement': 'fullwidth',
				'content': 'SEE COVERAGES'
			},
			'logo': {
				'src': 'https://www.assurantrenters.com/assets/images/assurant-logo.png',
				'width': '200px',
				'height': 'auto',
				'visible': true
			},
			'footer': {
				'content': '<a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Privacy Policy</a>, <a href=\'https://www.rein.ai/privacy-policy\' style=\'color:#40A2D8; font-size: 12px;\'>Do not Sell / Share my Information</a>',
				'visible': true
			},
			'description': {
				'bottom': {
					'content': '',
					'styles': {
						'fontSize': '',
						'textAlign': 'center'
					}
				}
			}
		},
		'progressBar': {
			'visible': true,
			'barSteps': [
				{
					'title': 'Questions',
					'steps': [
						'step1',
						'step2',
						'step3'
					]
				},
				{
					'title': 'Quote',
					'steps': [
						''
					]
				},
				{
					'title': 'Coverage',
					'steps': [
						''
					]
				},
				{
					'title': 'Checkout',
					'steps': [
						''
					]
				}
			]
		},
		'smallSize': true,
		'width': '300px',
		'height': '900px',
		'micrositeRedirect': false,
		'ErrorPage': {
			'description': 'Uh-oh something broke on our side. We\'re sorry for the inconvenience. Please reload your page and try again'
		},
		'ContactInformation': {
			'styles': {}
		},
		'step3': {
			'title': {
				'visible': true,
				'content': 'Let\'s get to know you better',
				'backButton': {
					'visible': true
				},
				'position': 'left'
			},
			'logo': {
				'src': 'https://www.assurantrenters.com/assets/images/assurant-logo.png',
				'width': '200px',
				'height': 'auto',
				'visible': true
			}
		},
		'controlsWithDescription': [
			'PriorLossExcludingNaturalDisaster'
		]
	});
} else {
	window.IXWidget = IXWidget;
}
