import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const RadioOption = styled.div`
  position: relative;
  display: block;
  margin: 0 10px 10px;
  
  label {
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
    line-height: 16px;
  }

  @media screen and (min-width: 1921px) {
    margin-bottom: ${px2vw(14)};
  }

  input[type='radio'] {
    display: none;
  }
`;
export const Label = styled.label`
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(15)};
    line-height: ${px2vw(20)};
    padding-left: ${px2vw(30)};
  }
`;
export const Wrapper = styled.div`
  &.radio {
    display: flex;
    flex-flow: wrap;
    margin: 0 -12px;
  }
`;
