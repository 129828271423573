import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, merge } from 'lodash';
import { withJsonFormsCellProps } from '@jsonforms/react';
import { IconButton, Input, InputAdornment, useTheme } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { rankWith } from '@jsonforms/core';
import { labelTest } from '../../CustomControls/LabelControl';

export const useDebouncedChange = (handleChange, defaultValue, data, path, eventToValueFunction, timeout = 300) => {
    const [input, setInput] = useState(data ?? defaultValue);
    useEffect(() => {
        setInput(data ?? defaultValue);
    }, [data]);
    const debouncedUpdate = useCallback(debounce((newValue) => handleChange(path, newValue), timeout), [handleChange, path, timeout]);
    const onChange = useCallback((ev) => {
        const newValue = eventToValueFunction(ev);
        setInput(newValue ?? defaultValue);
        debouncedUpdate(newValue);
    }, [debouncedUpdate, eventToValueFunction]);
    const onClear = useCallback(() => { setInput(defaultValue); handleChange(path, undefined); }, [defaultValue, handleChange, path]);
    return [input, onChange, onClear];
};

const eventToValue = (ev) => ev.target.value === '' ? undefined : ev.target.value;

export const MuiInputTextCell = (props) => {
    const [showAdornment, setShowAdornment] = useState(false);
    const {
        data,
        config,
        className,
        id,
        enabled,
        uischema,
        isValid,
        path,
        handleChange,
        schema,
        muiInputProps,
        inputComponent
    } = props;
    const maxLength = schema.maxLength;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    let inputProps;
    if (appliedUiSchemaOptions.restrict) {
        inputProps = { maxLength: maxLength };
    } else {
        inputProps = {};
    }

    inputProps = merge(inputProps, muiInputProps);

    if (appliedUiSchemaOptions.trim && maxLength !== undefined) {
        inputProps.size = maxLength;
    }
    const [inputText, onChange, onClear] = useDebouncedChange(handleChange, '', data, path, eventToValue);
    const onPointerEnter = () => setShowAdornment(true);
    const onPointerLeave = () => setShowAdornment(false);

    const theme = useTheme();

    const closeStyle = {
        background: theme.jsonforms?.input?.delete?.background || theme.palette.background.default,
        borderRadius: '50%'
    };

    return (
        <Input
            type={
                appliedUiSchemaOptions.format === 'password' ? 'password' : 'text'
            }
            value={inputText}
            onChange={onChange}
            className={className}
            id={id}
            disabled={!enabled}
            autoFocus={appliedUiSchemaOptions.focus}
            multiline={appliedUiSchemaOptions.multi}
            fullWidth={!appliedUiSchemaOptions.trim || maxLength === undefined}
            inputProps={inputProps}
            error={!isValid}
            placeholder={schema.title}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            endAdornment={
                <InputAdornment
                    position='end'
                    style={{
                        display:
                            !showAdornment || !enabled || data === undefined ? 'none' : 'flex',
                        position: 'absolute',
                        right: 0
                    }}
                >
                    <IconButton
                        aria-label='Clear input field'
                        onClick={onClear}
                        size='large'
                    >
                        <Close style={closeStyle}/>
                    </IconButton>
                </InputAdornment>
            }
            inputComponent={inputComponent}
        />
    );
};
MuiInputTextCell.propTypes = {
    data: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    className: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    uischema: PropTypes.any.isRequired,
    isValid: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    handleChange: PropTypes.any.isRequired,
    schema: PropTypes.any.isRequired,
    muiInputProps: PropTypes.any.isRequired,
    inputComponent: PropTypes.any.isRequired,
};

export const materialTextCellTester = rankWith(
    2, //increase rank as needed
    labelTest('inside')
);
export default withJsonFormsCellProps(MuiInputTextCell);