import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StageContext} from 'components/Stages';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {DescriptionText} from './styles';

const Description = ({position}) => {
	const { commonStyles, clientData: { labelPlacement, theme = 'default', ...otherClientData } } = useContext(StageContext);
	const { clientWidth } = useContext(StyleContext);
	const currentData = otherClientData?.description && otherClientData?.description?.[position];
	if(!currentData || !currentData.content) return null;
	const themeStyles = theme === 'material'
		? materialTheme(commonStyles, clientWidth, { labelPlacement })
		: customTheme(commonStyles)[theme];
	return (
		<DescriptionText styles={currentData.styles} paragraph={themeStyles.components.paragraphs}>
			{currentData.content}
		</DescriptionText>
	);
};
Description.propTypes = {
  position: PropTypes.string.isRequired,
};
export default Description;
