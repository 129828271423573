import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
`;

export const DropDownMenu = styled.div`
  font-family: ${(props) => props.style?.components?.paragraphs?.fontFamily || 'Poppins'};
  font-size: ${(props) => props.style?.components?.input?.fontSize || '14px'};
  text-transform: uppercase;
  border: 1px solid #b9b9c9;
  position: absolute;
  z-index: 9;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.style?.components?.input?.background || '#ffffff'};
  top: 100%;
`;

export const DropDownMenuElement = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    transition: 0.2s linear;
    background-color: rgba(141, 222, 255, 0.39);
  }
`;
