import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {useDebounce} from 'utils/useDebounce';
import {locationAutocomplete, smartyStreetsAutocomplete} from 'api/IXWidgetApi';
import {StageContext} from '../../Stages';
import {DropDownMenu, DropDownMenuElement, Wrapper} from './styles';
import FormContext from '../../FormContext/FormContext';
import {Input, InputLabel} from '@mui/material';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';
import { getAttributeDescription } from 'utils/helpers';

const AutocompleteControl = ({data, handleChange, path, id, label, uischema, required, rootSchema, description}) => {
	const {errors, showValidation, setAdditionalErrors} = useContext(FormContext);
	const {commonStyles, clientData: {theme, controlsWithDescription}} = useContext(StageContext);
	const dropdownBlock = useRef(null);
	const inputRef = useRef(null);
	const [value, setValue] = useState(data);
	const [isElementFocused, setIsElementFocused] = useState(false);
	const [addressData, setAddressData] = useState(null);
	const debouncedValue = useDebounce(value, 500);
	const handleFocusChange = (event) => {
		if (dropdownBlock.current && isElementFocused && !dropdownBlock.current.contains(event.target)) {
			setIsElementFocused(false);
		}
		if (inputRef.current && !isElementFocused && inputRef.current.contains(event.target)) {
			setIsElementFocused(true);
		}
	};
	useEffect(() => {
		addEventListener('mousedown', handleFocusChange, false);
		return () => {
			removeEventListener('mousedown', handleFocusChange, false);
		};
	}, [isElementFocused]);

	const handleSelfChange = (event) => {
		setValue(event.target.value);
		handleChange(path, event.target.value);
		if (!event.target.value) setAddressData([]);
	};

	const currentError = useMemo(() => {
		return errors.length > 0 ? errors.find((item) => item.keyword === path || item.params?.missingProperty === path || item.instancePath	=== `/${path}`) : null;
	}, [errors]);

	const filterPrevErrors = item => item.keyword !== path;

	useEffect(() => {
		const included = addressData ? addressData.find((item) => compareStrings(item, value)) : undefined;

		const newError = {
			instancePath: `/${path}`,
			message: 'Not valid address',
			schemaPath: '',
			keyword: path,
			params: {},
		};

		if (!data || !included && addressData) {
			setAdditionalErrors((prevErrors) => {
				const errors = prevErrors.filter(filterPrevErrors);
				return [...errors, newError];
			});
			return;
		}

		if (included && !included?.address.street) {
			newError.message = 'Please provide correct street or house humber';
			setAdditionalErrors((prevErrors) => {
				const errors = prevErrors.filter(filterPrevErrors);
				return [...errors, newError];
			});
			return;
		}
		setAdditionalErrors((prevErrors) => {
			return prevErrors.filter(item => {
				return item.keyword !== path;
			});
		});
	}, [data, addressData]);

	const compareStrings = (item, value) => {
		return item?.address?.label.toLowerCase().split(' ').filter((item) => !!item).sort().join(' ') ===
			value.toLowerCase().split(' ').filter((item) => !!item).sort().join(' ');
	};
	useEffect(() => {
		const getAutocompleteResult = async () => {
			try {
				const response = await locationAutocomplete(debouncedValue);
				if (response.data.items.length > 0) {
					setAddressData(response.data.items);
				} else {
					const responseSuggestions = await smartyStreetsAutocomplete(debouncedValue);
					const formattedData = responseSuggestions.data.suggestions.map((item) => ({
						address: {
							city: item.city,
							countryCode: '',
							countryName: '',
							county: '',
							houseNumber: '',
							label: item.street_line + ' ' + item.secondary + ' ' + item.city,
							postalCode: item.zipcode,
							state: '',
							stateCode: item.state,
							street: item.street_line
						},
					}));
					setAddressData(formattedData);
				}
			} catch (e) {
				setAddressData(null);
			}
		};
		if (debouncedValue) getAutocompleteResult().then(r => r);
	}, [debouncedValue]);

	const handleAddress = (data) => () => {
		setValue(data.label);
		const seperatedPathArray = path.split('.');
		const attributeName = seperatedPathArray[seperatedPathArray.length - 1];

		seperatedPathArray.pop();
		const pathField = seperatedPathArray.join('.');
		const addressData = `${data?.houseNumber ? data.houseNumber : ''} ${data.street}`.trim();
		const pathFieldValue = pathField ? `${pathField}.` : '';
		if(attributeName === 'FullAddress') {
			handleChange(path, data?.label);
		} else {
			handleChange(path, addressData);
		}

		if(checkValueExist('City')) handleChange(`${pathFieldValue}City`, data.city);
		if(checkValueExist('State')) handleChange(`${pathFieldValue}State`, data.stateCode);

		if(addressData.length > 30) {
			const newError = {
				instancePath: `/${path}`,
				message: 'Street address max allowed 30 chars',
				schemaPath: '',
				keyword: path +'/data',
				params: {},
			};
			setAdditionalErrors((prevErrors) => {
				const errors = prevErrors.filter((item) => item.keyword !== path + '/data');
				return [...errors, newError];
			});
		} else {
			if(checkValueExist('Address1')) handleChange(`${pathFieldValue}Address1`, addressData);
			setAdditionalErrors((prevErrors) => {
				return prevErrors.filter((item) => {
					return item.keyword !== path + '/data';
				});
			});
		}
		if(!data.postalCode) {
			const newError = {
				instancePath: `/${path}`,
				message: 'Not valid zipcode',
				schemaPath: '',
				keyword: path +'/zip',
				params: {},
			};

			if(checkValueExist('ZipCode')) handleChange(`${pathFieldValue}ZipCode`, undefined);
			setAdditionalErrors((prevErrors) => {
				const errors = prevErrors.filter((item) => item.keyword !== path + '/zip');
				return [...errors, newError];
			});
		} else {
			if(checkValueExist('ZipCode')) handleChange(`${pathFieldValue}ZipCode`, data.postalCode.split('-').shift());
			setAdditionalErrors((prevErrors) => {
				return prevErrors.filter((item) => item.keyword !== path + '/zip');
			});
		}
		setIsElementFocused(false);
	};
	const checkValueExist = (value) => {
		const seperatedPathArray = path.split('.');
		seperatedPathArray.pop();
		let propertyPath = rootSchema.properties;

		for (const element of seperatedPathArray) {
			if(propertyPath[element] && propertyPath[element].properties) {
				propertyPath = propertyPath[element].properties;
			}
		}

		return !!propertyPath[value];
	};
  const attribute_description = getAttributeDescription(path, controlsWithDescription);
	return (
		<Wrapper id={id} ref={dropdownBlock}>
			{theme === 'material' && (
				<>
					{uischema.labelPosition === 'top' &&
						<InputLabel htmlFor={`${id}-input`}>{label}{required && '*'}</InputLabel>}
					{attribute_description && <p className='description'>{description}</p>}
					<Input
						type="text"
						id={`${id}-input`}
						placeholder={label}
						className={currentError && showValidation ? 'validate invalid input' : 'validate valid input'}
						value={value || ''}
						ref={inputRef}
						autoComplete="off"
						onChange={handleSelfChange}
					/>
				</>
			)}
			{theme !== 'material' && (
				<>
					{uischema.labelPosition === 'top' && <label htmlFor={`${id}-input`}>{label}{required && '*'}</label>}
					{attribute_description && <p className='description'>{description}</p>}
					<input
						type="text"
						id={`${id}-input`}
						placeholder={label}
						className={currentError && showValidation ? 'validate invalid input' : 'validate valid input'}
						value={value || ''}
						autoComplete="off"
						ref={inputRef}
						onChange={handleSelfChange}
					/>
				</>
			)}
			{isElementFocused && addressData && addressData.length > 0 && (
				<DropDownMenu style={commonStyles}>{addressData.map((item) => {
					return	<DropDownMenuElement
						key={item.id ?? item.address?.label}
						onClick={handleAddress(item.address)}>
						{item.address?.label}
					</DropDownMenuElement>;
				})}</DropDownMenu>
			)}
			{currentError && <ErrorMessage errors={currentError?.message} path={path}/>}
		</Wrapper>

	);
};
AutocompleteControl.propTypes = {
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  rootSchema: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default withJsonFormsControlProps(AutocompleteControl);
