import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const CheckboxControl = styled.div`
  position: relative;
  display: block;
  width: fit-content;

  @media screen and (min-width: 1921px) {
    margin-bottom: ${px2vw(14)};
  }

  input[type='checkbox'] {
    display: none;
  }

  &:has(input[type='checkbox']:checked) {
    label:before {
      border-color: ${(props) => props.style?.colors.secondary};
    }

    label:after {
      content: '';
    }
  }
`;
export const Label = styled.label`
  font-family: ${(props) => props.style?.components.headings.fontFamily};
  cursor: pointer;
  padding-left: 30px;

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(15)};
    line-height: ${px2vw(20)};
    padding-left: ${px2vw(30)};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid ${(props) => props.style?.colors.primary};
    height: 18px;
    width: 18px;
    border-radius: ${(props) => props.style?.components.input.borderRadius};

    @media screen and (min-width: 1921px) {
      border: ${px2vw(2)} solid ${(props) => props.style?.colors.primary};
      height: ${px2vw(18)};
      width: ${px2vw(18)};
      border-radius: ${(props) => px2vw(props.style?.components.input.borderRadius)};
    }
  }

  &:after {
    position: absolute;
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid ${(props) => props.style?.colors.secondary};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media screen and (min-width: 1921px) {
      left: ${px2vw(7)};
      top: ${px2vw(2)};
      width: ${px2vw(6)};
      height: ${px2vw(12)};
      border-width: 0 ${px2vw(2)} ${px2vw(2)} 0;
    }
  }

  @media screen and (min-width: 1921px) {
    font-size: ${(props) => px2vw(props.style?.components.label.fontSize || '15px')};
    line-height: ${px2vw(24)};
    margin-bottom: ${px2vw(5)};
  }

  input[type='checkbox'] {
    display: none;
  }
`;