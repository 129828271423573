import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Group, TabButton } from './styles';
import {StageContext} from '../../Stages';

function Tabs({coveragePlans, active, setActive}) {
  const values= useContext(StageContext);
  const commonStyles = values?.commonStyles;
  return (
    <Group data-testid="resultPage-tabs">
      {coveragePlans?.map((item, i) => (
        <TabButton 
          style={commonStyles} 
          key={item.CoverageName?.Value || item.CoverageName}
          onClick={() => setActive(i)}
          className={active === i && 'active'}
        >{item.CoverageName?.Value || item.CoverageName}</TabButton>
      ))}
    </Group>
  );
}
Tabs.propTypes = {
  coveragePlans: PropTypes.any.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default Tabs;
