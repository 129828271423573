import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
import {
  Hidden,
} from '@mui/material';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs } from '@jsonforms/core';

import { Label, Wrapper } from './styles';

import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

export const BooleanGroupTester = rankWith(1000, uiTypeIs('BooleanGroup'));

const BooleanGroup = (props) => {
  const { uischema, schema, path, visible, renderers, description } = props;
  const { commonStyles, clientData: {controlsWithDescription} } = useContext(StageContext);

  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: 'column',
    visible: visible,
    uischema: uischema,
    renderers: renderers,
  };

  const attribute_description = getAttributeDescription(path, controlsWithDescription);

  return (
    <Hidden xsUp={!visible}>
        <Label style={commonStyles}>{uischema.label}</Label>
        {attribute_description && <p className='description'>{description}</p>}
        <Wrapper>
            <MaterialLayoutRenderer {...layoutProps} />
        </Wrapper>
    </Hidden>
  );
};
BooleanGroup.propTypes = {
  uischema: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  renderers: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
export default withJsonFormsLayoutProps(BooleanGroup);