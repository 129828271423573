import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleContext} from 'pages/Main';
import {StyledFooter} from './styles';
import {StageContext} from 'components/Stages';

const ThemedFooter = ({
  themeStyles,
  alignItems = 'center',
  justifyContent = 'flex-start',
  children,
}) => {
  const { clientData: { fullSize, smallSize } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const smSizeCl = smallSize ? 'smallSize' : '';
  const className = smSizeCl + ' ix-footer';

  return (
    <StyledFooter
      style={themeStyles}
      clientWidth={clientWidth}
      className={fullSize ? 'fullSize' : className}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      {children}
    </StyledFooter>
  );
};
ThemedFooter.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default ThemedFooter;
