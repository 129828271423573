const pushControlElement = (labelPlacement, scope, title) => {
    return labelPlacement ? {
        'type': 'Control',
        'scope': scope,
        'options': {
            'placeholder': title,
        },
        'labelPosition': labelPlacement,
    } : {
        'type': 'Control',
        'scope': scope,
    };
};

const generateUISchemaColums = (columns, attributeKeys, labelPlacement, properties, scope, baseSchema) => {
    let chunk;
    let arr = [];
    const columnsCount = columns && columns >= 1 && columns <= 3 ? columns : 2;
    while (attributeKeys.length > 0) {
        chunk = attributeKeys.splice(0,columnsCount);
        arr.push(chunk);
    }
    arr.forEach((key) => {
        const rowSchema = {
            'type': 'HorizontalLayout',
            'elements': [],
        };
        key.forEach((key) => {
            !properties[key]?.writeOnly && rowSchema.elements.push(pushControlElement(labelPlacement, `${scope}${key}`, properties[key]?.title));
        });
        baseSchema.elements.push(rowSchema);
    });
};

export const  generateUISchema = (schema, labelPlacement, layout) => {
	const { columns } = layout || {};
    const baseSchema = {
        'type': 'VerticalLayout',
        'elements': [],
    };
    const schemaColumns = schema?.stepName === 'vehicle' ? 1 : columns;

    const scope = '#/properties/';
    if (schema?.properties) generateMyUISchema(schema.properties, labelPlacement, schemaColumns, baseSchema, scope);
    return baseSchema;
};

const generateMyUISchema = (properties, labelPlacement, schemaColumns, baseSchema, scope) => {
    const keys = Object.keys(properties);
    const compoundAttributesKeys = [];
    const defaultAttributesKeys = [];
    keys.forEach((key) => {
        if (properties[key]?.properties) {
            compoundAttributesKeys.push(key);
        } else {
            defaultAttributesKeys.push(key);
        }
    });
    generateUISchemaColums(schemaColumns, defaultAttributesKeys, labelPlacement, properties, scope, baseSchema);
    if (compoundAttributesKeys.length) {
        compoundAttributesKeys.forEach(compoundKey => {
            const compoundAttribute = properties[compoundKey];
            const inner_scope = scope + `${compoundKey}/properties/`;
            generateMyUISchema(compoundAttribute.properties, labelPlacement, schemaColumns, baseSchema, inner_scope);
        });
    }
};

