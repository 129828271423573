import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const MarginTopBox = styled.div`
  display: ${(props) => props.display};
  width: 100%;
  margin-top: ${
    (props) =>
      props.fullSize
        ? props.fullSizeMarginTop
        : props.smallSizeMarginTop
  };

  @media screen and (min-width: 1921px) {
    margin-top: ${(props) => px2vw(
      props.fullSize
        ? props.fullSizeMarginTop
        : props.smallSizeMarginTop
    )};
  }
`;
