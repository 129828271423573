import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import fpfilter from 'lodash/fp/filter';
import fpmap from 'lodash/fp/map';
import fpflow from 'lodash/fp/flow';
import fpkeys from 'lodash/fp/keys';
import fpstartCase from 'lodash/fp/startCase';
import filter from 'lodash/filter';
import { Paths, getControlPath } from '@jsonforms/core';

import { Block, LabelElement } from './styles';
import { Paragraph } from 'shared/ui';
import EditArrayOption from './EditArrayOption';

import { IconButton } from '@mui/material';
import { EditOutlined, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

import {ClientDataContext} from '../../../App';
import {StageContext} from '../../Stages';

export const ArrayOption = (props) => {
  const {
		schema,
		path,
		getStyleAsClassName,
		childErrors,
    index,
    removeItems,
    _child,
    onChange,
  } = props;
	const { widgetId } = useContext(ClientDataContext);
	const { clientData: {heroImage} } = useContext(StageContext);

	const [openForm, setOpenForm] = useState(false);
	const [data, setData] = useState(_child);
	const [errors, setErrors] = useState(childErrors);
  const [additionalErrors, setAdditionalErrors] = useState([]);
	const [newItem, setNewItem] = useState(false);
  useEffect(() => {
    if (Object.keys(_child).length === 0) {
      setOpenForm(true);
      setNewItem(true);

      let form_data = {};
      for (let key in schema.properties) {
        form_data[key] = '';
      }
      setData(form_data);
    } else {
      setData(_child);
    }
  }, [_child]);

  useEffect(() => {
    const contentWrapper = document.getElementById(widgetId).getElementsByClassName('ix-content')[0];
    contentWrapper.style.display = openForm ? 'none' : 'block';
  }, [openForm]);

  const confirmDelete = (path, index) => {
		const p = path.substring(0, path.lastIndexOf('.'));
		removeItems(p, [index])();
  };

	const handleFormChange = ({data, errors}) => {
		setData(data);
    setErrors([...errors, ...additionalErrors]);
	};
  const childPath = Paths.compose(path, `${index}`);
  const handleUpdate = () => {
    for (let key in data) {
      onChange(childPath + '.' + key, data[key]);
    }
    if (newItem) onChange(childPath + '.checked', true);
    setOpenForm(false);
    setNewItem(false);
  };
  const handleRemove = () => {
    const contentWrapper = document.getElementById(widgetId).getElementsByClassName('ix-content')[0];
    contentWrapper.style.display = 'block';
    setOpenForm(false);
    confirmDelete(childPath, index);
  };

  const handleCheck = () => {
    setData({...data, checked: !data.checked});
    onChange(childPath + '.checked', !data.checked);
  };

  const handleBack = () => {
    if (newItem) {
      setNewItem(false);
      handleRemove();
    } else {
      setOpenForm(false);
    }
  };
  const errorsPerEntry = filter(childErrors, (error) => {
    const errorPath = getControlPath(error);
    return errorPath.startsWith(childPath);
  });
  const stepWrapper = document.getElementById(widgetId).getElementsByClassName('ix-step-wrapper')[0];
  return (
    <div key={childPath}>
      <Block error={data.checked && errorsPerEntry.length} heroImage={heroImage?.position}>
        <Paragraph>
          <IconButton onClick={handleCheck}>
            {data.checked ? <CheckCircle sx={{ color: '#009C10' }} /> : <RadioButtonUnchecked sx={{ color: '#000000' }} />}
          </IconButton>
          {schema.properties && (
            fpflow(
              fpkeys,
              fpfilter((prop) => schema.properties[prop].type !== 'array'),
              fpmap((prop) => {
                return (
                  <LabelElement key={prop}>
                    {fpstartCase(prop)}: {_child[prop]}
                  </LabelElement>
                );
              })
          )(schema.properties))}
        </Paragraph>
        <IconButton onClick={() => setOpenForm(true)}><EditOutlined sx={{ color: '#000000' }} /></IconButton>
      </Block>
      {openForm && createPortal(
        <EditArrayOption
          handleBack={handleBack}
          handleUpdate={handleUpdate}
          handleFormChange={handleFormChange}
          getStyleAsClassName={getStyleAsClassName}
          errors={errors}
          data={data}
          newItem={newItem}
          schema={schema}
          path={path}
          index={index}
          setErrors={setErrors}
          setAdditionalErrors={setAdditionalErrors}
          handleRemove={handleRemove}
        />,
        stepWrapper
      )}
    </div>
  );
};
ArrayOption.propTypes = {
  schema: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  getStyleAsClassName: PropTypes.func.isRequired,
  childErrors: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  removeItems: PropTypes.func.isRequired,
  _child: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default ArrayOption;
