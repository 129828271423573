import React from 'react';
import PropTypes from 'prop-types';

import { ImageBlock } from './styles';
import car from 'assets/car.png';

function BannerImage({
  heroImage,
  commonStyles,
  fullSize
}) {
  return (
    <ImageBlock position={heroImage?.position} style={commonStyles} fullSize={fullSize} height={heroImage?.height} width={heroImage?.width} className="ix-heroImage">
      <img alt='car' src={heroImage?.src || car} />
    </ImageBlock>
  );
}
BannerImage.propTypes = {
  heroImage: PropTypes.any,
  commonStyles: PropTypes.any.isRequired,
  fullSize: PropTypes.bool,
};
export default BannerImage;
