import React, {useContext, useEffect, useRef} from 'react';
import {StageContext, stagesNames} from '../Stages';

export const withPixelTracking = (Component) => (props) => {
	const { currentStageNumber, clientData: { trackers = {}}} = useContext(StageContext);
	const divRef = useRef(null);
	useEffect(() => {
		const dataEvent = trackers[`${stagesNames[currentStageNumber]}_impression`];
		if(dataEvent?.url) {
			const img = document.createElement('img');
			img.setAttribute('src', dataEvent.url);
			img.style.width = '0';
			img.style.height = '0';
			if(divRef.current) divRef.current.appendChild(img);
		}
	}, []);
	return (
		<div ref={divRef} style={{top: '0px', left: '0px', margin: '0px', padding: '0px', height: '100%', width: '100%', position: 'relative'}}>
			<Component {...props} />
		</div>
	);
};
