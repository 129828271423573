import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isBooleanControl, optionIs, and } from '@jsonforms/core';
import { CheckboxControl, Label } from './styles';

import {StageContext} from '../../Stages';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';

const BooleanToggleControl = ({ data, handleChange, path, id, label, errors, required, ...props }) => {
	const {commonStyles} = useContext(StageContext);
    return (
        <CheckboxControl id={id} className='control' style={commonStyles}>
            <Label htmlFor={`${id}-input`} style={commonStyles}>
                {label}{required && '*'}
                <input
                    type="checkbox"
                    id={`${id}-input`}
                    className={errors ? 'validate invalid input' : 'validate valid input'}
                    value={!!data}
                    onChange={() => handleChange(path, !data)}
                    {...props}
                />
            </Label>
            {errors && <ErrorMessage errors={'Is Required field'} path={path} /> }
        </CheckboxControl>

    );
};
BooleanToggleControl.propTypes = {
  data: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
  required: PropTypes.bool.isRequired,
};
export const BooleanToggleControlTester = rankWith(
    4,
    and(isBooleanControl, optionIs('toggle', true))
);
export default withJsonFormsControlProps(BooleanToggleControl);
