import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {materialCells, materialRenderers} from '@jsonforms/material-renderers';

import MaterialLabelControl, {materialLabelControlTester} from '../../CustomControls/MuiCustomLabelsTextControl';
import MaterialTextCell, {materialTextCellTester} from '../../CustomCells/MuiTextCell';
import MuiDatePickerControl, {MuiDatePickerControlTester} from '../../CustomControls/MuiDatePickerControl';

import {ThemeProvider} from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';

import {StyleContext} from 'pages/Main';
import {StageContext} from '../../Stages';

function SchemaWidgetMaterial(props) {
  const { commonStyles, clientData: { labelPlacement } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const customizedTheme = materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  }, 53454);

  const renderers = [
    ...materialRenderers,
    ...props.renderers,
    { tester: materialLabelControlTester, renderer: MaterialLabelControl },
    { tester: MuiDatePickerControlTester, renderer: MuiDatePickerControl },
  ];
  const cells = [
    ...materialCells,
    { tester: materialTextCellTester, cell: MaterialTextCell },
  ];

  const ComponentTemplate = props.template;

  return (
    <ThemeProvider theme={customizedTheme}>
      <ComponentTemplate
        {...props}
        jsonFormCells={cells}
        jsonFormRenderers={renderers}
      />
    </ThemeProvider>
  );
}
SchemaWidgetMaterial.propTypes = {
  renderers: PropTypes.any.isRequired,
  template: PropTypes.any.isRequired,
};
export default SchemaWidgetMaterial;
