import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import ParagraphMaterial from './Material';
import ParagraphCustom from './Custom';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const Paragraph = (props) => {
  const { commonStylesCTA, commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const selectedCommonStyles = props.useCTA ? commonStylesCTA : commonStyles;
  const themeStyles = theme === 'material'
    ? materialTheme(selectedCommonStyles, clientWidth, { labelPlacement })
    : customTheme(selectedCommonStyles)[theme];
  const ThemedParagraph = theme ==='material' ? ParagraphMaterial : ParagraphCustom;
  return <ThemedParagraph themeStyles={themeStyles} {...props} />;
};
Paragraph.propTypes = {
  useCTA: PropTypes.any,
};

export default Paragraph;
