import { useEffect, useState } from 'react';

function useEnterClick(callback, dependenciesArr = []) {
  const [isEnterKeyPressed, setIsEnterKeyPressed] = useState(false);

  const keyDownHandler = event => {
    if (event.key === 'Enter' && !isEnterKeyPressed) {
      event.preventDefault();
      setIsEnterKeyPressed(true);
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isEnterKeyPressed, ...dependenciesArr]);
}

export default useEnterClick;
