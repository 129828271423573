import styled from 'styled-components';

export const ModalBox = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
`;
export const Container = styled.div`
  width: 740px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  border: 1px solid #DADAE3;
  box-shadow: 0px 0px 10px rgba(56, 56, 116, 0.25);
  border-radius: 12px;
  max-height: 100vh;
  & > div {
    border-radius: 12px;
  }
  .ix-close-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
`;
