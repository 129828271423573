import React, {useContext} from 'react';

import {Block, Container} from 'pages/Main/styles';

import {Col, MarginTopBox, Paragraph} from 'shared/ui';
import {StyleContext} from 'pages/Main';
import BannerImage from 'components/BannerImage';
import {StageContext} from 'components/Stages';
import Header from 'components/Header/StepperHeader';

function CoverageRedirectTemplate() {
  const {
    commonStyles,
    clientData: {
      fullSize,
      smallSize,
      isMicroSite,
      heroImage,
    }
  } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';

  return (
    <Container
      style={commonStyles}
      position={heroImage?.position}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block>
        <Header
          stepNumber={1}
        />
        <div className='ix-content' style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Col justifyContent={'start'}>
            <MarginTopBox>
                <Paragraph
                  variant={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  className={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  bold={isMicroSite}
                  textAlign={'center'}
                  color={commonStyles.components.paragraphs.color || '#2b2c41'}
                >
                  We will redirect you to our coverage page where you can complete your purchase.
                </Paragraph>
                <br />
                <Paragraph
                  variant={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  className={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  bold={isMicroSite}
                  textAlign={'center'}
                  color={commonStyles.components.paragraphs.color || '#2b2c41'}
                >
                  If a new tab has not opened with your quotes, please click on the link in the pop up that may have showed up in your browser.
                </Paragraph>
            </MarginTopBox>
          </Col>
        </div>
      </Block>
    </Container>
  );
}

export default CoverageRedirectTemplate;
