import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import CTAWidgetMaterial from './Material';
import CTAWidgetCustom from './Custom';
import {ClientDataContext} from '../../App';
import {MICROSITE_URL} from '../../env_constants';

function CTAWidget({handleOpen}) {
  const { clientData: { theme }} = useContext(ClientDataContext);
  const openMicrosite = ()=> {
    const microsite_url = MICROSITE_URL;
    window.open(microsite_url, '_blank').focus();
    return null;
  };

  const Component = theme ==='material' ? CTAWidgetMaterial : CTAWidgetCustom;
  return (
    <Component
      openMicrosite={openMicrosite}
      handleOpen={handleOpen}
      open={open}
    />
  );
}
CTAWidget.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};
export default CTAWidget;
