import { patchApplicationForm } from 'api/IXWidgetApi';

export const sendApplicationForms = async (
  applicationFormId,
  data,
  setError
) => {
  try {
    const res = await patchApplicationForm(applicationFormId, {
      attributesData: data,
      startProcessing: false,
    });
    const res_data = res?.data;
    return res_data;
  } catch (e) {
    setError(e.response?.data || e);
  }
};
