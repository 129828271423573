import styled from 'styled-components';

export const DescriptionText = styled.div`
  font-family: ${(props) => props.styles?.fontFamily || props.paragraph.fontFamily};
  font-size: ${(props) => props.styles?.fontSize || props.paragraph.fontSize};
  line-height: ${(props) => props.styles?.lineHeight || props.paragraph.lineHeight};
  color: ${props => props.styles?.color || props.paragraph.color};
  font-style: normal;
	width: 100%;
  box-sizing: border-box;
	padding: ${(props) => props.styles?.padding || 0};
	margin: ${(props) => props.styles?.margin || '10px 0'};
  font-weight: ${(props) => props.styles?.fontWeight || 400};
  text-align: ${(props) =>  props.styles?.textAlign || 'left'};
  background-color: ${(props) =>  props.styles?.backgroundColor || 'none'};
`;
