import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {getCarEngine} from 'api/IXWidgetApi';
import FormContext from '../../FormContext/FormContext';
import VehicleSelect from './VehicleSelect';
import {VehiclesContext} from '../../FormContext/VehiclesContext';

const FuelTypes = [{engine_type: 'gas', id: 1}, {engine_type: 'hybrid', id: 2}, {
	engine_type: 'diesel',
	id: 3
}, {engine_type: 'electric', id: 4}];

const VehicleFuelControl = ({data, label, required, id, handleChange, path, errors}) => {
	const [engineData, setEngineData] = useState([{engine_type: '', id: ''}]);
	const {
		data:
			{
				Year: modelYear,
				Make: modelMakes,
				Model: model,
			}
	} = useContext(FormContext);
	const { vehicles } = useContext(VehiclesContext);
	useEffect(() => {
		const getEnginesData = async () => {
			try {
				const response = await getCarEngine({
					make: modelMakes.toLowerCase(),
					model: model.toLowerCase(),
					year: modelYear,
					...({trim: vehicles?.Trim?.id
							|| null})
				});
				setEngineData(response.data && response.data.data.length > 0 ? [{engine_type: '', id: ''}, ...response.data.data] : FuelTypes);
			} catch (e) {
				setEngineData([{engine_type: '', id: ''}, {engine_type: data}]);
				handleChange(path, data || undefined);
			}
		};
		modelYear && modelMakes && model && getEnginesData().then(r => r);
	}, [modelMakes, modelYear, model, vehicles]);

	useEffect(() => {
		if(!model || !modelMakes) {
			setEngineData([{engine_type: '', id: 'None'}]);
		}
	}, [model, modelMakes]);

	const formattedData = useMemo(() => {
		const newData = engineData.reduce((accum, item) => {
			accum[item.engine_type] = item;
			return accum;
		}, {});
		return Object.keys(newData).map(item => ({name: newData[item].engine_type, id: newData[item].id}));
	}, [engineData]);

	const changeHandler = (event) => {
		handleChange(path, event.target.value || undefined);
	};
	useEffect(() => {
		!data && engineData.length > 0 && handleChange(path, engineData[0].engine_type || undefined);
	}, [engineData]);
	return (
		<div>
			<VehicleSelect label={label} changeHandler={changeHandler} id={id} required={required} data={formattedData} value={data} errors={errors} path={path}/>
		</div>
	);
};
VehicleFuelControl.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withJsonFormsControlProps(VehicleFuelControl);
