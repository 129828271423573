import {API_PATH, VALIDATION_EMAIL_KEY, STORAGE_PATH} from '../env_constants';

export const defaultHeader = {
  baseURL: API_PATH,
  headers: {
    ['Accept-Language']: 'en_US',
    ['Content-Type']: 'application/x-www-form-urlencoded',
  },
};

export const herePlatformHeader = {
  baseURL: 'https://autocomplete.search.hereapi.com/v1/',
};

export const sendGridHeader = {
  baseURL: 'https://api.sendgrid.com',
  headers: {
    Authorization: `Bearer ${VALIDATION_EMAIL_KEY}`
  }
};
export const carApiHeader = {
  baseURL: 'https://carapi-proxy.azurewebsites.net',
};

export const storageHeader = {
  baseURL: STORAGE_PATH,
};
