import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/icon';
import { StyledLogo } from './styles';

function Logo({ logo, heroImagePosition, width = '150px', height = '50px' }) {
  return logo?.src ? (
    <StyledLogo
      alt='Logo image'
      className='ix-logo'
      src={logo?.src}
      height={logo?.height || height}
      width={logo?.width || width}
    />
  ) : (
    <Icon
      name={heroImagePosition === 'contain' ? 'logoWhite' : 'brandLogo'}
      width={width.replace('px', '')}
      height={height.replace('px', '')}
      viewBoxWidth={210}
      viewBoxHeight={70}
      className='ix-logo'
    />
  );
}
Logo.propTypes = {
  logo: PropTypes.any.isRequired,
  heroImagePosition: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
export default Logo;
