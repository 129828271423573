import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const ImageBlock = styled.div`
    position: relative;
    overflow: hidden;
    img.background {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: ${(props) => props.fade
            ? `linear-gradient(180deg, ${props.style.colors.primary} 0%, rgba(148, 148, 148, 0) 69.26%)`
            : props.style.colors.primary};
        opacity: 0.7;
        z-index: 1;
    }
    
    ${props => props.position === 'top' && `
        margin: -16px -16px 0px;
        height: 140px;
        .background {
            width: 100%;
        }

        @media screen and (min-width: 1921px) {
            margin: -${px2vw(16)} -${px2vw(16)} 0px;
            height: ${px2vw(140)};
        }
    `}
`;


export const LogoBlock = styled.div`
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    text-align: center;
    top: calc((100% - 50px)/ 2);

    @media screen and (min-width: 1921px) {
        top: calc((100% - ${px2vw(50)})/ 2);
    }
`;
