import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Col, Paragraph, Row, Title} from 'shared/ui';
import {Box} from './styles';
import {StageContext} from '../../Stages';

function Panel({coveragePrice, coverageDuration, coverageType}) {
  const { commonStyles, clientData: { fullSize, template } } = useContext(StageContext);
  return (
    <Box data-testid="resultPage-panel">
      {template !== 'coverage' && <Col alignItems={'flex-start'}>
        <Title
          variant={fullSize ? 'h3' : 'h4'}
          color={commonStyles.components.headings.color || commonStyles.colors.secondary}
        >
          {coverageDuration?.Value || '1 year policy'}
        </Title>
        <Paragraph
          variant={fullSize ? 'p1' : 'p4'}
          className={fullSize ? 'p1' : 'p4'}
          color={commonStyles.components.paragraphs.color}
        >
          {coverageType?.Value}
        </Paragraph>
      </Col>}
      
      <Row justifyContent={template === 'coverage' ? 'center' : 'flex-end'} alignItems='center'>
        <Title
          variant={fullSize || template === 'coverage' ? 'h3' : 'h4'}
          color={commonStyles.components.headings.color || commonStyles.colors.secondary}
        >
          $
        </Title>
        <Title
          variant={fullSize || template === 'coverage' ? 'h3' : 'h4'}
          color={commonStyles.components.headings.color || commonStyles.colors.secondary}
        >
          {coveragePrice?.Value || coveragePrice}
        </Title>
        <Paragraph
          variant={fullSize ? 'p1' : 'p4'}
          className={fullSize ? 'p1' : 'p4'}
          color={commonStyles.components.headings.color}
        >
          /mo
        </Paragraph>
      </Row>
    </Box>
  );
}
Panel.propTypes = {
  coveragePrice: PropTypes.any.isRequired,
  coverageDuration: PropTypes.any.isRequired,
  coverageType: PropTypes.any.isRequired,
};

export default Panel;
