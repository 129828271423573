import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { Helpers, Paths } from '@jsonforms/core';
import { withJsonFormsArrayControlProps } from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

import {StageContext} from '../../Stages';
import ArrayOption from './ArrayOption';

const { convertToValidClassName } = Helpers;

export const CoverageArrayControl = (props) => {
  const {
		uischema,
		schema,
		path,
		data,
		visible,
		errors,
		getStyleAsClassName,
		childErrors,
		removeItems,
		handleChange,
  } = props;
	const { clientData: {template} } = useContext(StageContext);

	const [allChecked,setAllChecked] = useState(false);

  const controlElement = uischema;
  const tableClass = getStyleAsClassName('array.table.table');
  const validationClass = getStyleAsClassName('array.table.validation');

  const controlClass = [
		getStyleAsClassName('array.table'),
		convertToValidClassName(controlElement.scope),
  ].join(' ');
  const isValid = errors.length === 0;
  const divClassNames = [validationClass]
		.concat(isValid ? '' : getStyleAsClassName('array.table.validation.error'))
		.join(' ');

	const renderChilds = (_child, index) => {
		return <ArrayOption 
							key={index} 
							index={index} 
							childErrors={childErrors} 
							getStyleAsClassName={getStyleAsClassName}
							path={path} 
							schema={schema}
							removeItems={removeItems} 
							_child={_child}
							onChange={handleChange}
							setAllChecked={setAllChecked}
							handleRadioCheck={handleRadioCheck}
						/>;
	};

	const handleSelectAll = (allChecked) => {
		data.map((item, index) => {
			const childPath = Paths.compose(path, `${index}`);
			handleChange(childPath + '.checked', allChecked);
		});
	};

	const handleRadioCheck = (child) => {
		data.map((item, index) => {
			const childPath = Paths.compose(path, `${index}`);
			if (child === index) {
				handleChange(childPath + '.checked', true);
			} else {
				handleChange(childPath + '.checked', false);
			}
		});
	};

	return (
		<div className={controlClass} hidden={!visible}>
			<div className={divClassNames}>{!isValid ? errors : ''}</div>
			<div className={tableClass}>
				{!data || !Array.isArray(data) || data.length === 0 ? (
					<div></div>
				) : (
					<>
						{data.map(renderChilds)}
						{template === 'coverages' && <ArrayOption handleSelectAll={handleSelectAll} setAllChecked={setAllChecked} allChecked={allChecked} />}
					</>
				)}
			</div>
		</div>
	);
};
CoverageArrayControl.propTypes = {
  uischema: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  childErrors: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  getStyleAsClassName: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default withVanillaControlProps(
  withJsonFormsArrayControlProps(CoverageArrayControl)
);
