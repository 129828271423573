import styled from 'styled-components';

export const StyledFooter = styled.div`
  font-size: ${props => props.style.components.footer?.fontSize};
  font-family: ${props => props.style.components.footer?.fontFamily};
  line-height: ${props => props.style.components.footer?.lineHeight};
  color: ${props => props.style.components.footer?.color};
  text-align: ${props => props.style.components.footer?.textAlign};
  margin: 20px 0 8px 0;
`;
