import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {fetchDynamicSchema, sendApplicationForms, fetchResults} from '../requests';
import useSchemaSteps from 'utils/useSchemaSteps';
import getUserData from 'utils/getUserData';
import {StageContext} from '..';
import Loader from '../../Loader';
import {withPixelTracking} from '../../HOC/withPixelTracking';
import {loginAuth} from 'api/IXWidgetApi';
import Multistep from '../Multistep';
import {stepVariantCheck, collectResultData, checkApplicationProcess} from '../utils';

const FirstStage = ({ setApplicationFormId, setSecondSchema, schema, setWorkflowResults }) => {
  const { clientData, setError, toNextStage, setLoading, stagesDataRef, loading, widgetDeployId } =
    useContext(StageContext);

  const {
    metadata,
    programId,
    productId,
    labelPlacement = 'top',
    userData,
    layout,
    clientId,
	template = 'default',
    widgetId
  } = clientData;

  const [processedSchemas, processedUiSchemas] = useSchemaSteps(
    schema,
    clientData?.FirstStage?.stepCategories,
    labelPlacement,
    layout,
  );

  const [data, setData] = useState(stagesDataRef.current[1]);

  const getAuthCarApiToken = async () => {
    try {
      const response = await loginAuth();
      if(response.data) {
        localStorage.setItem('carApiToken', response.data);
      }
    } catch (e) {
      console.log('e: ', e);
    }
  };

  useEffect(() => {
    if (!data) {
      const data = getUserData(userData, schema);
      setData(data);
    }
  }, [schema]);

  useEffect(() => {
    getAuthCarApiToken().then(r => r);
  }, []);

  const onSubmit = async () => {
    setLoading(true);
		let resultData = collectResultData(data);

    const id = await sendApplicationForms(
      {
        itemId: productId,
        itemType: 'Product',
        schemaId: schema?.id,
        attributesData: resultData,
        widgetId,
        programId,
        metadata,
        widgetDeployId,
      },
      setError,
      () => {
        setWorkflowResults({
          message: 'We were unable to verify your data. ',
          resultRuleName:
            'Thank you for submitting your information for a quote.',
          link: 'https://www.google.com/',
        });
      },
      clientId
    );
    if(id) {
      setApplicationFormId(id);
      setTimeout(() => {
        checkApplicationProcess({
          id,
          setError,
          onFinished: () => onFinished(id, resultData),
          onWaitingForUserInput: () => onWaitingForUserInput(id),
          onAbandoned: () => {},
          clientId,
        });
      }, 600);
    }
  };

  const onFinished = async (id, resultData) => {
    const workflowResults = await fetchResults(id, setError, clientId);
    setWorkflowResults(workflowResults);
    stagesDataRef.current[2] = resultData;
    toNextStage();
    setLoading(false);
  };

  const onWaitingForUserInput = async (id) => {
    const dynamicSchema = await fetchDynamicSchema(id, setError, clientId);
    setSecondSchema(dynamicSchema);
    stagesDataRef.current[1] = data;
    toNextStage();
    setLoading(false);
  };

	const StepVariantTemplate = stepVariantCheck(template);

  if(loading) {
    return <Loader />;
  }
	return (
		!!processedSchemas?.stepName && (
			<Multistep
				Template={StepVariantTemplate}
				onSubmit={onSubmit}
				schema={processedSchemas}
				uiSchema={processedUiSchemas}
				data={data}
				setData={setData}
			/>
		)
	);
};
FirstStage.propTypes = {
  schema: PropTypes.any,
  setApplicationFormId: PropTypes.func.isRequired,
  setSecondSchema: PropTypes.func.isRequired,
  setWorkflowResults: PropTypes.func.isRequired,
};

export default React.memo(withPixelTracking(FirstStage));
