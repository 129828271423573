import React from 'react';
import PropTypes from 'prop-types';
import { StyledForm } from './styles';

const StyledFormMaterial = ({
  children,
  headingsColor,
  placeholdersColor,
  showWarnings,
}) => {
  return (
    <StyledForm
      headingsColor={headingsColor}
      placeholdersColor={placeholdersColor}
      showWarnings={showWarnings}
    >
      {children}
    </StyledForm>
  );
};
StyledFormMaterial.propTypes = {
  children: PropTypes.any.isRequired,
  headingsColor: PropTypes.string.isRequired,
  placeholdersColor: PropTypes.string.isRequired,
  showWarnings: PropTypes.bool.isRequired,
};
export default StyledFormMaterial;
