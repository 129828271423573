import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {isDescriptionHidden, NOT_APPLICABLE, rankWith, showAsRequired,} from '@jsonforms/core';
import {DispatchCell, withJsonFormsControlProps} from '@jsonforms/react';
import {maxBy, merge} from 'lodash';
import {InputLabel} from '@mui/material';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';
import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

const MaterialCustomLabelsTextControl = (
    {
        path,
        label,
        classNames,
        description,
        id,
        errors,
        uischema,
        schema,
        rootSchema,
        visible,
        enabled,
        cells,
        config,
        required,
    }
) => {
	const { clientData: {controlsWithDescription} } = useContext(StageContext);
    const appliedUiSchemaOptions = merge({}, config, uischema.options);

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        false,
        appliedUiSchemaOptions.showUnfocusedDescription
    );
    const labelPlacement = uischema.labelPosition;

    const testerContext = {
        rootSchema: rootSchema,
        config: config
    };

    const attribute_description = getAttributeDescription(path, controlsWithDescription);

    // Filter schema values from null value
    if (schema.enum) {
        const result = schema.enum.filter((value) => {
            if (value) {
                return value;
            }
        });
        schema.enum = result;
    }

    const cell = maxBy(cells, r => r.tester(uischema, schema, testerContext));

    if (
        cell === undefined ||
        cell.tester(uischema, schema, testerContext) === NOT_APPLICABLE
    ) {
        console.warn('No applicable cell found.', uischema, schema);
        return null;
    } else {
        return (
            <div
                className={classNames?.wrapper}
                hidden={!visible}
                id={id}
            >
                {labelPlacement === 'top' && <InputLabel
                    htmlFor={id + '-input'}
                    error={!!errors}
                    required={showAsRequired(required,
                        appliedUiSchemaOptions.hideRequiredAsterisk)}
                >
                    {label}
                </InputLabel>}
                {attribute_description && <p className='description'>{description}</p>}
                <DispatchCell
                    uischema={uischema}
                    schema={schema}
                    path={path}
                    id={id + '-input'}
                    enabled={enabled}
                />
                <ErrorMessage errors={errors} path={path} showDescription={showDescription} description={description}/>
            </div>
        );
    }
};
MaterialCustomLabelsTextControl.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    classNames: PropTypes.any.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    errors: PropTypes.any.isRequired,
    uischema: PropTypes.any.isRequired,
    schema: PropTypes.any.isRequired,
    rootSchema: PropTypes.any.isRequired,
    visible: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    cells: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    required: PropTypes.bool.isRequired,
};

const uiSchemaLabelTester = () => (
    uischema
) => uischema.labelPosition === 'top' || uischema.labelPosition === 'inside';
export const materialLabelControlTester = rankWith(
    3,
    uiSchemaLabelTester()
);
export default withJsonFormsControlProps(MaterialCustomLabelsTextControl);
