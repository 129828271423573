import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from './styles';

const FooterContent = ({
  style,
  heroImage,
}) => {
  return (
    <Paragraph style={style} position={heroImage?.position}>
      <a href="https://www.rein.ai/privacy-policy">Privacy Policy</a> - <a href="https://www.rein.ai/privacy-policy#DoNotSell-Share">Do Not Sell or Share My Personal Information</a> REIN may receive a fee if you purchase the representated product or service.
    </Paragraph>
  );
};
FooterContent.propTypes = {
  style: PropTypes.any.isRequired,
  heroImage: PropTypes.any,
};
export default FooterContent;
