import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {East} from '@mui/icons-material';
import {StageContext, stagesNames} from 'components/Stages';

const ButtonMaterial = ({
  variant = 'contained',
  onClick,
  text,
  hasArrow,
  flex,
  disabled = false,
}) => {
  const { clientData: { trackers = {} }, currentStageNumber } = useContext(StageContext);
  const ref = useRef(null);

  const onHandleClick = () => {
    const dataEvent = trackers[`${stagesNames[currentStageNumber]}_button_click`];
    if(dataEvent?.url) {
      const img = document.createElement('img');
      img.setAttribute('src', dataEvent.url);
      ref.current.appendChild(img);
    }
    onClick();
  };
  return (
    <Button
      variant={variant}
      onClick={onHandleClick}
      style={{margin: 0}}
      fullWidth={flex}
      disabled={disabled}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {text}
        {hasArrow && (
          <East
            fontSize={'small'}
            sx={{ marginLeft: '10px', color: '#ffffff' }}
          />
        )}
      </Box>
    </Button>
  );
};
ButtonMaterial.propTypes = {
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  hasArrow: PropTypes.bool.isRequired,
  flex: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ButtonMaterial;
