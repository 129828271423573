import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleContext} from 'pages/Main';
import {Col} from './styles';
import {StageContext} from 'components/Stages';

const ThemedCol = ({
  themeStyles,
  alignItems = 'center',
  justifyContent = 'flex-start',
  children,
  flexDirection = 'column',
  width = 'auto',
  gap = 0,
}) => {
  const { clientData: { fullSize, smallSize } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  const smSizeCl = smallSize ? 'smallSize' : '';
  return (
    <Col
      style={themeStyles}
      clientWidth={clientWidth}
      className={fullSize ? 'fullSize' : smSizeCl}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      width={width}
      gap={gap}
    >
      {children}
    </Col>
  );
};
ThemedCol.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  children: PropTypes.any.isRequired,
  flexDirection: PropTypes.string,
  width: PropTypes.string,
  gap: PropTypes.any,
};

export default ThemedCol;
