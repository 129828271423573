import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import NoMatchingDataComponent from './NoMatchingData';
import UserQualifiedComponent from './UserQualified';
import UserNotQualifiedComponent from './UserNotQualified';

import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import MaterialLabelControl, {materialLabelControlTester} from '../CustomControls/MuiCustomLabelsTextControl';
import MaterialTextCell, {materialTextCellTester} from '../CustomCells/MuiTextCell';

import {vanillaCells, vanillaRenderers} from '@jsonforms/vanilla-renderers';
import LabelTextControl, {LabelControlTester} from '../CustomControls/LabelControl';

import {ThemeProvider} from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from '../Stages';

function Results({ workflowResults, schema, uiSchema, data, setData, onSubmit, goBack }) {
  const [message, setMessage] = useState(null);
  const [link, setLink] = useState(null);
  const [responseAttributes, setResponseAttributes] = useState(null);
  const [status, setStatus] = useState('');

  const { clientData: { theme, labelPlacement }, additionalDynamicStepsAmount, commonStyles, setResultStage } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  const customizedTheme = theme === 'material' && materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  }, 123);

  const jsonFormRenderers = theme === 'material'
    ? [
      ...materialRenderers,
      { tester: materialLabelControlTester, renderer: MaterialLabelControl },
    ] : [
      ...vanillaRenderers,
      { tester: LabelControlTester, renderer: LabelTextControl },
    ];

  const jsonFormCells = theme === 'material'
    ? [
      ...materialCells,
      { tester: materialTextCellTester, cell: MaterialTextCell },
    ] : vanillaCells;

  useEffect(() => {
    if (workflowResults) {
      processResults(workflowResults);
    }
  }, [workflowResults]);

  const processResults = (workflowResult) => {
    const { link, message, responseObject, outcomeType } = workflowResult;
    responseObject && setResponseAttributes(JSON.parse(responseObject));
    message && setMessage(message);
    link && setLink(link);

    let status;
    if (outcomeType === 'Quote' || outcomeType === 'Bind') {
      status = 'UserQualified';
    } else if (outcomeType === 'Lead') {
      status = 'UserNotQualified';
    } else {
      status = 'NoMatchingData';
    }
    setStatus(status);
    setResultStage(status);
  };

  const statusesToComponents = {
    'NoMatchingData': NoMatchingDataComponent,
    'UserNotQualified': UserNotQualifiedComponent,
    'UserQualified':  UserQualifiedComponent,
  };
  const commonProps = {
    message,
    toPrevStep: goBack,
    stepNumber: 3 + additionalDynamicStepsAmount,
  };

  const additionalProps = {
    'NoMatchingData': {
      link,
      stepName: 'NoMatchingData'
    },
    'UserNotQualified': {
      jsonFormRenderers,
      jsonFormCells,
      schema,
      uiSchema,
      onSubmit,
      data,
      setData,
      link,
      responseAttributes,
      stepName: 'UserNotQualified'
    },
    'UserQualified': {
      responseAttributes,
      jsonFormRenderers,
      jsonFormCells,
      schema,
      uiSchema,
      onSubmit,
      data,
      setData,
      link,
      stepName: 'UserQualified',
    },
  };

  const Component = statusesToComponents[status];
  return Component && (
    theme === 'material' ? (
      <ThemeProvider theme={customizedTheme}>
        <Component {...commonProps} {...additionalProps[status]} />
      </ThemeProvider>
    ) : (
      <Component {...commonProps} {...additionalProps[status]} />
    )
  );
}
Results.propTypes = {
  workflowResults: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  uiSchema: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  setData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default Results;
