import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Interweave} from 'interweave';

import {Block, Container} from 'pages/Main/styles';
import Icon from 'components/Icon/icon';

import {Button, Col, MarginTopBox, Paragraph, Row, Title} from 'shared/ui';
import {StyleContext} from 'pages/Main';
import ImageHeader from 'components/Header/ImageHeader';
import {StageContext} from 'components/Stages';

function ErrorPageTemplate({ error, toPrevStage }) {
  const {
    commonStyles,
    clientData: {
      fullSize,
      smallSize,
      logo,
      isMicroSite,
      heroImage,
      ErrorPage: { icon, title, description, buttonText, } = {},
    }
  } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
  return (
    <Container
      style={commonStyles}
      position={'top'}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      <ImageHeader heroImage={heroImage} logo={logo} fade={true} />
      <Block>
        <div className='ix-content' style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Col justifyContent={'center'}>
            <MarginTopBox className={'ix-title-div'}>
              <Row justifyContent={'center'}>
                {icon
                  ? <img
                      alt='Error icon'
                      className='ix-error-icon'
                      src={icon}
                    />
                  : <Icon name='notFound' width='62' height='64' fill={commonStyles.colors.secondary || '#6266EA'} />
                }
              </Row>
            </MarginTopBox>
            <MarginTopBox>
              <Row justifyContent={'center'}>
                <Title
                  variant={isMicroSite && (clientWidth >= 470) ? 'h3' : 'h5'}
                  className={'ix-title'}
                  textAlign={'left'}
                  color={commonStyles.components.headings.color || '#010101'}
                >
                  <Interweave content={title || error?.title || 'Something went wrong'} />
                </Title>
              </Row>
            </MarginTopBox>
            <MarginTopBox>
              <Row justifyContent={'center'}>
                <Paragraph
                  variant={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  className={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  bold={isMicroSite}
                  textAlign={'center'}
                  color={commonStyles.components.paragraphs.color || '#2b2c41'}
                >
                  {description || error?.detail ||  'There was a temporary problem processing your request.'}
                </Paragraph>
              </Row>
            </MarginTopBox>
            <MarginTopBox>
              <Row justifyContent={'center'}>
                <Button
                  variant='contained'
                  color='secondary'

                  className='secondary'
                  onClick={() => toPrevStage()}
                  text={buttonText || 'Try again'}
                />
              </Row>
            </MarginTopBox>
          </Col>
        </div>
      </Block>
    </Container>
  );
}
ErrorPageTemplate.propTypes = {
  error: PropTypes.any.isRequired,
  toPrevStage: PropTypes.func.isRequired,
};
export default ErrorPageTemplate;
