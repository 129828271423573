import React from 'react';
import PropTypes from 'prop-types';
import { Line } from './styles';

const ThemedDivider = ({ themeStyles }) => {
  return (
    <Line style={themeStyles} />
  );
};
ThemedDivider.propTypes = {
  themeStyles: PropTypes.any.isRequired,
};
export default ThemedDivider;
