export const schema = {
	'type': 'object',
	'stepName': 'ContactInformation',
	'properties': {
		'firstName': {
			'type': 'string',
			'description': 'Please enter your First Name'
		},
		'lastName': {
			'type': 'string',
			'description': 'Please enter your Last Name'
		},
		'email': {
			'type': 'string',
			'description': 'Please enter your Email',
			'format': 'email'
		},
	},
	'required': [
		'firstName',
		'lastName',
		'email'
	]
};
