import React, { useContext } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';

import CTAWidgetTemplate from '../CTAWidgetTemplate';

import { StageContext } from '../../Stages';

function CTAWidgetMaterial(props) {
  const { clientWidth, commonStylesCTA } = useContext(StageContext);

  let customizedTheme = materialTheme(commonStylesCTA, clientWidth);

  return (
    <ThemeProvider theme={customizedTheme}>
      <CTAWidgetTemplate {...props} />
    </ThemeProvider>
  );
}
export default CTAWidgetMaterial;
