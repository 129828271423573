import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const ParagraphMaterial = ({
  children,
  textAlign = 'left',
  bold,
  color,
  variant = 'p3',
}) => {
  return (
    <Typography variant={variant} mb={1} color={color} style={{marginBottom: 0, fontWeight: bold ? 700 : 400, textAlign}}>
      {children}
    </Typography>
  );
};
ParagraphMaterial.propTypes = {
  children: PropTypes.any.isRequired,
  textAlign: PropTypes.string.isRequired,
  bold: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
export default ParagraphMaterial;
