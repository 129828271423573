import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const ErrorText = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${(props) => props.style?.colors.danger};
  margin: 0;

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(14)};
    line-height: ${px2vw(16)};
  }
`;
