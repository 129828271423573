import React, {useContext, useMemo} from 'react';
import filter from 'lodash/filter';
import join from 'lodash/join';
import {getControlPath, Paths, rankWith, scopeEndsWith} from '@jsonforms/core';
import {JsonForms} from '@jsonforms/react';
import PropTypes from 'prop-types';

import {UpdateForm, Wrapper} from './styles';
import {StyledForm, Title, Button} from 'shared/ui';

import {IconButton} from '@mui/material';
import {KeyboardBackspace} from '@mui/icons-material';

import {generateUISchema} from 'utils/UIschemaGenerator';
import {vanillaCells, vanillaRenderers} from '@jsonforms/vanilla-renderers';
import FormContext from '../../FormContext/FormContext';
import {StageContext} from '../../Stages';
import {controls} from '../../SchemaRenderers';
import {VehiclesProvider} from '../../FormContext/VehiclesContext';
import BannerImage from '../../BannerImage';

export const EditArrayOption = (props) => {
  const {
		schema,
		path,
		getStyleAsClassName,
    index,
    handleBack,
    handleUpdate,
    handleRemove,
    newItem,
    handleFormChange,
    errors,
    data,
    setErrors,
    setAdditionalErrors,
  } = props;
  const { commonStyles, clientData: { customControls, ArrayControl, heroImage, fullSize, button } } = useContext(StageContext);
  const uiSchema = ArrayControl?.uiSchema;
  const customRenderers = customControls?.map(item => {
    const tester = rankWith(
      4,
      scopeEndsWith(item.attributeName)
    );
    let renderer = controls[item.renderer];

    return {tester, renderer};
  });
  const renderers = [
      ...vanillaRenderers,
      ...customRenderers
  ];
  const ui_schema = generateUISchema({type: 'object', ...schema});

  const childPath = Paths.compose(path, `${index}`);
  const errorsPerEntry = filter(errors, (error) => {
    const errorPath = getControlPath(error);
    return errorPath.startsWith(childPath);
  });

  const validationClassName = getStyleAsClassName('array.validation');
  const errorValidationClassName = getStyleAsClassName(
    'array.validation.error'
  );
  const errorClassNames = errorsPerEntry
    ? [validationClassName]
      .concat(errorValidationClassName)
      .join(' ')
    : validationClassName;


  const providerValue = useMemo(() => {
    return {
      data, errors, setErrors, showValidation: true, setAdditionalErrors };
  }, [data, errors, setErrors, setAdditionalErrors]);

  return (
    <StyledForm>
      <UpdateForm heroImage={heroImage?.position} style={commonStyles}>
        {heroImage?.position === 'contain' && <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize} />}
        <Wrapper>
          <Title textAlign={'left'}>
            <IconButton onClick={handleBack}><KeyboardBackspace sx={{ color: '#000000' }} /></IconButton> {newItem ? 'Add new vehicle' : 'Update our vehicle details'}
          </Title>
          <FormContext.Provider value={providerValue}>
            <VehiclesProvider>
              <JsonForms
                schema={{type: 'object', ...schema}}
                uischema={uiSchema || ui_schema}
                renderers={renderers}
                cells={vanillaCells}
                data={data}
                onChange={handleFormChange} />
            </VehiclesProvider>
          </FormContext.Provider>
          <div>
            {errorsPerEntry && (
              <span className={errorClassNames}>
                {join(
                  errorsPerEntry.map((e) => e.message),
                  ' and '
                )}
              </span>
            )}
          </div>
          <div>
            <Button
              variant='contained'
              color='secondary'

              className='secondary'
              onClick={handleUpdate}
              text={newItem ? 'Confirm' : 'Update'}
              flex={'fullwidth'}
              borderRadius={button?.borderRadius}
            />
            
            {!newItem && <Button
              variant='outlined'
              color='danger'

              className='danger outline'
              onClick={handleRemove}
              text={'Remove'}
              flex={'fullwidth'}
              borderRadius={button?.borderRadius}
            />}
          </div>
        </Wrapper>
      </UpdateForm>
    </StyledForm>
  );
};

EditArrayOption.propTypes = {
  schema: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setAdditionalErrors: PropTypes.func.isRequired,
  getStyleAsClassName: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  newItem: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};
export default EditArrayOption;
