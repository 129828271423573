import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Paragraph = styled.p`
  font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
  color: ${props => props.color || props.style?.components.paragraphs.color};
  font-style: normal;
  font-weight: ${(props) => props.bold ? 700 : 400};
  text-align: ${(props) => props.textAlign};
  margin: 0;

  ${(props) => props.className === 'p1' && `
    &.p1 {
      font-size: 20px;
      line-height: 32px;

      @media screen and (min-width: 1921px) {
        font-size: ${px2vw(20)};
        line-height: ${px2vw(32)};
      }
    }
  `}
  ${(props) => props.className === 'p2' && `
    &.p2 {
      font-size: 16px;
      line-height: 24px;

      @media screen and (min-width: 1921px) {
        font-size: ${px2vw(16)};
        line-height: ${px2vw(24)};
      }
    }
  `}
  ${(props) => props.className === 'p3' && `
    &.p3 {
      font-size: ${props.style?.components.paragraphs.fontSize || '15px'};
      line-height:  ${props.style?.components.paragraphs.lineHeight || '24px'};

      @media screen and (min-width: 1921px) {
        font-size: ${px2vw(props.style?.components.paragraphs.fontSize || 15)};
        line-height: ${px2vw(props.style?.components.paragraphs.lineHeight || 24)};
      }
    }
  `}
  ${(props) => props.className === 'p4' && `
    &.p4 {
      font-size: 14px;
      line-height: 20px;

      @media screen and (min-width: 1921px) {
        font-size: ${px2vw(14)};
        line-height: ${px2vw(20)};
      }
    }
  `}
  ${(props) => props.className === 'p5' && `
    &.p5 {
      font-size: 12px;
      line-height: 16px;

      @media screen and (min-width: 1921px) {
        font-size: ${px2vw(12)};
        line-height: ${px2vw(16)};
      }
    }
  `}
`;
