import styled from 'styled-components';
import { Box } from '@mui/material';

export const ModalBox = styled(Box)`
  &.MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 740px;
    max-width: 100%;
    background-color: #fff;
    padding: 0;
    border: 1px solid #DADAE3;
    box-shadow: 0px 0px 10px rgba(56, 56, 116, 0.25);
    border-radius: 12px;
    & > div {
      border-radius: 12px;
    }

    .ix-close-btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
    }
  }
`;