import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import {LoaderWrap, StyledIcon} from './styles';
import {Paragraph} from 'shared/ui';
import Icon from '../Icon/icon';

import {StyleContext} from 'pages/Main';

import {ThemeProvider} from '@mui/material';
import materialTheme from 'themes/materialTheme';
import {StageContext} from '../Stages';

const loaderDescriptions = ['We are processing your request...', 'We are obtaining your quote ...'];

const Loader = ({ activeStep }) => {
  const { clientWidth } = useContext(StyleContext);
  const { commonStyles, clientData: { loader: { fullscreen, blurred, color } = {}, labelPlacement, theme } } = useContext(StageContext);

  const customizedTheme = theme === 'material' && materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  });

  const LoaderTemplate = useMemo(() => (
    <LoaderWrap className='ix-spinner' fullscreen={fullscreen} blurred={blurred} color={color} style={commonStyles}>
      <StyledIcon>
        <Icon
          name='spinner'
          width='90'
          height='90'
          viewBox='0 0 90 90'
          fill={color || commonStyles.colors.secondary}
        />
      </StyledIcon>
      <Paragraph
        color={color || commonStyles.colors.secondary}
        textAlign={'center'}
        variant={'p4'}
        className={'p4'}
        bold={true}
      >
        {loaderDescriptions[activeStep - 1] || 'We are processing your request...'}
      </Paragraph>
    </LoaderWrap>
  ), [fullscreen, blurred, color, commonStyles, activeStep, loaderDescriptions]);

  return theme === 'material'
    ? (
      <ThemeProvider theme={customizedTheme}>
        {LoaderTemplate}
      </ThemeProvider>
    ) : (
      <>
        {LoaderTemplate}
      </>
    );
};
Loader.propTypes = {
  activeStep: PropTypes.number.isRequired,
};
export default Loader;
