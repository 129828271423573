import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleContext} from 'pages/Main';
import {StyledForm} from './styles';
import {StageContext} from 'components/Stages';

const StyledFormCustom = ({
  themeStyles,
  children,
  headingsColor,
  placeholdersColor,
  showWarnings,
}) => {
  const { clientData: { fullSize, smallSize, labelPlacement } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const smSizeCl = smallSize ? 'smallSize' : '';
  return (
    <StyledForm
      style={themeStyles}
      clientWidth={clientWidth}
      className={fullSize ? 'fullSize' : smSizeCl}
      headingsColor={headingsColor}
      placeholdersColor={placeholdersColor}
      showWarnings={showWarnings}
      labelPlacement={labelPlacement}
    >
      {children}
    </StyledForm>
  );
};
StyledFormCustom.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  headingsColor: PropTypes.any.isRequired,
  placeholdersColor: PropTypes.any.isRequired,
  showWarnings: PropTypes.any.isRequired,
};

export default StyledFormCustom;
