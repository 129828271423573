import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const BorderedPaddingBox = styled.div`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: ${(props) => (props.smallSize ? props.smallSizePadding : props.fullSizePadding)};
  border: 1px solid #B9B9C9;
  border-radius: ${(props) => props.borderRadius};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) => (props.background)};
    border-radius: ${(props) => props.borderRadius};
    opacity: 0.1;
    z-index: -2;
  }

  @media screen and (min-width: 1921px) {
    padding: ${(props) => props.smallSize
        ? px2vw(props.smallSizePadding)
        : px2vw(props.fullSizePadding)
    };
    border: ${px2vw(1)} solid #dadae3;
    border-radius: ${(props) => px2vw(props.borderRadius)};
  }
`;
