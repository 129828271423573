import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const CheckboxControl = styled.div`
  position: relative;
  display: block;

  @media screen and (min-width: 1921px) {
    margin-bottom: ${px2vw(14)};
  }

  input[type='checkbox'] {
    display: none;
  }

  &:has(input[type='checkbox']:checked) {
    label:before {
      background: #95D08C;
    }

    label:after {
      right: 4px;
    }
  }
`;
export const Label = styled.label`
  font-family: ${(props) => props.style?.components.headings.fontFamily};
  cursor: pointer;
  padding-right: 50px;

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(15)};
    line-height: ${px2vw(20)};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    width: 47px;
    background: #8C9DA7;
    border-radius: 24px;
    transition: background .15s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    right: 26px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffffff;
    transition: right .15s ease-in-out;
  }

  @media screen and (min-width: 1921px) {
    font-size: ${(props) => px2vw(props.style?.components.label.fontSize || '15px')};
    line-height: ${px2vw(24)};
    margin-bottom: ${px2vw(5)};
  }

  input[type='checkbox'] {
    display: none;
  }
`;