import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StageContext} from '../../Stages';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {InputLabel} from '@mui/material';
import {CustomSelect} from './styles';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';

const VehicleSelect = ({label, required, changeHandler, data, id, value, errors, path}) => {
	const { clientData: { theme }, commonStyles } = useContext(StageContext);
	if(theme === 'material') {
		return (
			<div>
				<InputLabel htmlFor={`${id}-input`}>{label}{required && '*'}</InputLabel>
				<Select value={value} onChange={changeHandler} helperText={'te'}>
					{data.map((item) => <MenuItem value={item.name} key={item.id}>{item.name === '' ? 'None' : item.name}</MenuItem>)}
				</Select>
				<ErrorMessage path={path} errors={errors}/>
			</div>
		);
	}
	return (
		<div>
			<label htmlFor={`${id}-input`}>{label}{required && '*'}</label>
			<CustomSelect value={value} onChange={changeHandler} error={errors} style={commonStyles} >
				{data.map((item) =>  <option value={item.name} key={item.id}>{item.name === '' ? 'None' : item.name}</option>)}
			</CustomSelect>
			<ErrorMessage path={path} errors={errors}/>
		</div>
	);
};
VehicleSelect.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default VehicleSelect;
