import React, {useContext} from 'react';
import ThemedMarginTopBox from './ThemedMarginTopBox';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const MarginTopBox = (props) => {
  const { commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth  } = useContext(StyleContext);

  const themeStyles = theme === 'material'
    ? materialTheme(commonStyles, clientWidth, { labelPlacement })
    : customTheme(commonStyles)[theme];
  return <ThemedMarginTopBox themeStyles={themeStyles} {...props} />;
};

export default MarginTopBox;
