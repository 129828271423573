import React, {useContext, useEffect, useState, useMemo} from 'react';
import {JsonForms} from '@jsonforms/react';
import {Interweave} from 'interweave';
import PropTypes from 'prop-types';

import {Block, Container} from 'pages/Main/styles';
import Header from '../../Header/StepperHeader';
import BannerImage from '../../BannerImage';
import FormContext from '../../FormContext/FormContext';
import ProgressBar from '../../ProgressBar';

import {VehiclesProvider} from '../../FormContext/VehiclesContext';
import useEnterClick from 'utils/useEnterClick';
import {StageContext} from '../../Stages';
import {StyleContext} from 'pages/Main';

import {
  Button,
  Col,
  Error,
  MarginTopBox,
  Row, 
  StyledForm, 
  Footer, 
  FormWrapper, 
  Title, 
  Description, 
  BackButton 
} from 'shared/ui';

const getClassName = (fullSize, smallSize) => {
  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
  return fullSize ? 'ix-container fullSize' : smSizeClass;
};
const getTitleJustifyContent = (title) => {
  const titlePositionClass = title?.position === 'right' ? 'flex-end' : 'flex-start';
  return title?.position === 'center' ? 'center' : titlePositionClass;
};

function DefaultTemplate({
  data,
  setData,
  stepNumber,
  toPrevStep,
  toNextStep,
  schema,
  uiSchema,
  jsonFormCells,
  jsonFormRenderers,
}) {
	const {clientWidth} = useContext(StyleContext);
  const { 
    commonStyles, 
    currentStageNumber,
    clientData: { fullSize, smallSize, button, heroImage, form, progressBar, isMicroSite, title, layout } = {} 
  } = useContext(StageContext);

  const [showValidation, setShowValidation] = useState(false);
  const [allFieldsAreEmpty, setAllFieldsAreEmpty] = useState(true);
  const [errors, setErrors] = useState([]);
  const [updatedData, setUpdatedData] = useState(data);
  const [additionalErrors, setAdditionalErrors] = useState([]);
  const [fieldsExpanded, setFieldsExpanded] = useState(false);

  const textAlign = fullSize || smallSize ? 'center' : 'left';
  const showTitle = !title || title?.visible || title?.visible === undefined;
  const variantType = isMicroSite && clientWidth >= 470;

  useEffect(() => {
    let newData = {...updatedData};
    for (const [key, dataValue] of Object.entries(newData)) {
      if (Array.isArray(dataValue)) {
        const filterResult = newData[key].filter((item) => item.checked);
        newData[key] = filterResult;
      }
    }
    setData(newData);
  }, [updatedData]);

  const validateCheckedArrayItems = () => {
    let schema_errors = [...errors];
    if(updatedData) {
      for (const [key, dataValue] of Object.entries(updatedData)) {
        if (Array.isArray(dataValue)) {
          updatedData[key].map((item,i) => {
            const instancePath = `/${key}/${i}/`;
            if (!item.checked) {
              for (let i = schema_errors.length - 1; i >= 0; i--) {
                if (schema_errors[i].instancePath.includes(instancePath)) {
                  schema_errors.splice(i, 1);
                }
               }
            }
          });
        }
      }
    } 
    return schema_errors;
  };

  const handleSubmit = () => {
    const schema_errors = validateCheckedArrayItems();
    if (schema_errors.length) {
      setShowValidation(true);
    } else {
      setData(updatedData);
      setShowValidation(false);
      setUpdatedData();
      toNextStep();
    }
  };

  const handleFormChange = ({ data, errors }) => {
    setData(data);
    setErrors([...errors, ...additionalErrors]);
    if(data) setAllFieldsAreEmpty(Object.values(data).every(value => !value));
  };

  const handleUpdate = () => {
    if (errors.length) {
      setShowValidation(true);
    } else {
      setData(updatedData);
      setFieldsExpanded(false);
    }
  };

  const handleBack = () => {
    if (setFieldsExpanded && fieldsExpanded) {
        setFieldsExpanded(false);
        return;
    }
    setErrors([]);
    setAdditionalErrors([]);
    setData();
    toPrevStep();
  };

  useEnterClick(fieldsExpanded ? handleUpdate : handleSubmit, [updatedData, errors, data, toNextStep]);
  
  const providerValue = useMemo(() => {
    return {data: updatedData, errors, setErrors, showValidation, setAdditionalErrors };
  }, [updatedData, showValidation, errors, setErrors, setAdditionalErrors ]);

  return (
    <Container
      style={commonStyles}
      position={heroImage?.position}
      className={getClassName(fullSize, smallSize)}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block width={form?.width} className="ix-step-wrapper">
        <FormWrapper commonStyles={commonStyles}>
          <Header
            stepNumber={stepNumber}
            handleBack={handleBack}
          />
          {progressBar?.visible && <ProgressBar />}
          <div className='ix-content' style={{display: 'flex', flexDirection: 'column', width: form?.width || 'auto'}}>
            <Col>
              {showTitle &&
                <MarginTopBox className={'ix-title-div'} smallSizeMarginTop={'16px'} fullSizeMarginTop={'50px'}>
                  <Row justifyContent={getTitleJustifyContent(title)}>
                    <Title
                      variant={variantType ? 'h3' : 'h5'}
                      className={'ix-title'}
                      textAlign={textAlign}
                    >
                      <BackButton handleBack={handleBack} backButton={title?.backButton} visible={currentStageNumber !== 1 && title?.backButton?.visible} />
                      <Interweave content={title?.content || 'Lock in your quote in the blink of an eye '} />
                    </Title>
                  </Row>
                </MarginTopBox>
              }
              {layout?.type === 'horizontal' && <Footer />}
              <Description step={schema.stepName} position='top'/>
              <MarginTopBox className={'ix-form-div'}>
                <StyledForm showWarnings={showValidation && !allFieldsAreEmpty} headingsColor={'#010101'} placeholdersColor={'#b9b9c9'}>
                  <FormContext.Provider value={providerValue}>
                    <VehiclesProvider>
                    <JsonForms
                      schema={schema}
                      uischema={uiSchema}
                      data={data}
                      renderers={jsonFormRenderers}
                      cells={jsonFormCells}
                      validationMode={
                        showValidation ? 'ValidateAndShow' : 'ValidateAndHide'
                      }
                      onChange={handleFormChange}
                    />
                    </VehiclesProvider>
                  </FormContext.Provider>
                  {allFieldsAreEmpty || errors.length ?
                    <Row>
                      <Error
                        errors={errors}
                        showValidation={showValidation}
                        content={'* Fields in red are required.'}
                      />
                    </Row>
                  : null}
                  <Description step={schema.stepName} position='bottom'/>
                  <MarginTopBox fullSizeMarginTop='10px'>
                    <Row justifyContent={['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'center'}>
                      <Button
                        variant='contained'
                        color='secondary'

                        className='secondary'
                        onClick={handleSubmit}
                        text={button?.content || 'Confirm'}
                        flex={button?.placement === 'fullwidth'}
                        borderRadius={button?.borderRadius}
                      />
                    </Row>
                  </MarginTopBox>
                </StyledForm>
              </MarginTopBox>
            </Col>
          </div>
        </FormWrapper>
        <Footer />
      </Block>
    </Container>
  );
}
DefaultTemplate.propTypes = {
  data: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  uiSchema: PropTypes.any.isRequired,
  jsonFormCells: PropTypes.any.isRequired,
  jsonFormRenderers: PropTypes.any.isRequired,
  setData: PropTypes.func.isRequired,
  toPrevStep: PropTypes.func.isRequired,
  toNextStep: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

export default DefaultTemplate;
