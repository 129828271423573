import React, {useContext} from 'react';

import SuccessPageTemplate from './SuccessPageTemplate';

import {ThemeProvider} from '@mui/material/styles';
import materialTheme from 'themes/materialTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

function SuccessPage() {
  const { commonStyles, clientData: { theme, labelPlacement } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const customizedTheme = theme === 'material' && materialTheme(commonStyles, clientWidth, {
    labelPlacement,
  });

  return theme === 'material'
    ? (
      <ThemeProvider theme={customizedTheme}>
        <SuccessPageTemplate />
      </ThemeProvider>
    ) : (
      <SuccessPageTemplate />
    );
}

export default SuccessPage;
