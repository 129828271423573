import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import ErrorPage from 'pages/ErrorPage';
import Loader from '../Loader';
import FirstStage from './1stStage';
import ConfirmationStage from './2ndStage';
import ResultStage from './3rdStage';
import ContactInformation from './ContactInformation';

import {invokeEvents} from './invokeEvents';
import {getCurrentStageName, getClientDataForStep} from 'utils/getClientDataForStep';
import {ClientDataContext} from '../../App';
import mainStyles from 'themes/mainStyles';
import Modal from '../Modal';
import CTAWidget from '../CTAWidget';
import {getFormSchema, recordWidgetDeploys} from './requests';

export const StageContext = createContext(null);

const numbersToStages = {
	1: FirstStage,
	2: ConfirmationStage,
	3: ResultStage,
	4: ContactInformation,
};

export const stagesNames = {
	1: 'FirstStage',
	2: 'ConfirmationStage',
	3: 'ResultStage',
	4: 'ContactInformation',
};

const Stages = () => {

	const [currentStageNumber, setCurrentStageNumber] = useState(1);
	const [resultStage, setResultStage] = useState(null);
	const [ctaModalOpen, setCtaModalOpen] = useState(false);
	const [additionalDynamicStepsAmount, setAdditionalDynamicStepsAmount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [firstSchema, setFirstSchema] = useState(null);
	const [applicationFormId, setApplicationFormId] = useState(null);
	const [secondSchema, setSecondSchema] = useState(null);
	const [workflowResults, setWorkflowResults] = useState(null);
	const [error, setError] = useState(null);
	const [contactInfo, setContactInfo] = useState(false);
	const [stepName, setStepName] = useState();
	const [widgetDeployId, setWidgetDeployId] = useState();

	const {clientData, widgetId} = useContext(ClientDataContext);
	const [commonStyles, setCommonStyles] = useState(clientData?.styles);
	const [commonStylesCTA, setCommonStylesCTA] = useState(clientData?.styles);

	const [widgetConfig, setWidgetConfig] = useState(clientData);

	const stageName = getCurrentStageName(clientData?.CTA, error, stagesNames[currentStageNumber], clientData?.modal);

	const clientCTA = getClientDataForStep('CtaStage', clientData);

	const stagesDataRef = useRef({1: null, 2: null, 3: null});

	useEffect(() => {
		if (widgetConfig) {
			const common_Styles = mainStyles(widgetConfig.styles, widgetConfig.heroImage, widgetConfig.theme);
			setCommonStyles(common_Styles);
			const common_StylesCTA = mainStyles(clientCTA?.styles, widgetConfig.heroImage, widgetConfig.theme);
			setCommonStylesCTA(common_StylesCTA);
		}
	}, [widgetConfig]);

	useEffect(() => {
		const client_data = getClientDataForStep(stageName, clientData, resultStage);
		setWidgetConfig(client_data);
	}, [stageName]);

	useEffect(() => {
		if (stepName) {
			let client_data = getClientDataForStep(stepName, clientData, resultStage);
			setWidgetConfig(client_data);
		}
	}, [stepName]);

	const toNextStage = useCallback(() => {
		invokeEvents(currentStageNumber, currentStageNumber + 1);
		setCurrentStageNumber(prev => prev + 1);
		const name = stagesNames[currentStageNumber+1];
			const categoryEntries = clientData[name]?.stepCategories ? Object.entries(clientData[name]?.stepCategories) : undefined;
			if(categoryEntries) {
				const firstStep = categoryEntries[0];
				const firstStepName = firstStep[0];
				setStepName(firstStepName);
			}
	}, [currentStageNumber]);

	const toPrevStage = useCallback(() => {
		invokeEvents(currentStageNumber, currentStageNumber - 1);
		if (error) {
			setError(null);
			if (currentStageNumber > 1) {
				setCurrentStageNumber(prev => prev - 1);
			}
		} else {
			const name = stagesNames[currentStageNumber-1];
			const categoryEntries = Object.entries(clientData[name].stepCategories);
			const lastStep = categoryEntries[categoryEntries.length - 1];
			const lastStepName = lastStep[0];
			setCurrentStageNumber(prev => prev - 1);
			setStepName(lastStepName);
		}
	}, [error, currentStageNumber]);

	const toStage = useCallback((number) => {
		invokeEvents(currentStageNumber, number);
		setCurrentStageNumber(number);
	}, [currentStageNumber]);

	const contextValue = useMemo(() => {
		return {
			setError,
			setLoading,
			currentStageNumber,
			toNextStage,
			toPrevStage,
			toStage,
			stagesDataRef,
			additionalDynamicStepsAmount,
			setAdditionalDynamicStepsAmount,
			clientData: widgetConfig,
			widgetId,
			commonStyles,
			commonStylesCTA,
			setResultStage,
			setStepName,
			stepName,
			widgetDeployId,
		};
	}, [
		widgetId,
		commonStylesCTA,
		widgetConfig,
		currentStageNumber,
		toNextStage,
		toPrevStage,
		toStage,
		additionalDynamicStepsAmount,
		widgetDeployId,
	]);

	useEffect(() => setLoading(false), [error]);

	useEffect(() => {
		getFormSchema({productId: clientData?.productId}, setError, clientData?.clientId, setContactInfo).then((data) => {
			setFirstSchema(data?.schema);
		});
		if (clientData?.widgetId) {
			const widgetDeploysData = {
				'itemId': clientData?.productId,
				'itemType': 'Product',
				'programId': clientData?.programId,
				'widgetId': clientData?.widgetId,
			};
			recordWidgetDeploys(widgetDeploysData, setError, clientData?.clientId).then((data) => {
				setWidgetDeployId(data);
			});
		}
	}, [ctaModalOpen]);

	useEffect(() => {
		if(contactInfo) {
			setCurrentStageNumber(4);
		}
	}, [contactInfo]);

	const stagesProps = {
		1: {
			setApplicationFormId,
			setSecondSchema,
			schema: firstSchema,
			setWorkflowResults,
		},
		2: {
			applicationFormId,
			setSecondSchema,
			setWorkflowResults,
			schema: secondSchema,
			setFirstSchema
		},
		3: {
			workflowResults,
			applicationFormId,
			setSecondSchema,
		},
	};

	const handleOpenCta = () => {
		setCtaModalOpen(true);
	};

	const handleClose = () => {
		setCtaModalOpen(false);
		setCurrentStageNumber(1);
		stagesDataRef.current[1] = undefined;
	};

	const Stage = numbersToStages[currentStageNumber];

	return (
		<StageContext.Provider value={contextValue}>
			<div style={{top: 0, left: 0, margin: 0, padding: 0, height: '100%', width: '100%', position: 'relative'}}>
				{error && <ErrorPage error={error} toPrevStage={toPrevStage}/>}
				{!error && widgetConfig?.CTA && <CTAWidget handleOpen={handleOpenCta} />}
				{!error && !widgetConfig?.CTA && <Stage {...stagesProps[currentStageNumber]} />}
				{!error && ctaModalOpen && <Modal
					open={ctaModalOpen}
					handleClose={handleClose}
				>
					{!error && <Stage {...stagesProps[currentStageNumber]} /> }
					{loading && widgetConfig?.CTA && <Loader activeStep={currentStageNumber}/>}
				</Modal>}
				{loading && !widgetConfig?.CTA && <Loader activeStep={currentStageNumber}/>}
			</div>
		</StageContext.Provider>
	);
};

export default Stages;
