import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {computeLabel, isDescriptionHidden,} from '@jsonforms/core';
import {withJsonFormsEnumProps} from '@jsonforms/react';
import {withVanillaControlProps} from '@jsonforms/vanilla-renderers';
import merge from 'lodash/merge';
import {Label, RadioOption} from './styles';

import {StageContext} from '../../Stages';
import {getAttributeDescription} from 'utils/helpers';

export const RadioGroupControl = (props) => {
	const {classNames, id, label, options, required, description, errors, data, uischema, visible, config, enabled, path, handleChange} = props;

	const {commonStyles, clientData, widgetId} = useContext(StageContext);
	const orientation = clientData.RadioGroupControl?.orientation;
	const {components: {radioGroup}} = commonStyles;

	const [isFocused, setIsFocused] = useState(false);
	const radioControl = 'radio';
	const isValid = errors.length === 0;
	const divClassNames = [classNames.validation]
		.concat(isValid ? classNames.description : classNames.validationError)
		.join(' ');
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const hasRadioClass = !radioControl || radioControl === 'radio';
	let groupStyle = {};
	if (hasRadioClass) {
		groupStyle = {
			display: 'flex',
			flexDirection: orientation || 'column',
			flexWrap: 'wrap',
			gap: '12px'
		};
	}
	const onChange = (ev) => {
		handleChange(path, ev.currentTarget.value);
	};

	const attribute_description = getAttributeDescription(path, clientData.controlsWithDescription);
	const error_description = showDescription ? description : null;
	const buttonType = radioGroup.type === 'button';
	return <div
		className={classNames.wrapper}
		hidden={!visible}
		onFocus={() => setIsFocused(true)}
		onBlur={() => setIsFocused(false)}
	>
		<label htmlFor={id}>
			{computeLabel(
				label,
				required,
				appliedUiSchemaOptions.hideRequiredAsterisk
			)}
		</label>

		{attribute_description && <p className='description'>{description}</p>}
		<div className={radioControl} style={groupStyle}>
			{options?.map((option) => {
				const id = `${widgetId}_${path}_${option.value}`;
				const checked = data === option.value;
				if (option.value) {
					return <RadioOption key={id} buttonType={buttonType}>
						<Label htmlFor={id} style={commonStyles} orientation={orientation} buttonType={buttonType} checked={checked}>
							{option.label}
							<input
								type='radio'
								value={option.value}
								id={id}
								name={id}
								checked={checked}
								onChange={onChange}
								disabled={!enabled}
							/>
						</Label>
					</RadioOption>;
				}
				return null;
			})}
		</div>
		<div className={divClassNames}>
			{!isValid ? errors : error_description}
		</div>
	</div>;
};
RadioGroupControl.propTypes = {
  classNames: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  config: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withVanillaControlProps(
	withJsonFormsEnumProps(RadioGroupControl)
);
