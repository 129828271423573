import React, {useContext} from 'react';
import moment from 'moment';
import { merge } from 'lodash';
import PropTypes from 'prop-types';

import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isDateControl, isDateTimeControl, or, showAsRequired } from '@jsonforms/core';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputLabel } from '@mui/material';
import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

const MuiDatePickerControl = ({ 
    data, 
    id, 
    label, 
    errors, 
    required, 
    uischema: { options, labelPosition }, 
    config, 
    handleChange, 
    path, 
    description 
}) => {
    const appliedUiSchemaOptions = merge({}, config, options);
    const { clientData: {controlsWithDescription} } = useContext(StageContext);

	const isDateOfBirth = path.includes('DateOfBirth');
	const isDateOfLoss = path.includes('DateOfLoss');
	let tomorrow  = moment().add(1,'days').format('MM-DD-YYYY');
	let yesterday  = moment().add(-1,'days').format('MM-DD-YYYY');
	let adult = moment().add(-15, 'years').add(-1, 'days').format('MM-DD-YYYY');
	let minDateOfLoss = moment().add(-3, 'years').add(-1, 'days').format('MM-DD-YYYY');
	let inTwoMonth = moment().add(2, 'months').format('MM-DD-YYYY');

	const lossMinDate = isDateOfLoss ? minDateOfLoss : tomorrow;
	const minDate = isDateOfBirth ? '01-01-1800' : lossMinDate;
	const lossMaxDate = isDateOfLoss ? yesterday : inTwoMonth;
	const maxDate = isDateOfBirth ? adult : lossMaxDate;

    const attribute_description = getAttributeDescription(path, controlsWithDescription);

    return (
        <div id={id}>
            {labelPosition === 'top' && <InputLabel
                htmlFor={id + '-input'}
                error={!!errors}
                required={showAsRequired(required,
                    appliedUiSchemaOptions.hideRequiredAsterisk)}
            >
                {label}
            </InputLabel>}
			{attribute_description && <p className='description'>{description}</p>}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={data}
                    id={`${id}-input`}
                    onChange={(e) => e && handleChange(path, moment(e.toString()).format('YYYY-MM-DD'))}
                    renderInput={(params) => <TextField {...params} variant="standard" error={!!errors} placeholder="MM-DD-YYYY" />}
                    minDate={minDate}
                    maxDate={maxDate}
                    format="MM-DD-YYYY"
                />
            </LocalizationProvider>
        </div>
        
    );
};
MuiDatePickerControl.propTypes = {
    data: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    uischema: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
};

export const MuiDatePickerControlTester = rankWith(
    4,
    or(isDateControl, isDateTimeControl)
);
export default withJsonFormsControlProps(MuiDatePickerControl);