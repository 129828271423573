import React from 'react';
import PropTypes from 'prop-types';
import { ErrorText } from './styles';

const ThemedError = ({
  errors,
  showValidation,
  content,
  themeStyles,
}) => {

  return errors.length && showValidation ? (
    <ErrorText className='error' style={themeStyles}>{content}</ErrorText>
  ) : (
    ''
  );
};
ThemedError.propTypes = {
  errors: PropTypes.any.isRequired,
  showValidation: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  themeStyles: PropTypes.any.isRequired,
};
export default ThemedError;
