import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Title = styled.h2`
  font-family: ${(props) => props.style?.components.headings.fontFamily};  
  font-weight: ${(props) => props.style?.components.headings.fontWeight};  
  color: ${props => props.color || props.style?.components.headings.color};
  margin: ${props => props.color || props.style?.components.headings.margin};
  font-style: normal;
  text-align: center;
  text-align: ${(props) => props.textAlign};

  button {
    margin-right: 10px;
  }

  ${(props) => props.variant === 'h1' && `
    font-size: 52px;
    line-height: 64px;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(52)};
      line-height: ${px2vw(64)};
    }
  `}
  ${(props) => props.variant === 'h2' && `
    font-size: 38px;
    line-height: 48px;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(38)};
      line-height: ${px2vw(48)};
    }
  `}
  ${(props) => props.variant === 'h3' && `
    font-size: 32px;
    line-height: 48px;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(32)};
      line-height: ${px2vw(48)};
    }
  `}
  ${(props) => props.variant === 'h4' && `
    font-size: 24px;
    line-height: 32px;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(24)};
      line-height: ${px2vw(32)};
    }
  `}
  ${(props) => props.variant === 'h5' && `
    font-size: ${props.style?.components.headings.fontSize || '20px'};
    line-height: ${props.style?.components.headings.lineHeight || '24px'};

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(props.style?.components.headings.fontSize?.replace('px', '') || 20)};
      line-height: ${px2vw(props.style?.components.headings.fontHeight?.replace('px', '') || 24)};
    }
  `}
  ${(props) => props.variant === 'h6' && `
    font-size: 18px;
    line-height: 24px;

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(18)};
      line-height: ${px2vw(24)};
    }
  `}
`;
