import {useMemo} from 'react';
import {cloneDeep, isEmpty, merge} from 'lodash';

export const useClientDataForStep = (step, client, result) => {
    return useMemo(() => {

        if (!client) return {};
        const clientData = cloneDeep(client);
        let styles = {};

        const removeEmpty = (obj) => {
            return Object.entries(obj)
                // eslint-disable-next-line no-unused-vars
                .filter(([_, v]) => !isEmpty(v))
                .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty({...v}) : v}), {});
        };

        if (!step || !clientData[step] || Object.keys(clientData[step]).length <= 0) return clientData;

        if(!isEmpty(clientData[step].styles)) {
            styles = removeEmpty(clientData[step].styles);
        }
        return {...merge(clientData, clientData[step]), styles: merge(clientData[step]?.[result]?.styles ? clientData[step]?.[result]?.styles : clientData.styles,styles)};

    }, [step, result]);
};


export const getCurrentStageName = (CTA, error, step, modal) => {
    if(CTA && !modal) return 'CtaStage';
    if(error) return 'ErrorPage';
    return step;
};
