import styled from 'styled-components';
import { Button } from '@mui/material';
import { px2vw } from 'utils/px2vw';

export const StepHeader = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  width: 100%;
  .MuiIconButton-root {
    padding: 5.5px;

    @media screen and (min-width: 1921px) {
      padding: ${px2vw(5.5)};
    }
  }
`;

export const StepCount = styled.span`
  font-weight: 700;
  letter-spacing: 1px;
  font-family: 'Arial', sans-serif;
  color: ${props => props.style.colors.secondary};
  font-size: ${props => props.clientWidth < 470 ? '12px' : '16px'};
  line-height: ${props => props.clientWidth < 470 ? '16px' : '24px'};
  width: ${props => props.clientWidth < 470 ? '60px' : '100px'};
  text-align: right;

  @media screen and (min-width: 1921px) {
    letter-spacing: ${px2vw(1)};
    font-size: ${px2vw(16)};
    line-height: ${px2vw(24)};
    width: ${px2vw(100)};
  }
`;

export const FlexButton = styled(Button)`
  &.MuiButton-root {
    display: flex;
    justify-content: center;
  }
`;

export const LogoWrapper = styled.div`
  justify-content: ${(props) => props.justifyContent};
  width: 100%;
  display: flex;
`;
