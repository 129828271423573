import {
	ACCENT,
	DANGER,
	FORM_BG,
	HEADINGS_COLOR,
	INPUT_BG,
	INPUT_COLOR,
	INPUT_PADDING,
	PARAGRAPH_COLOR,
	PRIMARY,
	SECONDARY
} from './stylesVariables';

const mainStyles = (styles, heroImage, theme) => {
	return {
		...styles,
		colors: {
			primary: styles?.colors?.primary || PRIMARY,
			secondary: styles?.colors?.secondary || SECONDARY,
			accent: ACCENT,
			danger: styles?.colors?.danger || DANGER,
		},
		components: {
			headings: {
				color: styles?.components?.headings?.color ? styles?.components?.headings?.color : HEADINGS_COLOR,
				fontSize: styles?.components?.headings?.fontSize || '20px',
				lineHeight: styles?.components?.headings?.lineHeight || '24px',
				fontFamily: styles?.components?.headings?.fontFamily,
				margin: styles?.components?.headings?.margin || '0',
				fontWeight: styles?.components?.headings?.fontWeight || '700',
			},
			paragraphs: {
				color: styles?.components?.paragraphs?.color ? styles?.components?.paragraphs?.color : PARAGRAPH_COLOR,
				fontSize: styles?.components?.paragraphs?.fontSize || '15px',
				lineHeight: styles?.components?.paragraphs?.lineHeight || '24px',
				fontFamily: styles?.components?.paragraphs?.fontFamily
			},
			form: {
				background: styles?.components?.form?.background,
				borderRadius: styles?.components?.form?.borderRadius,
				padding: styles?.components?.form?.padding,
			},
			label: {
				fontSize: styles?.components?.label?.fontSize || '14px',
				fontFamily: styles?.components?.label?.fontFamily,
				color: styles?.components?.label?.color ? styles?.components?.label?.color : HEADINGS_COLOR,
			},
			input: {
				background: styles?.components?.input?.background || INPUT_BG,
				color: styles?.components?.input?.color || INPUT_COLOR,
				padding: styles?.components?.input?.padding || theme !== 'material' && INPUT_PADDING,
				fontSize: styles?.components?.input?.fontSize || '14px',
				borderRadius: styles?.components?.input?.borderRadius,
				border: styles?.components?.input?.border || '1px solid #b9b9c9',
				minHeight: styles?.components?.input?.minHeight || '42px',
				underline: styles?.components?.input?.underline || true,
				boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${SECONDARY}`,
				dangerShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${DANGER}`
			},
			radioGroup: {
				fontSize: styles?.components?.radioGroup?.fontSize || '14px',
				color: styles?.components?.radioGroup?.color || INPUT_COLOR,
				background: styles?.components?.radioGroup?.background || INPUT_BG,
				borderRadius: styles?.components?.radioGroup?.borderRadius,
				border: styles?.components?.radioGroup?.border || '1px solid #b9b9c9',
				buttonStyles: styles?.components?.radioGroup?.buttonStyles || {},
				minHeight: styles?.components?.radioGroup?.minHeight || '42px',
				boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${SECONDARY}`,
				dangerShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${DANGER}`,
				fontFamily:  styles?.components?.radioGroup?.fontFamily || styles?.components?.paragraphs?.fontFamily,
				type: styles?.components?.radioGroup?.type === 'button' ? styles?.components?.radioGroup?.type : 'radio',
				markBorder: styles?.components?.radioGroup?.markBorder || '#CDD8DE',
				checkedStyles: {
					color:  styles?.components?.radioGroup?.checkedStyles?.color || styles?.components?.radioGroup?.color || INPUT_COLOR,
					background: styles?.components?.radioGroup?.checkedStyles?.background || styles?.colors?.secondary || SECONDARY,
					border: styles?.components?.radioGroup?.checkedStyles?.border || styles?.components?.radioGroup?.border || '1px solid #b9b9c9',
					borderColor: styles?.components?.radioGroup?.checkedStyles?.borderColor || '#b9b9c9',
					markBorder: styles?.components?.radioGroup?.checkedStyles?.markBorder || '#CDD8DE',
				}
			},
			container: {
				background: styles?.components?.container?.background || FORM_BG,
				borderRadius: styles?.components?.container?.borderRadius || '12px',
			},
			footer: {
				fontSize: styles?.components?.footer?.fontSize || '14px',
				fontFamily: styles?.components?.footer?.fontFamily,
				color: styles?.components?.footer?.color || INPUT_COLOR,
				lineHeight: styles?.components?.footer?.lineHeight || '24px',
				textAlign: styles?.components?.footer?.textAlign,
			},
			button: {
				background: styles?.components?.button?.background,
				padding: styles?.components?.button?.padding || '12px 20px',
				color: styles?.components?.button?.color,
				borderRadius: styles?.components?.button?.borderRadius,
				border: styles?.components?.button?.border,
				fontSize: styles?.components?.button?.fontSize || '14px',
			},
			progressBar: {
				text: {
					color: styles?.components?.progressBar?.text?.color || '#85949E',
					fontSize: styles?.components?.progressBar?.text?.fontSize || '12px',
					lineHeight: styles?.components?.progressBar?.text?.lineHeight || '140%',
					fontWeight: styles?.components?.progressBar?.text?.fontWeight || '400',
					fontFamily: styles?.components?.progressBar?.text?.fontFamily || styles?.components?.paragraphs?.fontFamily,
				},
				activeText: {
					color: styles?.components?.progressBar?.activeText?.color || '#292929',
					fontSize: styles?.components?.progressBar?.activeText?.fontSize || '12px',
					lineHeight: styles?.components?.progressBar?.activeText?.lineHeight || '140%',
					fontWeight: styles?.components?.progressBar?.activeText?.fontWeight || '400',
					fontFamily: styles?.components?.progressBar?.activeText?.fontFamily || styles?.components?.paragraphs?.fontFamily,
				},
				bar: {
					background: styles?.components?.progressBar?.bar?.background || '#DDE5EA',
					height: styles?.components?.progressBar?.bar?.height || '7px',
					borderRadius: styles?.components?.progressBar?.bar?.borderRadius || '200px',
					opacity: styles?.components?.progressBar?.bar?.opacity || '0.5',
				},
				activeBar: {
					background: styles?.components?.progressBar?.activeBar?.background || '#007DD7',
					opacity: styles?.components?.progressBar?.activeBar?.opacity || '1',
				},
			}
		}
	};
};
export default mainStyles;
