import React, {useContext, useState, useMemo} from 'react';
import {JsonForms} from '@jsonforms/react';
import {Interweave} from 'interweave';
import PropTypes from 'prop-types';

import {IconButton} from './styles';
import {Block, Container} from 'pages/Main/styles';

import Icon from '../../Icon/icon';
import Header from '../../Header/StepperHeader';
import BannerImage from '../../BannerImage';
import FormContext from '../../FormContext/FormContext';
import ProgressBar from '../../ProgressBar';

import {VehiclesProvider} from '../../FormContext/VehiclesContext';
import useEnterClick from 'utils/useEnterClick';
import {StyleContext} from 'pages/Main';
import {StageContext} from '../../Stages';

import {
  BorderedPaddingBox,
  Button,
  Col,
  Error,
  MarginTopBox,
  Paragraph,
  Row,
  StyledForm,
  Title,
  Footer,
  FormWrapper,
  Description,
  BackButton
} from 'shared/ui';

function CustomVehicleTemplate({
  data,
  setData,
  stepNumber,
  toPrevStep,
  toNextStep,
  schema,
  uiSchema,
  jsonFormCells,
  jsonFormRenderers,
}) {
  const { clientWidth } = useContext(StyleContext);
  const {
    commonStyles,
    currentStageNumber,
    clientData: { fullSize, smallSize, isMicroSite, title, button, heroImage, form, progressBar } = {}
  } = useContext(StageContext);

  const [showValidation, setShowValidation] = useState(false);
  const [allFieldsAreEmpty, setAllFieldsAreEmpty] = useState(true);
  const [errors, setErrors] = useState([]);
  const [updatedData, setUpdatedData] = useState(data);
  const [additionalErrors, setAdditionalErrors] = useState([]);
  const [fieldsExpanded, setFieldsExpanded] = useState(false);

  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';

  const handleSubmit = () => {
    if (errors.length) {
      setShowValidation(true);
    } else {
      toNextStep();
    }
  };
  const handleFormChange = ({ data, errors: errorsForm }) => {
    setUpdatedData(data);
    setErrors([...errorsForm, ...additionalErrors]);
    setAllFieldsAreEmpty(Object.values(data).every(value => !value));
  };

  const handleUpdate = () => {
    if (errors.length) {
      setShowValidation(true);
    } else {
      setData(updatedData);
      setFieldsExpanded(false);
    }
  };

  const handleBack = () => {
    if (setFieldsExpanded && fieldsExpanded) {
        setFieldsExpanded(false);
        return;
    }
    toPrevStep();
  };

  useEnterClick(fieldsExpanded ? handleUpdate : handleSubmit, [updatedData, errors, data, toNextStep]);

  const providerValue = useMemo(() => {
    return {data: updatedData, errors, setErrors, showValidation, setAdditionalErrors };
  }, [updatedData, showValidation, errors, setErrors, setAdditionalErrors]);

  const currentDataKeys = Object.keys(schema.properties);

  return (
    <Container
      style={commonStyles}
      position={heroImage?.position}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block width={form?.width} className="ix-step-wrapper">
        <FormWrapper commonStyles={commonStyles}>
          {progressBar?.visible && <ProgressBar />}
          <Header
            stepNumber={stepNumber}
            handleBack={handleBack}
          />
          <div className='ix-content' style={{height: '100%', display: 'flex', flexDirection: 'column', width: form?.width || 'auto'}}>
            <Col>
              {(!title || title?.visible || title?.visible === undefined) &&
                <MarginTopBox className={'ix-title-div'}>
                  <Row justifyContent={'center'}>
                    <Title
                      variant={isMicroSite && clientWidth >= 470 ? 'h3' : 'h5'}
                      className={'ix-title'}
                      textAlign={'left'}
                      color={'#010101'}
                    >
                      <BackButton handleBack={handleBack} backButton={title?.backButton} visible={currentStageNumber !== 1 && title?.backButton?.visible} />
                      <Interweave content={title?.content || `Confirm your ${schema.stepName}(s)`} />
                    </Title>
                  </Row>
                </MarginTopBox>
              }
                <Description step={schema.stepName} position='top'/>
                <MarginTopBox className={'ix-form-div'} display={!fieldsExpanded && 'none'}>
                  <StyledForm showWarnings={showValidation && !allFieldsAreEmpty} headingsColor={'#010101'} placeholdersColor={'#b9b9c9'}>
                    <FormContext.Provider value={providerValue}>
                      <VehiclesProvider>
                        <JsonForms
                          schema={schema}
                          uischema={uiSchema}
                          data={updatedData}
                          renderers={jsonFormRenderers}
                          cells={jsonFormCells}
                          validationMode={
                            showValidation ? 'ValidateAndShow' : 'ValidateAndHide'
                          }
                          onChange={handleFormChange}
                        />
                      </VehiclesProvider>
                    </FormContext.Provider>
                    {allFieldsAreEmpty &&
                      <Row>
                        <Error
                          errors={errors}
                          showValidation={showValidation}
                          content={'* Fields in red are required.'}
                        />
                      </Row>
                    }
                    <Description step={schema.stepName} position='bottom'/>
                    <MarginTopBox fullSizeMarginTop='10px'>
                      <Row justifyContent={['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'center'}>
                        <Button
                          variant='contained'
                          color='secondary'
                          className='secondary'
                          onClick={handleUpdate}
                          text={'Confirm'}
                          flex={button?.placement === 'fullwidth'}
                          borderRadius={button?.borderRadius}
                        />
                      </Row>
                    </MarginTopBox>
                  </StyledForm>
                </MarginTopBox>

              {!fieldsExpanded && ( <MarginTopBox className={'ix-form-div'}>
                  <BorderedPaddingBox
                    onClick={smallSize ? () => setFieldsExpanded(true) : undefined}
                  >
                    <Row>
                      <Row>
                        <IconButton style={{ marginRight: '12px' }}>
                          <Icon name={'checked'} width='24' height='24' fill={'#009C10'} />
                        </IconButton>
                        <Paragraph color={'#2b2c41'}>
                          {currentDataKeys
                            .reduce((acc, key) => data[key] ? [...acc, data[key]] : acc, [])
                            .join(', ')}
                        </Paragraph>
                      </Row>
                      <IconButton onClick={() => setFieldsExpanded(true)}>
                        <Icon name='editIcon' width='24' height='24' fill='#000000' />
                      </IconButton>
                    </Row>
                  </BorderedPaddingBox>
                </MarginTopBox>
              )}
              {!fieldsExpanded && (
                <MarginTopBox className={'ix-submit-button-div'} smallSizeMarginTop={'16px'}>
                  <Row>
                    <Error
                      errors={errors}
                      showValidation={showValidation}
                      content={'* Fields in red are required.'}
                    />
                  </Row>
                  <Row justifyContent={['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'center'}>
                    <Button
                      variant='contained'
                      color='secondary'
                      className='secondary'
                      onClick={handleSubmit}
                      text={button?.content || 'Confirm'}
                      flex={button?.placement === 'fullwidth'}
                      borderRadius={button?.borderRadius}
                    />
                  </Row>
                </MarginTopBox>
              )}
            </Col>
          </div>
        </FormWrapper>
        <Footer />
      </Block>
    </Container>
  );
}
CustomVehicleTemplate.propTypes = {
  data: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  uiSchema: PropTypes.any.isRequired,
  jsonFormCells: PropTypes.any.isRequired,
  jsonFormRenderers: PropTypes.any.isRequired,
  setData: PropTypes.func.isRequired,
  stepNumber: PropTypes.func.isRequired,
  toPrevStep: PropTypes.func.isRequired,
  toNextStep: PropTypes.func.isRequired,
};

export default CustomVehicleTemplate;
