import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: ${props => props.style.components.container.borderRadius};
  background: ${props => props.style.components.container.background};
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;

  &.fullSize {
    display: flex;
    padding: 60px;
  }
  &.smallSize {
    padding: 10px;
  }
  
  @media screen and (min-width: 1921px) {
    padding: ${px2vw(16)};
    border-radius: ${props => px2vw(props.style.components.container.borderRadius)};

    &.fullSize {
      padding: ${px2vw(60)};
    }
  }

  @media screen and (max-width: 900px) {
    &.fullSize {
      padding: 16px;
    }
  }
  label.MuiInputLabel-shrink, label.MuiFormLabel-root.Mui-focused {
    color: ${props => props.style.colors.secondary};
  }
  
  input.MuiInput-input {
    ::placeholder {
      opacity: 1;
    }
  }
  
  div.Mui-error {
    input.MuiInput-input {
      ::placeholder {
        color: #d32f2f;
      }
    }
  }
  
  &>div:last-child {
    width: 100%;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;
    box-sizing: border-box;
  }

  ${props => props.position === 'bottom' && `
    display: flex;
    flex-direction: column-reverse;
  `}
  ${props => props.position === 'left' && `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
  ${props => props.position === 'right' && `
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  `}
  ${props => props.position === 'contain' && `
    label.MuiInputLabel-shrink, label.MuiFormLabel-root.Mui-focused {
      color: #ffffff;
    }
    .error {
      color: #FDE2E2;
    }
  `}
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  
  height: 100%;
  position: relative;
  z-index: 1;
  max-width: ${props => props.width || '1005px'};
  margin: auto;

  @media screen and (min-width: 1921px) {
    max-width: ${props => px2vw(props.width ||1005)};
  }
`;
