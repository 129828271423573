import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {getCarMakes} from 'api/IXWidgetApi';
import FormContext from '../../FormContext/FormContext';
import VehicleSelect from './VehicleSelect';

const VehicleMakeControl = ({data, label, required, id, handleChange, path, errors }) => {
	const { data: { Year: modelYear } } = useContext(FormContext);
	const [makesData, setMakesData] = useState([]);
	useEffect(() => {
		const getYearsData = async () => {
			try {
				const response = await getCarMakes(modelYear);
				setMakesData(response.data && response.data.data.length > 0 ? [{name: '', id: 'None'}, ...response.data.data] : [{name: data}]);
			} catch (e) {
				setMakesData([{name: data}]);
				handleChange(path, data || undefined);
			}
		};
		if(modelYear) {
			getYearsData().then(r => r);
		}

	}, [modelYear]);

	const formattedData = useMemo(() => {
		return makesData.map(item => ({name: item.name, id: item.id}));
	}, [makesData]);

	useEffect(() => {
		if(!data && formattedData.length > 0) {
			handleChange(path, undefined);
		}
	}, [formattedData]);

	const changeHandler = (event) => {
		handleChange(path, event.target.value || undefined);
	};
	useEffect(() => {
		if(formattedData.length === 0 ) {
			return;
		}
		const newItem = formattedData.find((item) => item.name.toLowerCase() === data?.toLowerCase());
		if(!newItem)  {
			handleChange(path, formattedData[0].name || undefined);
		}
	}, [data, formattedData]);
	const newValue = formattedData.length > 0 ? formattedData.find((item) => {
		return item.name.toLowerCase() === data?.toLowerCase();
	})?.name || '' : '';
	return (
		<div>
			<VehicleSelect label={label} changeHandler={changeHandler} id={id} required={required} data={formattedData} value={newValue} errors={errors} path={path}/>
		</div>
	);
};
VehicleMakeControl.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withJsonFormsControlProps(VehicleMakeControl);
