import React from 'react';

export const APP_ICONS = Object.freeze({
  spinner: (props) => (
    <>
      <path
        opacity='0.25'
        d='M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM9 45C9 64.8822 25.1177 81 45 81C64.8822 81 81 64.8822 81 45C81 25.1177 64.8822 9 45 9C25.1177 9 9 25.1177 9 45Z'
        fill={props.fill}
      />
      <path
        d='M45 90C39.0905 90 33.2389 88.836 27.7792 86.5746C22.3196 84.3131 17.3588 80.9984 13.1802 76.8198C9.00156 72.6412 5.68688 67.6804 3.42542 62.2208C1.16396 56.7611 -5.16623e-07 50.9095 0 45L9 45C9 49.7276 9.93117 54.4089 11.7403 58.7766C13.5495 63.1443 16.2012 67.1129 19.5442 70.4558C22.8871 73.7988 26.8557 76.4505 31.2234 78.2597C35.5911 80.0688 40.2724 81 45 81L45 90Z'
        fill={props.fill}
      />
    </>
  ),
  carIcon: (props) => (
    <path
      d="M23.8334 8.82019C23.7763 8.65411 23.5741 8.52494 23.4053 8.52494C22.6743 8.52494 21.9433 8.52494 21.2123 8.52494C21.0435 8.52494 20.8413 8.65411 20.7842 8.82019C20.7284 8.98275 20.6866 9.12779 20.6589 9.26419L19.194 5.21151C18.9524 4.54323 18.3125 4.09424 17.602 4.09424H6.39809C5.68749 4.09424 5.04773 4.54323 4.80599 5.21163L3.34105 9.26468C3.31326 9.12815 3.27154 8.98293 3.21565 8.82019C3.15861 8.65411 2.95634 8.52494 2.78759 8.52494C2.05661 8.52494 1.32562 8.52494 0.594583 8.52494C0.425825 8.52494 0.223559 8.65411 0.166515 8.82019C-0.0555761 9.46682 -0.0554545 9.837 0.166576 10.4837C0.22362 10.6498 0.425886 10.7789 0.594644 10.7789C1.32569 10.7789 2.05667 10.7789 2.78765 10.7789C2.78971 10.7789 2.7919 10.7784 2.79403 10.7783L2.76095 10.87C2.31379 11.0185 1.99093 11.4395 1.99093 11.9366V15.3303V15.61V19.3653C1.99093 19.6639 2.23284 19.9058 2.53138 19.9058H5.25139C5.54986 19.9058 5.79184 19.6639 5.79184 19.3653V16.7344H18.2082V19.3653C18.2082 19.6639 18.4501 19.9058 18.7486 19.9058H21.4686C21.7672 19.9058 22.0091 19.6639 22.0091 19.3653V15.61V15.3303V11.9366C22.0091 11.4396 21.6863 11.0187 21.2394 10.87L21.2062 10.7783C21.2082 10.7784 21.2104 10.7789 21.2124 10.7789C21.9434 10.7789 22.6743 10.7789 23.4054 10.7789C23.5742 10.7789 23.7764 10.6497 23.8334 10.4836C24.0555 9.837 24.0553 9.46682 23.8334 8.82019ZM6.60504 6.08309H17.3935L19.1029 10.8121H4.89575L6.60504 6.08309ZM6.90595 14.1435H4.63042C4.14221 14.1435 3.7465 13.7478 3.7465 13.2596C3.7465 12.7714 4.14215 12.3757 4.63042 12.3757H6.90595C7.3941 12.3757 7.78988 12.7714 7.78988 13.2596C7.78994 13.7478 7.3941 14.1435 6.90595 14.1435ZM19.3694 14.1435H17.0939C16.6057 14.1435 16.21 13.7478 16.21 13.2596C16.21 12.7714 16.6057 12.3757 17.0939 12.3757H19.3694C19.8576 12.3757 20.2533 12.7714 20.2533 13.2596C20.2533 13.7478 19.8576 14.1435 19.3694 14.1435Z"
      fill={props.fill}
    />
  ),
  brandLogo: (props) => (
    <>
      <path
        d="M14 46.8363V22.3999H24.5955C26.4568 22.3999 28.0199 22.6505 29.2847 23.1516C30.5574 23.6527 31.5159 24.3607 32.1602 25.2755C32.8125 26.1902 33.1386 27.2681 33.1386 28.509C33.1386 29.4079 32.9398 30.2272 32.542 30.9669C32.1523 31.7067 31.6034 32.3272 30.8955 32.8283C30.1875 33.3215 29.3602 33.6635 28.4136 33.8544V34.0931C29.4636 34.1329 30.4182 34.3993 31.2773 34.8925C32.1364 35.3777 32.8205 36.0499 33.3295 36.909C33.8386 37.7601 34.0932 38.7624 34.0932 39.9158C34.0932 41.2522 33.7432 42.4414 33.0432 43.4834C32.3511 44.5255 31.3648 45.3448 30.0841 45.9414C28.8034 46.538 27.2761 46.8363 25.5023 46.8363H14ZM20.6341 41.5385H23.7364C24.85 41.5385 25.6852 41.3317 26.242 40.9181C26.7989 40.4965 27.0773 39.876 27.0773 39.0567C27.0773 38.484 26.946 37.9988 26.6835 37.601C26.421 37.2033 26.0472 36.901 25.5619 36.6942C25.0847 36.4874 24.508 36.384 23.8318 36.384H20.6341V41.5385ZM20.6341 32.2794H23.3545C23.9352 32.2794 24.4483 32.188 24.8938 32.005C25.3392 31.8221 25.6852 31.5596 25.9318 31.2175C26.1864 30.8675 26.3136 30.4419 26.3136 29.9408C26.3136 29.1851 26.0432 28.6084 25.5023 28.2107C24.9614 27.805 24.2773 27.6022 23.45 27.6022H20.6341V32.2794Z"
        fill="#6266EA"
      />
      <path
        d="M36.2032 46.8363V28.509H42.5987V31.9931H42.7896C43.1237 30.7044 43.6526 29.7618 44.3765 29.1652C45.1083 28.5687 45.9634 28.2704 46.9418 28.2704C47.2282 28.2704 47.5106 28.2942 47.789 28.3419C48.0754 28.3817 48.3498 28.4414 48.6123 28.5209V34.1408C48.2862 34.0294 47.8845 33.9459 47.4072 33.8902C46.9299 33.8346 46.5203 33.8067 46.1782 33.8067C45.5339 33.8067 44.9532 33.9539 44.4362 34.2482C43.9271 34.5346 43.5254 34.9402 43.231 35.4652C42.9367 35.9823 42.7896 36.5908 42.7896 37.2908V46.8363H36.2032Z"
        fill="#6266EA"
      />
      <path
        d="M54.6897 47.1226C53.5203 47.1226 52.4862 46.9317 51.5874 46.5499C50.6965 46.1601 49.9965 45.5715 49.4874 44.784C48.9783 43.9965 48.7237 42.9942 48.7237 41.7772C48.7237 40.7749 48.8948 39.9198 49.2368 39.2118C49.5789 38.4959 50.0561 37.9113 50.6686 37.4579C51.2811 37.0044 51.9931 36.6584 52.8044 36.4198C53.6237 36.1812 54.5067 36.026 55.4533 35.9544C56.4794 35.8749 57.3027 35.7794 57.9232 35.6681C58.5516 35.5488 59.005 35.3857 59.2834 35.1789C59.5618 34.9641 59.701 34.6817 59.701 34.3317V34.284C59.701 33.8067 59.5181 33.4408 59.1521 33.1863C58.7862 32.9317 58.3169 32.8044 57.7442 32.8044C57.1158 32.8044 56.6027 32.9437 56.205 33.2221C55.8152 33.4925 55.5806 33.9101 55.501 34.4749H49.4397C49.5192 33.3613 49.8732 32.3351 50.5016 31.3965C51.1379 30.4499 52.0647 29.6942 53.2817 29.1294C54.4987 28.5567 56.0181 28.2704 57.8396 28.2704C59.1521 28.2704 60.3294 28.4255 61.3715 28.7357C62.4135 29.038 63.3004 29.4635 64.0323 30.0124C64.7641 30.5533 65.3209 31.1897 65.7027 31.9215C66.0925 32.6454 66.2874 33.4329 66.2874 34.284V46.8363H60.1306V44.259H59.9874C59.6215 44.9431 59.176 45.4959 58.651 45.9175C58.134 46.3391 57.5414 46.6454 56.8732 46.8363C56.2129 47.0272 55.4851 47.1226 54.6897 47.1226ZM56.8374 42.9704C57.3385 42.9704 57.8078 42.867 58.2453 42.6601C58.6908 42.4533 59.0527 42.155 59.3311 41.7652C59.6095 41.3755 59.7487 40.9022 59.7487 40.3454V38.8181C59.5737 38.8897 59.3868 38.9573 59.1879 39.0209C58.997 39.0846 58.7902 39.1442 58.5675 39.1999C58.3527 39.2556 58.122 39.3073 57.8754 39.355C57.6368 39.4027 57.3862 39.4465 57.1237 39.4863C56.6147 39.5658 56.197 39.6971 55.8709 39.88C55.5527 40.055 55.3141 40.2738 55.155 40.5363C55.0039 40.7908 54.9283 41.0772 54.9283 41.3954C54.9283 41.9044 55.1073 42.2942 55.4652 42.5647C55.8232 42.8351 56.2806 42.9704 56.8374 42.9704Z"
        fill="#6266EA"
      />
      <path
        d="M75.6848 36.5272V46.8363H69.0984V28.509H75.3507V31.9931H75.5416C75.9393 30.8317 76.6393 29.9209 77.6416 29.2607C78.6518 28.6005 79.8291 28.2704 81.1734 28.2704C82.47 28.2704 83.5956 28.5687 84.5501 29.1652C85.5126 29.7539 86.2563 30.5652 86.7813 31.5993C87.3143 32.6334 87.5768 33.8147 87.5688 35.1431V46.8363H80.9825V36.5272C80.9904 35.6204 80.7598 34.9084 80.2904 34.3914C79.8291 33.8743 79.1848 33.6158 78.3575 33.6158C77.8166 33.6158 77.3433 33.7351 76.9376 33.9738C76.5399 34.2044 76.2336 34.5385 76.0188 34.976C75.8041 35.4056 75.6927 35.9226 75.6848 36.5272Z"
        fill="#6266EA"
      />
      <path
        d="M97.1184 47.0749C95.8138 47.0749 94.6127 46.7329 93.515 46.0488C92.4252 45.3647 91.5502 44.3266 90.89 42.9346C90.2377 41.5425 89.9115 39.7885 89.9115 37.6726C89.9115 35.4613 90.2536 33.6635 90.9377 32.2794C91.6297 30.8954 92.5206 29.8812 93.6104 29.2368C94.7081 28.5925 95.8615 28.2704 97.0706 28.2704C97.9775 28.2704 98.7729 28.4294 99.457 28.7476C100.141 29.0579 100.714 29.4755 101.175 30.0005C101.637 30.5175 101.987 31.0863 102.225 31.7067H102.321V22.3999H108.907V46.8363H102.368V43.8294H102.225C101.971 44.4499 101.605 45.0067 101.127 45.4999C100.658 45.9851 100.085 46.3709 99.4093 46.6573C98.7411 46.9357 97.9775 47.0749 97.1184 47.0749ZM99.5525 42.0158C100.157 42.0158 100.674 41.8408 101.104 41.4908C101.541 41.1329 101.875 40.6317 102.106 39.9874C102.344 39.3351 102.464 38.5635 102.464 37.6726C102.464 36.7658 102.344 35.9902 102.106 35.3459C101.875 34.6937 101.541 34.1965 101.104 33.8544C100.674 33.5044 100.157 33.3294 99.5525 33.3294C98.9479 33.3294 98.4309 33.5044 98.0013 33.8544C97.5797 34.1965 97.2536 34.6937 97.0229 35.3459C96.8002 35.9902 96.6888 36.7658 96.6888 37.6726C96.6888 38.5795 96.8002 39.359 97.0229 40.0113C97.2536 40.6556 97.5797 41.1527 98.0013 41.5027C98.4309 41.8448 98.9479 42.0158 99.5525 42.0158Z"
        fill="#6266EA"
      />
      <path
        d="M131.433 22.3999V46.8363H128.045L115.624 28.9147H115.397V46.8363H111.71V22.3999H115.122L127.555 40.3454H127.782V22.3999H131.433Z"
        fill="#383874"
      />
      <path
        d="M140.179 47.2419C139.017 47.2419 137.967 47.0272 137.029 46.5976C136.09 46.1601 135.346 45.5277 134.797 44.7005C134.257 43.8732 133.986 42.859 133.986 41.6579C133.986 40.6238 134.185 39.7726 134.583 39.1044C134.98 38.4363 135.517 37.9073 136.193 37.5175C136.87 37.1277 137.625 36.8334 138.461 36.6346C139.296 36.4357 140.147 36.2846 141.014 36.1812C142.112 36.0539 143.003 35.9505 143.687 35.8709C144.371 35.7834 144.868 35.6442 145.178 35.4533C145.488 35.2624 145.643 34.9522 145.643 34.5226V34.4391C145.643 33.3971 145.349 32.5897 144.761 32.0169C144.18 31.4442 143.313 31.1579 142.159 31.1579C140.958 31.1579 140.012 31.4243 139.32 31.9573C138.636 32.4823 138.162 33.0669 137.9 33.7113L134.547 32.9476C134.945 31.834 135.525 30.9351 136.289 30.251C137.061 29.559 137.947 29.0579 138.95 28.7476C139.952 28.4294 141.006 28.2704 142.112 28.2704C142.843 28.2704 143.619 28.3579 144.438 28.5329C145.266 28.6999 146.037 29.0101 146.753 29.4635C147.477 29.9169 148.07 30.5652 148.531 31.4084C148.992 32.2437 149.223 33.3294 149.223 34.6658V46.8363H145.739V44.3306H145.596C145.365 44.792 145.019 45.2454 144.558 45.6908C144.096 46.1363 143.504 46.5062 142.78 46.8005C142.056 47.0948 141.189 47.2419 140.179 47.2419ZM140.954 44.3783C141.941 44.3783 142.784 44.1834 143.484 43.7937C144.192 43.4039 144.729 42.8948 145.095 42.2664C145.468 41.63 145.655 40.9499 145.655 40.226V37.8635C145.528 37.9908 145.282 38.1101 144.916 38.2215C144.558 38.3249 144.148 38.4164 143.687 38.4959C143.225 38.5675 142.776 38.6351 142.338 38.6988C141.901 38.7544 141.535 38.8022 141.241 38.8419C140.549 38.9294 139.916 39.0766 139.343 39.2834C138.779 39.4902 138.325 39.7885 137.983 40.1783C137.649 40.5601 137.482 41.0692 137.482 41.7056C137.482 42.5885 137.808 43.2567 138.461 43.7101C139.113 44.1556 139.944 44.3783 140.954 44.3783Z"
        fill="#383874"
      />
      <path
        d="M152.295 46.8363V28.509H155.719V31.4919H155.946C156.328 30.4817 156.952 29.6942 157.819 29.1294C158.686 28.5567 159.724 28.2704 160.933 28.2704C162.158 28.2704 163.185 28.5567 164.012 29.1294C164.847 29.7022 165.464 30.4897 165.861 31.4919H166.052C166.49 30.5135 167.186 29.734 168.14 29.1533C169.095 28.5647 170.232 28.2704 171.553 28.2704C173.215 28.2704 174.572 28.7914 175.622 29.8334C176.68 30.8755 177.208 32.4465 177.208 34.5465V46.8363H173.641V34.8806C173.641 33.6397 173.303 32.7408 172.627 32.184C171.951 31.6272 171.143 31.3488 170.205 31.3488C169.043 31.3488 168.14 31.7067 167.496 32.4226C166.852 33.1306 166.53 34.0414 166.53 35.155V46.8363H162.974V34.6539C162.974 33.6596 162.664 32.8601 162.043 32.2556C161.423 31.651 160.615 31.3488 159.621 31.3488C158.945 31.3488 158.32 31.5277 157.748 31.8857C157.183 32.2357 156.726 32.7249 156.376 33.3533C156.033 33.9817 155.862 34.7096 155.862 35.5368V46.8363H152.295Z"
        fill="#383874"
      />
      <path
        d="M188.209 47.2062C186.403 47.2062 184.848 46.8204 183.544 46.0488C182.247 45.2692 181.245 44.1755 180.537 42.7675C179.837 41.3516 179.487 39.6931 179.487 37.7919C179.487 35.9147 179.837 34.2601 180.537 32.8283C181.245 31.3965 182.231 30.2789 183.496 29.4755C184.769 28.6721 186.256 28.2704 187.958 28.2704C188.993 28.2704 189.995 28.4414 190.965 28.7834C191.936 29.1255 192.807 29.6624 193.578 30.3942C194.35 31.126 194.958 32.0766 195.404 33.2459C195.849 34.4073 196.072 35.8192 196.072 37.4817V38.7465H181.503V36.0738H192.576C192.576 35.1351 192.385 34.3039 192.003 33.58C191.622 32.8482 191.085 32.2715 190.393 31.8499C189.708 31.4283 188.905 31.2175 187.982 31.2175C186.98 31.2175 186.105 31.4641 185.357 31.9573C184.618 32.4425 184.045 33.0789 183.639 33.8664C183.241 34.6459 183.043 35.4931 183.043 36.4079V38.4959C183.043 39.7209 183.257 40.763 183.687 41.6221C184.124 42.4812 184.733 43.1374 185.512 43.5908C186.292 44.0363 187.203 44.259 188.245 44.259C188.921 44.259 189.537 44.1635 190.094 43.9726C190.651 43.7738 191.132 43.4795 191.538 43.0897C191.944 42.6999 192.254 42.2187 192.469 41.6459L195.845 42.2544C195.575 43.2488 195.09 44.1198 194.39 44.8675C193.698 45.6073 192.827 46.184 191.777 46.5976C190.735 47.0033 189.545 47.2062 188.209 47.2062Z"
        fill="#383874"
      />
      <rect x="0.5" y="0.5" width={props.width - 1} height={props.height - 1} stroke="#DADAE3" strokeDasharray="2 2"/>
    </>
  ),
  logoWhite: (props) => (
    <>
      <path
        d="M14 46.8363V22.3999H24.5955C26.4568 22.3999 28.0199 22.6505 29.2847 23.1516C30.5574 23.6527 31.5159 24.3607 32.1602 25.2755C32.8125 26.1902 33.1386 27.2681 33.1386 28.509C33.1386 29.4079 32.9398 30.2272 32.542 30.9669C32.1523 31.7067 31.6034 32.3272 30.8955 32.8283C30.1875 33.3215 29.3602 33.6635 28.4136 33.8544V34.0931C29.4636 34.1329 30.4182 34.3993 31.2773 34.8925C32.1364 35.3777 32.8205 36.0499 33.3295 36.909C33.8386 37.7601 34.0932 38.7624 34.0932 39.9158C34.0932 41.2522 33.7432 42.4414 33.0432 43.4834C32.3511 44.5255 31.3648 45.3448 30.0841 45.9414C28.8034 46.538 27.2761 46.8363 25.5023 46.8363H14ZM20.6341 41.5385H23.7364C24.85 41.5385 25.6852 41.3317 26.242 40.9181C26.7989 40.4965 27.0773 39.876 27.0773 39.0567C27.0773 38.484 26.946 37.9988 26.6835 37.601C26.421 37.2033 26.0472 36.901 25.5619 36.6942C25.0847 36.4874 24.508 36.384 23.8318 36.384H20.6341V41.5385ZM20.6341 32.2794H23.3545C23.9352 32.2794 24.4483 32.188 24.8938 32.005C25.3392 31.8221 25.6852 31.5596 25.9318 31.2175C26.1864 30.8675 26.3136 30.4419 26.3136 29.9408C26.3136 29.1851 26.0432 28.6084 25.5023 28.2107C24.9614 27.805 24.2773 27.6022 23.45 27.6022H20.6341V32.2794Z"
        fill="white"
      />
      <path
        d="M36.2032 46.8363V28.509H42.5987V31.9931H42.7896C43.1237 30.7044 43.6526 29.7618 44.3765 29.1652C45.1083 28.5687 45.9634 28.2704 46.9418 28.2704C47.2282 28.2704 47.5106 28.2942 47.789 28.3419C48.0754 28.3817 48.3498 28.4414 48.6123 28.5209V34.1408C48.2862 34.0294 47.8845 33.9459 47.4072 33.8902C46.9299 33.8346 46.5203 33.8067 46.1782 33.8067C45.5339 33.8067 44.9532 33.9539 44.4362 34.2482C43.9271 34.5346 43.5254 34.9402 43.231 35.4652C42.9367 35.9823 42.7896 36.5908 42.7896 37.2908V46.8363H36.2032Z"
        fill="white"
      />
      <path
        d="M54.6897 47.1226C53.5203 47.1226 52.4862 46.9317 51.5874 46.5499C50.6965 46.1601 49.9965 45.5715 49.4874 44.784C48.9783 43.9965 48.7237 42.9942 48.7237 41.7772C48.7237 40.7749 48.8948 39.9198 49.2368 39.2118C49.5789 38.4959 50.0561 37.9113 50.6686 37.4579C51.2811 37.0044 51.9931 36.6584 52.8044 36.4198C53.6237 36.1812 54.5067 36.026 55.4533 35.9544C56.4794 35.8749 57.3027 35.7794 57.9232 35.6681C58.5516 35.5488 59.005 35.3857 59.2834 35.1789C59.5618 34.9641 59.701 34.6817 59.701 34.3317V34.284C59.701 33.8067 59.5181 33.4408 59.1521 33.1863C58.7862 32.9317 58.3169 32.8044 57.7442 32.8044C57.1158 32.8044 56.6027 32.9437 56.205 33.2221C55.8152 33.4925 55.5806 33.9101 55.501 34.4749H49.4397C49.5192 33.3613 49.8732 32.3351 50.5016 31.3965C51.1379 30.4499 52.0647 29.6942 53.2817 29.1294C54.4987 28.5567 56.0181 28.2704 57.8396 28.2704C59.1521 28.2704 60.3294 28.4255 61.3715 28.7357C62.4135 29.038 63.3004 29.4635 64.0323 30.0124C64.7641 30.5533 65.3209 31.1897 65.7027 31.9215C66.0925 32.6454 66.2874 33.4329 66.2874 34.284V46.8363H60.1306V44.259H59.9874C59.6215 44.9431 59.176 45.4959 58.651 45.9175C58.134 46.3391 57.5414 46.6454 56.8732 46.8363C56.2129 47.0272 55.4851 47.1226 54.6897 47.1226ZM56.8374 42.9704C57.3385 42.9704 57.8078 42.867 58.2453 42.6601C58.6908 42.4533 59.0527 42.155 59.3311 41.7652C59.6095 41.3755 59.7487 40.9022 59.7487 40.3454V38.8181C59.5737 38.8897 59.3868 38.9573 59.1879 39.0209C58.997 39.0846 58.7902 39.1442 58.5675 39.1999C58.3527 39.2556 58.122 39.3073 57.8754 39.355C57.6368 39.4027 57.3862 39.4465 57.1237 39.4863C56.6147 39.5658 56.197 39.6971 55.8709 39.88C55.5527 40.055 55.3141 40.2738 55.155 40.5363C55.0039 40.7908 54.9283 41.0772 54.9283 41.3954C54.9283 41.9044 55.1073 42.2942 55.4652 42.5647C55.8232 42.8351 56.2806 42.9704 56.8374 42.9704Z"
        fill="white"
      />
      <path
        d="M75.6848 36.5272V46.8363H69.0984V28.509H75.3507V31.9931H75.5416C75.9393 30.8317 76.6393 29.9209 77.6416 29.2607C78.6518 28.6005 79.8291 28.2704 81.1734 28.2704C82.47 28.2704 83.5956 28.5687 84.5501 29.1652C85.5126 29.7539 86.2563 30.5652 86.7813 31.5993C87.3143 32.6334 87.5768 33.8147 87.5688 35.1431V46.8363H80.9825V36.5272C80.9904 35.6204 80.7598 34.9084 80.2904 34.3914C79.8291 33.8743 79.1848 33.6158 78.3575 33.6158C77.8166 33.6158 77.3433 33.7351 76.9376 33.9738C76.5399 34.2044 76.2336 34.5385 76.0188 34.976C75.8041 35.4056 75.6927 35.9226 75.6848 36.5272Z"
        fill="white"
      />
      <path
        d="M97.1184 47.0749C95.8138 47.0749 94.6127 46.7329 93.515 46.0488C92.4252 45.3647 91.5502 44.3266 90.89 42.9346C90.2377 41.5425 89.9115 39.7885 89.9115 37.6726C89.9115 35.4613 90.2536 33.6635 90.9377 32.2794C91.6297 30.8954 92.5206 29.8812 93.6104 29.2368C94.7081 28.5925 95.8615 28.2704 97.0706 28.2704C97.9775 28.2704 98.7729 28.4294 99.457 28.7476C100.141 29.0579 100.714 29.4755 101.175 30.0005C101.637 30.5175 101.987 31.0863 102.225 31.7067H102.321V22.3999H108.907V46.8363H102.368V43.8294H102.225C101.971 44.4499 101.605 45.0067 101.127 45.4999C100.658 45.9851 100.085 46.3709 99.4093 46.6573C98.7411 46.9357 97.9775 47.0749 97.1184 47.0749ZM99.5525 42.0158C100.157 42.0158 100.674 41.8408 101.104 41.4908C101.541 41.1329 101.875 40.6317 102.106 39.9874C102.344 39.3351 102.464 38.5635 102.464 37.6726C102.464 36.7658 102.344 35.9902 102.106 35.3459C101.875 34.6937 101.541 34.1965 101.104 33.8544C100.674 33.5044 100.157 33.3294 99.5525 33.3294C98.9479 33.3294 98.4309 33.5044 98.0013 33.8544C97.5797 34.1965 97.2536 34.6937 97.0229 35.3459C96.8002 35.9902 96.6888 36.7658 96.6888 37.6726C96.6888 38.5795 96.8002 39.359 97.0229 40.0113C97.2536 40.6556 97.5797 41.1527 98.0013 41.5027C98.4309 41.8448 98.9479 42.0158 99.5525 42.0158Z"
        fill="white"
      />
      <path
        d="M131.433 22.3999V46.8363H128.045L115.624 28.9147H115.397V46.8363H111.71V22.3999H115.122L127.555 40.3454H127.782V22.3999H131.433Z"
        fill="white"
      />
      <path
        d="M140.179 47.2419C139.017 47.2419 137.967 47.0272 137.029 46.5976C136.09 46.1601 135.346 45.5277 134.797 44.7005C134.257 43.8732 133.986 42.859 133.986 41.6579C133.986 40.6238 134.185 39.7726 134.583 39.1044C134.98 38.4363 135.517 37.9073 136.193 37.5175C136.87 37.1277 137.625 36.8334 138.461 36.6346C139.296 36.4357 140.147 36.2846 141.014 36.1812C142.112 36.0539 143.003 35.9505 143.687 35.8709C144.371 35.7834 144.868 35.6442 145.178 35.4533C145.488 35.2624 145.643 34.9522 145.643 34.5226V34.4391C145.643 33.3971 145.349 32.5897 144.761 32.0169C144.18 31.4442 143.313 31.1579 142.159 31.1579C140.958 31.1579 140.012 31.4243 139.32 31.9573C138.636 32.4823 138.162 33.0669 137.9 33.7113L134.547 32.9476C134.945 31.834 135.525 30.9351 136.289 30.251C137.061 29.559 137.947 29.0579 138.95 28.7476C139.952 28.4294 141.006 28.2704 142.112 28.2704C142.843 28.2704 143.619 28.3579 144.438 28.5329C145.266 28.6999 146.037 29.0101 146.753 29.4635C147.477 29.9169 148.07 30.5652 148.531 31.4084C148.992 32.2437 149.223 33.3294 149.223 34.6658V46.8363H145.739V44.3306H145.596C145.365 44.792 145.019 45.2454 144.558 45.6908C144.096 46.1363 143.504 46.5062 142.78 46.8005C142.056 47.0948 141.189 47.2419 140.179 47.2419ZM140.954 44.3783C141.941 44.3783 142.784 44.1834 143.484 43.7937C144.192 43.4039 144.729 42.8948 145.095 42.2664C145.468 41.63 145.655 40.9499 145.655 40.226V37.8635C145.528 37.9908 145.282 38.1101 144.916 38.2215C144.558 38.3249 144.148 38.4164 143.687 38.4959C143.225 38.5675 142.776 38.6351 142.338 38.6988C141.901 38.7544 141.535 38.8022 141.241 38.8419C140.549 38.9294 139.916 39.0766 139.343 39.2834C138.779 39.4902 138.325 39.7885 137.983 40.1783C137.649 40.5601 137.482 41.0692 137.482 41.7056C137.482 42.5885 137.808 43.2567 138.461 43.7101C139.113 44.1556 139.944 44.3783 140.954 44.3783Z"
        fill="white"
      />
      <path
        d="M152.295 46.8363V28.509H155.719V31.4919H155.946C156.328 30.4817 156.952 29.6942 157.819 29.1294C158.686 28.5567 159.724 28.2704 160.933 28.2704C162.158 28.2704 163.185 28.5567 164.012 29.1294C164.847 29.7022 165.464 30.4897 165.861 31.4919H166.052C166.49 30.5135 167.186 29.734 168.14 29.1533C169.095 28.5647 170.232 28.2704 171.553 28.2704C173.215 28.2704 174.572 28.7914 175.622 29.8334C176.68 30.8755 177.208 32.4465 177.208 34.5465V46.8363H173.641V34.8806C173.641 33.6397 173.303 32.7408 172.627 32.184C171.951 31.6272 171.143 31.3488 170.205 31.3488C169.043 31.3488 168.14 31.7067 167.496 32.4226C166.852 33.1306 166.53 34.0414 166.53 35.155V46.8363H162.974V34.6539C162.974 33.6596 162.664 32.8601 162.043 32.2556C161.423 31.651 160.615 31.3488 159.621 31.3488C158.945 31.3488 158.32 31.5277 157.748 31.8857C157.183 32.2357 156.726 32.7249 156.376 33.3533C156.033 33.9817 155.862 34.7096 155.862 35.5368V46.8363H152.295Z"
        fill="white"
      />
      <path
        d="M188.209 47.2062C186.403 47.2062 184.848 46.8204 183.544 46.0488C182.247 45.2692 181.245 44.1755 180.537 42.7675C179.837 41.3516 179.487 39.6931 179.487 37.7919C179.487 35.9147 179.837 34.2601 180.537 32.8283C181.245 31.3965 182.231 30.2789 183.496 29.4755C184.769 28.6721 186.256 28.2704 187.958 28.2704C188.993 28.2704 189.995 28.4414 190.965 28.7834C191.936 29.1255 192.807 29.6624 193.578 30.3942C194.35 31.126 194.958 32.0766 195.404 33.2459C195.849 34.4073 196.072 35.8192 196.072 37.4817V38.7465H181.503V36.0738H192.576C192.576 35.1351 192.385 34.3039 192.003 33.58C191.622 32.8482 191.085 32.2715 190.393 31.8499C189.708 31.4283 188.905 31.2175 187.982 31.2175C186.98 31.2175 186.105 31.4641 185.357 31.9573C184.618 32.4425 184.045 33.0789 183.639 33.8664C183.241 34.6459 183.043 35.4931 183.043 36.4079V38.4959C183.043 39.7209 183.257 40.763 183.687 41.6221C184.124 42.4812 184.733 43.1374 185.512 43.5908C186.292 44.0363 187.203 44.259 188.245 44.259C188.921 44.259 189.537 44.1635 190.094 43.9726C190.651 43.7738 191.132 43.4795 191.538 43.0897C191.944 42.6999 192.254 42.2187 192.469 41.6459L195.845 42.2544C195.575 43.2488 195.09 44.1198 194.39 44.8675C193.698 45.6073 192.827 46.184 191.777 46.5976C190.735 47.0033 189.545 47.2062 188.209 47.2062Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width={props.width - 1} height={props.height - 1} stroke="#DADAE3" strokeDasharray="2 2"/>
    </>
  ),
  notFound: (props) => (
    <>
      <path
        d="M57.6323 0H7.63222C5.56448 0 3.88235 1.68225 3.88235 3.74999V41.986C3.88235 44.0538 5.56448 45.736 7.63222 45.736H13.1838L1.98161 56.2445C1.12198 57.051 0.637861 58.1578 0.618736 59.361C0.599111 60.5934 1.08536 61.7978 1.95311 62.6655C2.82011 63.533 4.0266 64.0196 5.25723 63.9996C6.46072 63.9805 7.5676 63.4965 8.3741 62.6369L22.3962 47.689C29.2104 52.3983 39.5153 51.4635 45.3674 45.7362H57.6325C59.7002 45.7362 61.3825 44.0539 61.3825 41.9862V3.74999C61.3822 1.68225 59.7 0 57.6323 0ZM6.5506 60.9263C6.21135 61.2878 5.73785 61.4914 5.21723 61.4997C4.65948 61.5086 4.11235 61.2893 3.72073 60.8976C3.32886 60.5059 3.10923 59.9603 3.11823 59.4009C3.12661 58.8805 3.33036 58.4071 3.69198 58.0679L14.7728 47.6734L16.9451 49.8456L6.5506 60.9263ZM18.6565 48.0212L16.5972 45.9619L18.4433 44.2302C19.0435 44.9248 19.6935 45.5748 20.3882 46.175L18.6565 48.0212ZM16.3823 31.9862C16.3823 23.0258 23.6719 15.7362 32.6323 15.7362C54.1605 16.5531 54.1546 47.4225 32.632 48.2363C23.6719 48.2362 16.3823 40.9464 16.3823 31.9862ZM57.6323 43.236H47.6234C56.8949 31.2058 47.9484 13.0945 32.632 13.2363C17.8907 13.0926 8.77734 30.0633 16.9296 42.2224L15.849 43.236H7.63222C6.94297 43.236 6.38235 42.6753 6.38235 41.986V9.99998H27.0068C28.6636 9.93848 28.6623 7.56086 27.0068 7.49998H6.38235V3.74999C6.38235 3.06074 6.94297 2.49999 7.63222 2.49999H57.6323C58.3215 2.49999 58.8822 3.06074 58.8822 3.74999V7.49998H38.2568C36.6 7.56148 36.6013 9.9391 38.2568 9.99998H58.8822V41.986C58.8822 42.6753 58.3214 43.236 57.6323 43.236Z"
        fill='black'
      />
      <path
        d="M32.6324 7.5H32.6314C31.9411 7.5 31.3819 8.05963 31.3819 8.75C31.4434 10.406 33.8212 10.4061 33.8824 8.75C33.8824 8.05963 33.3226 7.5 32.6324 7.5ZM41.8577 26.738L37.8804 22.7606C37.4147 22.2764 36.5781 22.2765 36.1125 22.7606L32.6321 26.241L29.1519 22.7607C28.6862 22.2765 27.8496 22.2765 27.384 22.7607L23.4066 26.7381C22.9185 27.2262 22.9185 28.0177 23.4066 28.506L26.8869 31.9862L23.4066 35.4665C22.9185 35.9546 22.9185 36.7461 23.4066 37.2344L27.384 41.2118C27.8496 41.696 28.6862 41.696 29.1519 41.2118L32.6321 37.7315L36.1125 41.2119C36.5781 41.696 37.4147 41.696 37.8804 41.2119L41.8577 37.2345C42.3459 36.7464 42.3459 35.9549 41.8577 35.4666L38.3775 31.9864L41.8577 28.5061C42.346 28.0177 42.346 27.2263 41.8577 26.738ZM35.7259 31.1022C35.2377 31.5904 35.2377 32.3819 35.7259 32.8701L39.2061 36.3504L36.9965 38.5601L33.5161 35.0798C33.0506 34.5956 32.2137 34.5956 31.7482 35.0798L28.268 38.56L26.0584 36.3503L29.5386 32.87C30.0267 32.3819 30.0267 31.5904 29.5386 31.1021L26.0584 27.6219L28.268 25.4121L31.7482 28.8924C32.2137 29.3766 33.0505 29.3766 33.5161 28.8924L36.9965 25.412L39.2061 27.6217L35.7259 31.1022Z"
        fill={props.fill}
      />
    </>
  ),
  editIcon: (props) => (
    <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z" fill={props.fill}/>
  ),
  checked: (props) => (
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill={props.fill}/>
  ),
  unchecked: (props) => (
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill={props.fill}/>
  )
});
