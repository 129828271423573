import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {getCarYears} from 'api/IXWidgetApi';
import VehicleSelect from './VehicleSelect';

const VehicleYearControl = ({data, label, required, id, handleChange, path, errors}) => {
	const [yearsData, setYearsData] = useState([]);
	useEffect(() => {
		const getYearsData = async () => {
			try {
				const response = await getCarYears();
				setYearsData(response.data && response.data.length > 0 ? response.data : [parseInt(data)]);
			} catch (e) {
				setYearsData([parseInt(data)]);
				handleChange(path, parseInt(data));
			}
		};
		getYearsData().then(r => r);
	}, []);

	const changeHandler = (event) => {
		handleChange(path, parseInt(event.target.value));
	};
	const formattedData = useMemo(() => {
		return yearsData.map((item) => ({name: item, id: item}));
	}, [yearsData]);


	useEffect(() => {
		!data && handleChange(path, parseInt(yearsData[0]));
	}, [yearsData]);

	return (
		<div>
			<VehicleSelect label={label} changeHandler={changeHandler} id={id} required={required} data={formattedData} value={data} errors={errors} path={path}/>
		</div>
	);
};
VehicleYearControl.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withJsonFormsControlProps(VehicleYearControl);
