import React from 'react';
import PropTypes from 'prop-types';
import { APP_ICONS } from './icon.data';
import { StyledSvg } from './styles';

const Icon = ({
  width = 20,
  height = 20,
  name,
  fill = 'none',
  viewBoxWidth,
  viewBoxHeight,
  ...props
}) => {
  return (
    <StyledSvg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxWidth || width} ${viewBoxHeight || height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {APP_ICONS[name]({fill, width: viewBoxWidth || width, height: viewBoxHeight || height})}
    </StyledSvg>
  );
};

Icon.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  viewBoxWidth: PropTypes.any,
  viewBoxHeight: PropTypes.any,
};
export default Icon;
