import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';
import check from 'assets/check.svg';

export const RadioOption = styled.div`
  flex: 1;
  label {
    display: flex;
    align-items: center;
    padding-left: ${(props) => props.buttonType ? '' : '40px'};
    ${(props) => props.buttonType ? 'justify-content: center;' : ''};
  }
`;
export const Label = styled.label`
  position: relative;
	user-select: none;
	font-size: ${(props) => props.style.components.radioGroup.fontSize}!important;
	color: ${(props) => props.checked ? props.style.components.radioGroup.checkedStyles.color : props.style.components.radioGroup.color}!important;
  border-radius: ${(props) => props.style.components.radioGroup.borderRadius}!important;
  background: ${(props) => props.checked && props.buttonType ? 
		props.style.components.radioGroup.checkedStyles.background : 
		props.style.components.radioGroup.background}!important;
  border: ${(props) => props.checked && props.buttonType ? props.style.components.radioGroup.checkedStyles.border : props.style.components.radioGroup.border}!important;
  min-height: ${(props) => props.style?.components.input.minHeight || '42px'};
  line-height: ${(props) => props.style?.components.input.minHeight || '42px'};
  padding: ${(props) => props.style?.components.input.padding};

  @media screen and (min-width: 1921px) {
    margin-bottom: ${px2vw(14)};
    font-size: ${(props) => px2vw(props.style.fontSize)};
  }

  input[type='radio'] {
    display: none;
  }

  &:has(input:checked) {
    &:before {
       background: ${(props) => props.style.components.radioGroup.checkedStyles.background};
    }

    &:after {
      content: '';
    }
  }

  cursor: pointer;
  position: relative;
  font-weight: 400;
  margin-bottom: 0!important;
  font-family: ${(props) => props.style?.components.radioGroup.fontFamily};

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(15)};
    line-height: ${px2vw(20)};
    padding-left: ${(props) => props.buttonType ? '' : px2vw(30)};
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 14px;
    border: ${(props) => `2px solid ${props.checked ? props.style?.components.radioGroup.checkedStyles.markBorder : props.style?.components.radioGroup.markBorder}`};
    height: 16px;
    width: 16px;
    border-radius: 16px;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: ${(props) => {
        return props.buttonType ? 'none' : 'inherit';
    }};
    @media screen and (min-width: 1921px) {
      border: ${px2vw(2)} solid #CDD8DE;
      height: ${px2vw(20)};
      width: ${px2vw(20)};
      border-radius: ${px2vw(20)};
    }
  }

  &:after {
    position: absolute;
    left: 14px;
    top: 50%;
    height: 17px;
    width: 16px;
    background: ${(props) => props.buttonType ? 'none' : `url(${check})`};
    transform: translateY(-50%);
    background-size: contain;

    @media screen and (min-width: 1921px) {
      left: ${px2vw(6)};
      top: ${px2vw(6)};
      width: ${px2vw(12)};
      height: ${px2vw(12)};
    }
  }
`;
