import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Label = styled.label`
  font-family: ${(props) => props.style?.components.headings.fontFamily};
  cursor: pointer;

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw(15)};
    line-height: ${px2vw(20)};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${(props) => px2vw(props.style?.components.label.fontSize || '15px')};
    line-height: ${px2vw(24)};
    margin-bottom: ${px2vw(5)};
  }

  input[type='checkbox'] {
    display: none;
  }
`;

export const Wrapper = styled.div`
  .horizontal-layout {
    margin: 0;
    width: auto;
  }
  .horizontal-layout-item{
    width: auto;
    margin-right: 12px;
  }
`;