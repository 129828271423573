import React, {useState, useContext} from 'react';

import {withPixelTracking} from '../../HOC/withPixelTracking';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import SchemaRenderer from '../../SchemaRenderers';
import SuccessPage from 'pages/SuccessPage';
import { StageContext } from '..';
import { schema } from './config';
import {sendContactInformation} from '../requests';

const ContactInformation = () => {
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);

  const { clientData, setLoading, setError } = useContext(StageContext);

  const onSubmit = async () => {
    setLoading(true);

    const id = await sendContactInformation(
      {
        'firstName': data.firstName,
        'lastName': data.lastName,
        'email': data.email,
        'itemId': clientData.productId,
        'itemType': 'Product',
        'programId': clientData?.programId || null,
        'widgetId': clientData?.widgetId || null
      },
      setError,
      clientData.clientId
    );
    if(id) {
      setLoading(false);
      setSuccess(true);
    }
  };

  const uiSchema = {
    'type': 'VerticalLayout',
    'elements': [
      {
        'type': 'HorizontalLayout',
        'elements': [
          {
            'type': 'Control',
            'scope': '#/properties/firstName',
            'labelPosition': clientData.labelPlacement ?? 'top',
            'options':{
              'placeholder':'First Name'
            },
          },
          {
            'type': 'Control',
            'scope': '#/properties/lastName',
            'labelPosition': clientData.labelPlacement ?? 'top',
            'options':{
              'placeholder':'Last Name'
            },
          },
        ]
      },
      {
        'type': 'HorizontalLayout',
        'elements': [
          {
            'type': 'Control',
            'scope': '#/properties/email',
            'labelPosition': clientData.labelPlacement ?? 'top',
            'options':{
              'placeholder':'Email'
            },
          }
        ]
      }
    ]
  };

	return success ? (<SuccessPage />) : (
    <SchemaRenderer
      stepNumber={1}
      toNextStep={onSubmit}
      schema={schema}
      uiSchema={clientData.uiSchema || uiSchema}
      data={data}
      setData={setData}
      template={DefaultTemplate}
    />
);
};

export default React.memo(withPixelTracking(ContactInformation));
