import React, {useContext} from 'react';
import {Interweave} from 'interweave';
import PropTypes from 'prop-types';

import {Block, Container} from 'pages/Main/styles';
import Coverage from './Coverage';
import BannerImage from '../BannerImage';
import Header from '../Header/StepperHeader';

import {
  Button,
  Col,
  FormWrapper,
  MarginTopBox,
  Paragraph,
  Row,
  Title,
  Description,
  Footer,
  BackButton
} from 'shared/ui';

import {StyleContext} from 'pages/Main';
import {StageContext} from '../Stages';

function UserQualified({
  responseAttributes,
  link,
  message,
  stepName,
  toPrevStep,
  stepNumber,
}) {
  const { clientWidth } = useContext(StyleContext);
  const {
    commonStyles,
    currentStageNumber,
    clientData: { fullSize, smallSize, isMicroSite, title, button, heroImage, UserQualified }
  } = useContext(StageContext);

  const titlePositionClass = title?.position === 'right' ? 'flex-end' : 'flex-start';
  const justifyContent = title?.position === 'center' ? 'center' : titlePositionClass;

  const handleClick = () => window.open(link, '_blank');

  const renderSubmission = () => {
    return (
      <>
        {message && <MarginTopBox className={'ix-results-message-div'}>
          <Paragraph
            variant={isMicroSite && clientWidth >= 470 ? 'p1' : 'p3'}
            className={isMicroSite && clientWidth >= 470 ? 'p1' : 'p3'}
            textAlign='center'
            bold={isMicroSite}
            color={commonStyles.components.paragraphs.color || '#2b2c41'}
          >
            <Interweave
              content={message}
            />
          </Paragraph>
        </MarginTopBox> }
        <Description step={stepName} position='bottom'/>
        {link && <MarginTopBox>
          <Row justifyContent={['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'center'}>
            <Button
              variant='contained'
              color='secondary'
              className='secondary'
              onClick={handleClick}
              text={UserQualified?.button?.content || button?.content || 'Purchase your policy'}
              flex={button?.placement === 'fullwidth'}
              borderRadius={button?.borderRadius}
            />
          </Row>
        </MarginTopBox>}
      </>
    );
  };
  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';

  return (
    <Container
      style={commonStyles}
      position={heroImage?.position || 'top'}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block>
        <FormWrapper commonStyles={commonStyles}>
          <div className='ix-content' style={{display: 'flex', flexDirection: 'column'}}>
            <Col>
              <Header stepNumber={stepNumber} handleBack={toPrevStep}/>
              {(!title || title?.visible || title?.visible === undefined) &&
                <MarginTopBox className={'ix-title-div'}>
                  <Row justifyContent={justifyContent}>
                    <Title
                      variant={isMicroSite && clientWidth >= 470 ? 'h3' : 'h5'}
                      className={'ix-title'}
                      textAlign={'left'}
                      color={commonStyles.components.headings.color || '#010101'}
                    >
                      <BackButton handleBack={toPrevStep} backButton={title?.backButton} visible={currentStageNumber !== 1 && title?.backButton?.visible} />
                      <Interweave content={UserQualified?.title?.content || title?.content || 'Here is your quote'} />
                    </Title>
                  </Row>
                </MarginTopBox>
              }
              <Description step={stepName} position='top'/>
              <MarginTopBox>
                <Coverage attributesAndValues={responseAttributes} />
              </MarginTopBox>
              {renderSubmission()}
            </Col>
          </div>
        </FormWrapper>
        <Footer />
      </Block>
    </Container>
  );
}
UserQualified.propTypes = {
  responseAttributes: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  toPrevStep: PropTypes.func.isRequired,
};

export default UserQualified;
