import React from 'react';
import PropTypes from 'prop-types';

import {IconButton} from '@mui/material';
import {KeyboardBackspace} from '@mui/icons-material';

function BackButton({
  handleBack,
  backButton,
  visible,
	hidden
}) {
	if(hidden) return null;
	if(visible) return (
    <IconButton onClick={handleBack}>
      {backButton?.src ? 
        <img src={backButton?.src} style={{width: backButton?.width, height: backButton?.height}} alt="back button icon" /> : 
        <KeyboardBackspace style={{ color: '#010101' }} />
      }
    </IconButton>
  );
}
BackButton.propTypes = {
  handleBack: PropTypes.func.isRequired,
  backButton: PropTypes.any,
  visible: PropTypes.bool,
  hidden: PropTypes.bool,
};
export default BackButton;
