import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {StepCount, StepHeader, LogoWrapper} from './styles';

import Logo from '../../Logo';
import {StyleContext} from 'pages/Main';
import {StageContext} from '../../Stages';
import {BackButton} from 'shared/ui';

function Header({
  stepNumber,
  handleBack,
}) {
  const {
    commonStyles,
    currentStageNumber,
    clientData: { logo, title, FirstStage, ConfirmationStage, heroImage, progressBar = {}, steps }
  } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const [stepsAmount, setStepsAmount] = useState(1);
  const [step, setStep] = useState(stepNumber);

	const {visible = true, position, backButton} = logo ?? {};
  const stepsVisible = steps?.visible;

  const justifyContent = !progressBar?.visible ? 'space-between' : 'flex-start';
  const logoPosition = position === 'left' ? 'flex-left' : 'center';
  const logoJustifyContent = position === 'right' ? 'flex-end' : logoPosition;

  useEffect(() => {
    const FirstStageEntries = FirstStage && Object.entries(FirstStage.stepCategories);
    const ConfirmationStageEntries = ConfirmationStage && Object.entries(ConfirmationStage.stepCategories);
    setStepsAmount(FirstStageEntries?.length + ConfirmationStageEntries?.length);
  }, []);

  useEffect(() => {
    if (currentStageNumber === 2) {
      const FirstStageEntries = FirstStage && Object.entries(FirstStage.stepCategories);
      setStep(FirstStageEntries?.length + stepNumber - 1);
    } else {
      setStep(stepNumber);
    }
  }, [currentStageNumber, stepNumber]);

  const renderLogo = () => visible ? (
    <LogoWrapper justifyContent={logoJustifyContent}>
      <Logo
        logo={logo}
        heroImagePosition={heroImage?.position}
        width={clientWidth < 470 ? '120px' : undefined}
        height={clientWidth < 470 ? '40px' : undefined}
      />
    </LogoWrapper>
  ) : null;

  return step !== 1 && visible ? (
    <StepHeader clientWidth={clientWidth} className='header' justifyContent={justifyContent} >
        <div>
          <BackButton handleBack={handleBack} backButton={backButton} visible={!title?.backButton?.visible} hidden={!title?.backButton}/>
        </div>
        <LogoWrapper justifyContent={logoJustifyContent}>
          <Logo
            logo={logo}
            heroImagePosition={heroImage?.position}
            width={clientWidth < 470 ? '120px' : undefined}
            height={clientWidth < 470 ? '40px' : undefined}
          />
        </LogoWrapper>
        <div>
          {!progressBar?.visible || stepsVisible !== false && <StepCount clientWidth={clientWidth} style={commonStyles} >
            STEP {step}/{stepsAmount}
          </StepCount>}
        </div>
      </StepHeader>
  ) : renderLogo();
}
Header.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
};
export default Header;
