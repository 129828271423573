import styled from 'styled-components';
import {px2vw} from 'utils/px2vw';

export const Col = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  min-width: ${props => props.width};
  gap: ${props => props.gap};
  width: 100%;
  height: 100%;

  @media screen and (max-width: 470px) {
    min-width: ${props => props.width === 'auto' ? 'auto' : px2vw(props.width)};
    flex-direction: column;
  } 
  @media screen and (min-width: 1921px) {
  	min-width: ${props => px2vw(props.width)};
	}
`;
