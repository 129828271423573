import React, {useContext} from 'react';
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import StepTemplateMaterial from './Material';
import StepTemplateCustom from './Custom';
import {StageContext} from '../Stages';

import BooleanControl from '../CustomControls/BooleanControl';
import BooleanToggleControl from '../CustomControls/BooleanToggleControl';
import ArrayControl from '../CustomControls/ArrayControl';
import RadioGroupControl from '../CustomControls/RadioControl';
import AutocompleteControl from '../CustomControls/AutocompleteControl';
import CoverageArrayControl from '../CustomControls/CoverageArrayControl';
import EmailControl from '../CustomControls/EmailControl';
import VehicleTrimControl from '../CustomControls/VehicleControls/VehicleTrimControl';
import VehicleYearControl from '../CustomControls/VehicleControls/VehicleYearControl';
import VehicleModelControl from '../CustomControls/VehicleControls/VehicleModelControl';
import VehicleMakeControl from '../CustomControls/VehicleControls/VehicleMakeControl';
import VehicleFuelControl from '../CustomControls/VehicleControls/VehicleFuelTypeControl';
import RadioIconsControl from '../CustomControls/RadioIconsControl';
import BooleanIconControl from '../CustomControls/BooleanIconControl';
import ArrayOfStringsControl from '../CustomControls/ArrayOfStringsControl';
import BooleanGroup, {BooleanGroupTester} from '../CustomControls/BooleanGroup';

export const controls = {
  BooleanControl: BooleanControl,
  BooleanToggleControl: BooleanToggleControl,
  ArrayControl: ArrayControl,
  RadioGroupControl: RadioGroupControl,
  AutocompleteControl: AutocompleteControl,
  CoverageArrayControl: CoverageArrayControl,
  EmailControl: EmailControl,
  VehicleTrimControl: VehicleTrimControl,
  VehicleYearControl: VehicleYearControl,
  VehicleModelControl: VehicleModelControl,
  VehicleMakeControl: VehicleMakeControl,
  VehicleFuelControl: VehicleFuelControl,
  RadioIconsControl: RadioIconsControl,
  BooleanIconControl: BooleanIconControl,
  ArrayOfStringsControl: ArrayOfStringsControl,
};
function SchemaRenderer(props) {
  const { clientData: { theme, customControls } } = useContext(StageContext);

  let renderers = customControls?.map(item => {
    const tester = rankWith(
      4,
      scopeEndsWith(item.attributeName)
    );
    let renderer = controls[item.renderer];

    return {tester, renderer};
  });

  renderers = renderers && [
    ...renderers, 
    {tester: BooleanGroupTester, renderer: BooleanGroup},
  ];

  return theme === 'material' ? (
    <StepTemplateMaterial {...props} renderers={renderers || []} />
  ) : (
    <StepTemplateCustom {...props} renderers={renderers || []} />
  );
}

export default SchemaRenderer;
