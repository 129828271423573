import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ResultPage from '../../Results';
import {sendApplicationForms} from './requests';
import {
	fetchDynamicSchema,
	fetchBackApplicationProcess
} from '../requests';
import {  checkApplicationProcess } from '../utils';
import getUserData from 'utils/getUserData';
import {generateUISchema} from 'utils/UIschemaGenerator';

import {StageContext} from '..';
import {withPixelTracking} from '../../HOC/withPixelTracking';

const ResultStage = ({workflowResults, applicationFormId, setSecondSchema}) => {
	const {
		setError,
		setLoading,
		stagesDataRef,
		toPrevStage,
		setStepName,
		clientData: {userData, labelPlacement, layout, ConfirmationStage, clientId} = {}
	} = useContext(StageContext);

	const [data, setData] = useState(stagesDataRef.current[3]);

	useEffect(() => {
		if (!data) {
			const data = getUserData(userData, schema);
			setData(data);
		}
	}, []);

	const onSubmit = () => {
		setLoading(true);
		sendApplicationForms(applicationFormId, data, setError);
		stagesDataRef.current[3] = data;
		setLoading(false);
	};

	const goBack = async () => {
		setLoading(true);

		const { id } = await fetchBackApplicationProcess(applicationFormId, () => {}, clientId);

		if (id) {
			setTimeout(() => {
				checkApplicationProcess({
					id,
					setError,
					onWaitingForUserInput: () => onWaitingForUserInput(id),
					clientId,
				});
			}, 400);
		}
	};

	const onWaitingForUserInput = async (id) => {
		const dynamicSchema = await fetchDynamicSchema(id, setError, clientId);
		setSecondSchema(dynamicSchema);
		stagesDataRef.current[1] = data;
		setLoading(false);
		toPrevStage();

		const stepCategoriesKeys = ConfirmationStage && Object.keys(ConfirmationStage?.stepCategories);
		setStepName(stepCategoriesKeys?.[stepCategoriesKeys.length-1]);
	};

	const schema = {
		stepName: 'vehicle',
		type: 'object',
		properties: {
			Email: {
				title: 'Email',
				description: '',
				type: 'string',
				default: '',
				format: 'email',
			},
		},
		required: ['Email'],
	};
	const uiSchema = generateUISchema(schema, labelPlacement, layout);

	return (
		<ResultPage
			workflowResults={workflowResults}
			schema={schema}
			uiSchema={uiSchema}
			data={data}
			setData={setData}
			onSubmit={onSubmit}
			goBack={goBack}
		/>
	);
};
ResultStage.propTypes = {
  workflowResults: PropTypes.any.isRequired,
  applicationFormId: PropTypes.string.isRequired,
  setSecondSchema: PropTypes.func.isRequired,
};

export default React.memo(withPixelTracking(ResultStage));
