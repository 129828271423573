import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {getCarModels} from 'api/IXWidgetApi';
import FormContext from '../../FormContext/FormContext';
import VehicleSelect from './VehicleSelect';
import useDefaultValue from './useDefaultValue';

const VehicleModelControl = ({data, label, required, id, handleChange, path, errors }) => {
	const { data: { Year: modelYear, Make: modelMakes } } = useContext(FormContext);
	const [modelsData, setModelsData] = useState([{name: '', id: 'None'}]);

	useEffect(() => {
		const getModelsData = async () => {
			try {
				const response = await getCarModels({modelYear: modelYear, modelMake: modelMakes.toLowerCase()});
				setModelsData(response.data && response.data.data.length > 0 ? [{name: '', id: 'None'}, ...response.data.data] : [{name: data}]);
			} catch (e) {
				setModelsData([{name: data, id: data}]);
				handleChange(path, data || undefined);
			}
		};
		if(modelYear && modelMakes) {
			getModelsData().then(r => r);
		}
	}, [modelYear, modelMakes]);
	useEffect(() => {
		if(!modelMakes) {
			setModelsData([{name: '', id: 'None'}]);
		}
	}, [modelMakes]);

	const formattedData = useMemo(() => {
		return modelsData.map(item => ({name: item.name, id: item.id}));
	}, [modelsData]);

	useDefaultValue(data, formattedData, handleChange, path);

	const changeHandler = (event) => {
		handleChange(path, event.target.value || undefined);
	};

	return (
		<div>
			<VehicleSelect label={label} changeHandler={changeHandler} id={id} required={required} data={formattedData} value={data} errors={errors} path={path}/>
		</div>
	);
};
VehicleModelControl.propTypes = {
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withJsonFormsControlProps(VehicleModelControl);
