import React, {useContext} from 'react';
import ThemedCol from './ThemedCol';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const Col = (props) => {
  const { commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth  } = useContext(StyleContext);

  const themeStyles = theme === 'material'
    ? materialTheme(commonStyles, clientWidth, { labelPlacement })
    : customTheme(commonStyles)[theme];

  return <ThemedCol themeStyles={themeStyles} {...props} />;
};

export default Col;
