import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const VehiclesContext = React.createContext();

export const VehiclesProvider = ({ children }) => {
	const [vehicles, setVehicles] = useState({});

  const vehiclesValue = useMemo(() => ({ vehicles, setVehicles }), [vehicles, setVehicles]);
	return (
		<VehiclesContext.Provider value={vehiclesValue}>
			{children}
		</VehiclesContext.Provider>
	);
};
VehiclesProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
