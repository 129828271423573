import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;

    background: ${props => props.style?.components?.progressBar?.bar?.background};
    height: ${props => props.style?.components?.progressBar?.bar?.height};
    border-radius: ${props => props.style?.components?.progressBar?.bar?.borderRadius};
    opacity: ${props => props.style?.components?.progressBar?.bar?.opacity};
  }
  &:after {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    
    background: ${props => props.style?.components?.progressBar?.activeBar?.background};
    height: ${props => props.style?.components?.progressBar?.bar?.height};
    border-radius: ${props => props.style?.components?.progressBar?.bar?.borderRadius};
    opacity: ${props => props.style?.components?.progressBar?.activeBar?.opacity};

    width: ${(props) => props.length && props.progress && `${100/props.length * props.progress}%`};
  }
`;

export const ListItem = styled.li`
  color: ${props => props.style?.components?.progressBar?.text?.color};
  font-size: ${props => props.style?.components?.progressBar?.text?.fontSize};
  font-family: ${props => props.style?.components?.progressBar?.text?.fontFamily};
  font-weight: ${props => props.style?.components?.progressBar?.text?.fontWeight};
  line-height: ${props => props.style?.components?.progressBar?.text?.lineHeight};
  font-style: normal;
  padding-bottom: 20px;
  width: ${(props) => props.length && `${100/props.length}%`};
  text-align: center;

  &.active {
    color: ${props => props.style?.components?.progressBar?.activeText?.color};
    font-size: ${props => props.style?.components?.progressBar?.activeText?.fontSize};
    font-weight: ${props => props.style?.components?.progressBar?.activeText?.fontWeight};
    line-height: ${props => props.style?.components?.progressBar?.activeText?.lineHeight};
    font-family: ${props => props.style?.components?.progressBar?.activeText?.fontFamily};
  }
`;
