import React from 'react';
import {createTheme} from '@mui/material/styles';
import {CheckBoxOutlined} from '@mui/icons-material';
import {px2vw} from 'utils/px2vw';
import {alpha} from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
});

const materialTheme = (commonStyles, clientWidth, props) => {
  return createTheme({
    palette: {
      primary: {
        main: commonStyles?.colors?.primary,
      },
      secondary: {
        main: commonStyles?.colors?.secondary,
      },
      warning: {
        main: commonStyles?.colors?.accent,
      }
    },
    typography: {
      h1: {
        fontSize: '52px',
        lineHeight: '64px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(52),
          lineHeight: px2vw(64),
        },
        color: commonStyles?.components?.headings?.color || commonStyles?.components?.styles?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      h2: {
        fontSize: '40px',
        lineHeight: '48px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(40),
          lineHeight: px2vw(48),
        },
        color: commonStyles?.components?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      h3: {
        fontSize: '32px',
        lineHeight: '48px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(32),
          lineHeight: px2vw(48),
        },
        color: commonStyles?.components?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      h4: {
        fontSize: '24px',
        lineHeight: '32px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(24),
          lineHeight: px2vw(32),
        },
        color: commonStyles?.components?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      h5: {
        fontSize:  commonStyles?.components?.headings?.fontSize || '20px',
        lineHeight: commonStyles?.components?.headings?.lineHeight || '24px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw( commonStyles?.components?.headings?.fontSize || 20),
          lineHeight: px2vw(commonStyles?.components?.headings?.lineHeight || 24),
        },
        color: commonStyles?.components?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      h6: {
        fontSize: '18px',
        lineHeight: '24px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(18),
          lineHeight: px2vw(24),
        },
        color: commonStyles?.components?.headings?.color,
        fontWeight: 700,
        fontFamily: `${commonStyles?.components?.headings?.fontFamily || 'Open Sans'}, sans-serif`,
      },
      caption: {
        color: commonStyles?.components?.paragraphs?.color,
      },
      fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,

      p1: {
        color: commonStyles?.components?.paragraphs?.color,
        fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,
        fontSize: '20px',
        lineHeight: '32px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(20),
          lineHeight: px2vw(32),
        },
      },
      p2: {
        color: commonStyles?.components?.paragraphs?.color,
        fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,
        fontSize: '16px',
        lineHeight: '24px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(16),
          lineHeight: px2vw(24),
        },
      },
      p3: {
        color: commonStyles?.components?.paragraphs?.color,
        fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,
        fontSize: commonStyles?.components?.paragraphs?.fontSize || '15px',
        lineHeight: commonStyles?.components?.paragraphs?.lineHeight || '24px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(commonStyles?.components?.paragraphs?.fontSize || 15),
          lineHeight: px2vw(commonStyles?.components?.paragraphs?.lineHeight || 24),
        },
      },
      p4: {
        color: commonStyles?.components?.paragraphs?.color,
        fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,
        fontSize: '14px',
        lineHeight: '20px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(14),
          lineHeight: px2vw(20),
        },
      },
      p5: {
        color: commonStyles?.components?.paragraphs?.color,
        fontFamily: `${commonStyles?.components?.paragraphs?.fontFamily || 'Open Sans'}, sans-serif`,
        fontSize: '12px',
        lineHeight: '16px',
        [theme.breakpoints.up('xxl')]: {
          fontSize: px2vw(12),
          lineHeight: px2vw(16),
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            'color': commonStyles?.components?.button?.color || '#FFFFFF',
            textTransform: 'uppercase',
            'margin': '10px auto 0',
            'display': 'block',
            'border-radius': commonStyles?.components?.button?.borderRadius || '4px',
            'font-size': commonStyles?.components?.button?.fontSize || '14px',
            'padding': commonStyles?.components?.button?.padding || '12px 20px',
            [theme.breakpoints.up('xxl')]: {
              'font-size': px2vw(commonStyles?.components?.button?.fontSize || '14'),
              'border-radius': px2vw(commonStyles?.components?.button?.borderRadius || '4px'),
              'padding': px2vw(commonStyles?.components?.button?.padding || '12px 20px'),
            },
            'svg': {
              width: commonStyles?.components?.button?.fontSize || '20px',
              height: commonStyles?.components?.button?.fontSize || '20px',
              'margin-left': px2vw(10),
              [theme.breakpoints.up('xxl')]: {
                'width': px2vw(commonStyles?.components?.button?.fontSize || '20'),
                'height': px2vw(commonStyles?.components?.button?.fontSize || '20'),
                'margin-left': px2vw(10),
              },
              '& path': {
                fill: commonStyles?.components?.button?.color || '#FFFFFF',
              }
            }
          },
          outlined: {
            'color': commonStyles?.components?.button?.background || commonStyles?.colors?.secondary || '#6266EA',
            'border-color': commonStyles?.components?.button?.background || commonStyles?.colors?.secondary || '#6266EA',
            '&:hover': {
              'background':commonStyles?.components?.button?.background ||  commonStyles?.colors?.secondary || '#6266EA',
              'border-color': commonStyles?.components?.button?.background || commonStyles?.colors?.secondary || '#6266EA',
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              'color': commonStyles?.colors?.primary,
              'border-color': commonStyles?.colors?.primary,
              'opacity': '0.35'
            }
          },
          text: {
            'color': commonStyles?.components?.button?.background || commonStyles?.colors?.secondary || '#6266EA',
            '&:hover': {
              'background': alpha(commonStyles?.components?.button?.background ||  commonStyles?.colors?.secondary || '#6266EA', 0.1),
              'color':commonStyles?.components?.button?.background ||  commonStyles?.colors?.secondary || '#6266EA',
            },
          },
          contained: {
            'background': commonStyles?.components?.button?.background,
            'padding': commonStyles?.components?.button?.padding,
            'height': commonStyles?.components?.button?.padding && 'auto',
            '&:hover': {
              'background': commonStyles?.components?.button?.background,
            },
            '&.Mui-disabled': {
              backgroundColor: commonStyles?.colors?.primary,
              'color': '#ffffff',
              'opacity': '0.35'
            }
          }
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: '8px',
            width: '100%'
          }
        },
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            paddingTop: '8px !important'
          },
          container: {
            display: clientWidth < 550 && 'block',
            '@media screen and (max-width: 550px)': {
              display: 'block',
            },
          },
        },
      },
      MuiTextareaAutosize: {
        minRows: 2
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: `${commonStyles?.components?.label?.color} !important`,
            fontSize: commonStyles?.components?.label?.fontSize || '14px',
            fontFamily: `${commonStyles?.components?.label?.fontFamily || 'Open Sans'}, sans-serif`,
            padding: commonStyles?.components?.input?.padding,
          },
          shrink: {
            padding: 0
          }
        },
        defaultProps: props?.labelPlacement === 'top' && {
          shrink: true
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: commonStyles?.components?.headings?.color,
            'font-size': '14px',
          },
          label: {
            color: commonStyles?.components?.headings?.color,
            'font-size': '14px',
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          checkedIcon: <CheckBoxOutlined />,
          color: 'secondary'
        }
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary'
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            width: '100%',
            color: commonStyles?.components?.input?.color,
            background: commonStyles?.components?.input?.background,
            borderBottom: `1px solid ${commonStyles?.components?.input?.color}`,
            fontSize: commonStyles?.components?.input?.fontSize,
            borderRadius: commonStyles?.components?.input?.borderRadius,
            minHeight: commonStyles?.components?.input?.minHeight || '42px',
            '&::after': {
              display: commonStyles?.components?.input?.underline || '',
            },
            '&::before': {
              display: commonStyles?.components?.input?.underline || '',
            },
          },
          input: {
            padding: commonStyles?.components?.input?.padding,
            fontSize: commonStyles?.components?.input?.fontSize,
            lineHeight: commonStyles?.components?.input?.lineHeight,
            minHeight: commonStyles?.components?.input?.minHeight || '42px',
            [theme.breakpoints.up('xxl')]: {
              padding: commonStyles?.components?.input?.padding || `${px2vw(4)} 0 ${px2vw(5)}`,
              fontSize: commonStyles?.components?.input?.fontSize || px2vw(15),
              'line-height': commonStyles?.components?.input?.lineHeight || px2vw(21),
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: commonStyles?.components?.input?.color,
            background: commonStyles?.components?.input?.background,
            borderBottom: `1px solid ${commonStyles?.components?.input?.color}`,
            fontSize: commonStyles?.components?.input?.fontSize,
            borderRadius: commonStyles?.components?.input?.borderRadius,
            minHeight: commonStyles?.components?.input?.minHeight || '42px',
            '&::after': {
              display: commonStyles?.components?.input?.underline || '',
            },
            '&::before': {
              display: commonStyles?.components?.input?.underline || '',
            },
          },
          input: {
            padding: commonStyles?.components?.input?.padding,
            fontSize: commonStyles?.components?.input?.fontSize,
            lineHeight: commonStyles?.components?.input?.lineHeight,
            minHeight: commonStyles?.components?.input?.minHeight || '42px',
            [theme.breakpoints.up('xxl')]: {
              padding: commonStyles?.components?.input?.padding || `${px2vw(4)} 0 ${px2vw(5)}`,
              fontSize: commonStyles?.components?.input?.fontSize || px2vw(15),
              'line-height': commonStyles?.components?.input?.lineHeight || px2vw(21),
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiInput-root .MuiInput-input': {
              padding: commonStyles?.components?.input?.padding,
            }
          },
          listbox: {
            '.MuiAutocomplete-option': {
              color: '#2B2C41'
            }
          }
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: commonStyles?.components?.paragraphs?.color,
            '&[aria-label="Clear input field"]': {
              display: 'none',
            },
            left: '-12px',
            position: 'relative',
          }
        }
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: 'rgba(0, 0, 0, 0.54)',
          }
        }
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            '.PrivatePickersYear-yearButton': {
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '.Mui-selected': {
              color: commonStyles?.components?.paragraphs?.color,
            }
          }
        }
      }
    },
  });
};
export default materialTheme;
