const tokenParams = {
    _client_id: null,
    _grant_type: 'public',
    _scope: 'product_schemas:get application_forms:create application_forms:update application_forms:get system_glossary:get workflow_result:get',

    get clientId() {
        return this._client_id;
    },

    set clientId(id) {
        this._client_id = id;
    },

    get params () {
        return {
            client_id: this._client_id,
            grant_type: this._grant_type,
            scope: this._scope,
        };
    },
};

export { tokenParams };
