import React from 'react';
import PropTypes from 'prop-types';
import {Container, ModalBox} from './styles';
import {IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';

function CustomModal(
  {
    open,
    children,
    handleClose,
  }) {
  return (
    <>
      {open && <ModalBox onClick={handleClose}>
        <Container onClick={e => e.stopPropagation()}>
          <IconButton onClick={handleClose} className="ix-close-btn" >
            <Close />
          </IconButton>
          {children}
        </Container>
      </ModalBox>
      }
    </>
  );
}
CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default CustomModal;
