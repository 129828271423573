import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 5px;

  width: 100%;

  @media screen and (min-width: 1921px) {
    padding: ${px2vw('10px 20px')};
    gap: ${px2vw(5)};
  }
`;
