import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  computeLabel,
  isDescriptionHidden,
} from '@jsonforms/core';
import {withJsonFormsEnumProps} from '@jsonforms/react';
import {withVanillaControlProps} from '@jsonforms/vanilla-renderers';
import merge from 'lodash/merge';
import {RadioOption, Label, Wrapper} from './styles';

import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

export const RadioIconsControl = ({
  classNames,
  id,
  label,
  options,
  required,
  description,
  errors,
  data,
  uischema,
  visible,
  config,
  enabled,
  path,
  handleChange,
}) => {
  const {commonStyles, clientData, widgetId} = useContext(StageContext);
  const valuesProps = clientData?.RadioIconsControl?.valuesProps;

  const [isFocused, setIsFocused] = useState(false);
  const radioControl = 'radio';
  const isValid = errors.length === 0;
  const divClassNames = [classNames.validation]
    .concat(isValid ? classNames.description : classNames.validationError)
    .join(' ');
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const showDescription = !isDescriptionHidden(
    visible,
    description,
    isFocused,
    appliedUiSchemaOptions.showUnfocusedDescription
  );
  const onChange = (ev) => {
    handleChange(path, ev.currentTarget.value);
  };

  const error_description = showDescription ? description : null;
  
  const attribute_description = getAttributeDescription(path, clientData.controlsWithDescription);

  return <div
    className={classNames.wrapper}
    hidden={!visible}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
  >
    <label htmlFor={id}>
      {computeLabel(
        label,
        required,
        appliedUiSchemaOptions.hideRequiredAsterisk
      )}
    </label>
    {attribute_description && <p className='description'>{description}</p>}
    <Wrapper className={radioControl}>
      {options?.map((option) => {
        if (option.value) {
          const id = `${widgetId}_${path}_${option.value}`;
          return (
            <RadioOption key={option.label} style={commonStyles}>
              <Label htmlFor={id} style={commonStyles}>
                {valuesProps && <img
                  width={valuesProps[option.value]?.width}
                  height={valuesProps[option.value]?.height}
                  src={data === option.value ? valuesProps[option.value]?.activeIcon : valuesProps[option.value]?.icon}
                  alt="radio icon" />
                }
                <input
                  type='radio'
                  value={option.value}
                  id={id}
                  name={id}
                  checked={data === option.value}
                  onChange={onChange}
                  disabled={!enabled}
                />
              </Label>
            </RadioOption>
          );
        }
        return null;
      })}
    </Wrapper>
    <div className={divClassNames}>
      {!isValid ? errors : error_description}
    </div>
  </div>;
};
RadioIconsControl.propTypes = {
  classNames: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  config: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withVanillaControlProps(
  withJsonFormsEnumProps(RadioIconsControl)
);
