import React from 'react';
import PropTypes from 'prop-types';
import {Divider, Paragraph, Row} from 'shared/ui';
import checkCircle from 'assets/checkCircle.svg';
import {isObject} from 'utils/typeValidation';

const AttributesTree = ({index, border, showIcon, value, fullSize, commonStyles, stepIndex, label, smallSize, attributesProps}) => {
	const renderValue = (value, stepIndex) => {
		if(value && value.Value && Array.isArray(value.Value)) {
			return value.Value.map((valuesItem) => {
				return Object.keys(valuesItem).map((item, index) => {
					const currentItem = valuesItem[item];
					return <AttributesTree value={currentItem} key={item} index={index} fullSize={fullSize} border={border} commonStyles={commonStyles} showIcon={showIcon} stepIndex={stepIndex}/>;
				});
			});
		}
		if(value && !value.Value && isObject(value)) {
			return (
				Object.keys(value).map((item, index) => {
					if(item !== 'DisplayName') {
						return <AttributesTree value={value[item]} key={item} index={index} fullSize={fullSize} border={border} commonStyles={commonStyles} showIcon={showIcon} stepIndex={stepIndex}/>;
					}
				})
			);
		}
		return null;
	};
	return value && value.Value && (
		<>
			<React.Fragment key={value?.DisplayName || label}>
				{index !== 0 && border && <Divider />}
				<Row justifyContent={'space-between'} index={stepIndex}>
					<Paragraph
						variant={fullSize ? 'p4' : 'p5'}
						className={fullSize ? 'p4' : 'p5'}
						color={commonStyles.components.paragraphs.color || '#2b2c41'}
						smallSize={smallSize}
						bold={attributesProps?.DisplayName?.bold}
					>
						{showIcon && <img src={checkCircle} alt="checkCircle" />}
						{value?.DisplayName || label}
					</Paragraph>
					<Paragraph
						variant={fullSize ? 'p4' : 'p5'}
						className={fullSize ? 'p4' : 'p5'}
						bold={attributesProps?.Value?.bold !== undefined ? attributesProps?.Value?.bold : true}
						color={commonStyles.components.paragraphs.color || '#9696a9'}
					>
						{!Array.isArray(value?.Value) && !isObject(value?.Value) && value?.Value}
					</Paragraph>
				</Row>
			</React.Fragment>
			{renderValue(value, stepIndex + 1)}
		</>
	);
};
AttributesTree.propTypes = {
  commonStyles: PropTypes.any.isRequired,
  attributesProps: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  fullSize: PropTypes.bool.isRequired,
  smallSize: PropTypes.bool.isRequired,
  showIcon: PropTypes.bool.isRequired,
  stepIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  border: PropTypes.string.isRequired,
};

export default AttributesTree;
