export const PRIMARY = '#383874';
export const SECONDARY = '#6266EA';
export const ACCENT = '#FCA22B';
export const DANGER = '#F14141';
export const FORM_BG = '#ffffff';
export const HIGHLIGHTS_BG = '#F2F2FC';
export const INPUT_BG = '#ffffff';
export const INPUT_COLOR = '#2B2C41';
export const INPUT_PADDING = '0 14px';
export const HEADINGS_COLOR = '#010101';
export const PARAGRAPH_COLOR = '#2B2C41';
