import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const LoaderWrap = styled.div`
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background: ${(props) => props.style.colors.primary};
    z-index: -1;
    border-radius: ${px2vw(12)};
  }

  position: ${(props) => props.fullscreen ? 'fixed' : 'absolute'};
  ${(props) => props.blurred ? `
    backdrop-filter: blur(5px);
  ` : ''}

  p {
    width: 50%;
    text-align: center;
    font-weight: 700;
  }

  @media screen and (min-width: 1921px) {
    border-radius: ${px2vw(12)};
  }
`;

export const StyledIcon = styled.div`
  height: 90px;

  @media screen and (min-width: 1921px) {
    height: ${px2vw(90)};
  }

  @keyframes spinning {
    25%   { transform: rotateZ(90deg); }
    50%   { transform: rotateZ(180deg); }
    75%   { transform: rotateZ(270deg); }
    100%  { transform: rotateZ(360deg); }
  }
  
  animation: spinning 1s infinite linear;
`;
