import React, {useContext} from 'react';
import ThemedDivider from './ThemedDivider';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const Divider = (props) => {
  const { commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  const themeStyles = theme === 'material'
    ? materialTheme(commonStyles, clientWidth, { labelPlacement })
    : customTheme(commonStyles)[theme];

  return <ThemedDivider themeStyles={themeStyles} {...props} />;
};

export default Divider;
