import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const TitleMaterial = ({
  children,
  textAlign = 'center',
  color,
  variant = 'h5',
  className,
}) => {
  return (
    <Typography variant={variant} mb={1} color={color} style={{textAlign: textAlign}} className={className}>
      {children}
    </Typography>
  );
};
TitleMaterial.propTypes = {
  children: PropTypes.any.isRequired,
  textAlign: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
export default TitleMaterial;
