import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Paragraph = styled.p`
  font-family: ${(props) => props.style?.components?.paragraphs?.fontFamily || 'Poppins'};
  color: ${props => props.style?.components.paragraphs.color};
  font-style: normal;
  font-weight: ${(props) => props.bold ? 700 : 400};
  text-align: ${(props) => props.textAlign};
  margin: 0;
  font-size: ${(props) => props.style?.components.paragraphs.fontSize || '15px'};
  line-height:  ${(props) => props.style?.components.paragraphs.lineHeight || '24px'};

  a {
    color: ${props => props.style?.components?.paragraphs?.color};
  }

  @media screen and (min-width: 1921px) {
    font-size: ${px2vw((props) => props.style?.components.paragraphs.fontSize || 15)};
    line-height: ${px2vw((props) => props.style?.components.paragraphs.lineHeight || 24)};
  }
`;
