import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Helpers,
	createDefaultValue
} from '@jsonforms/core';
import { withJsonFormsArrayControlProps } from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

import { AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArrayOption from './ArrayOption';
import { StyledTitle, StyledSummary, StyledAccordion } from './styles';

import {StageContext} from '../../Stages';
import { Button } from 'shared/ui';

const { convertToValidClassName } = Helpers;

export const ArrayControl = (props) => {
  const {
		addItem,
		uischema,
		schema,
		path,
		data,
		visible,
		errors,
		getStyleAsClassName,
		childErrors,
		removeItems,
		handleChange,
  } = props;
	const { clientData: {template, heroImage} } = useContext(StageContext);
	const [expanded, setExpanded] = useState(true);

	const controlElement = uischema;
	const tableClass = getStyleAsClassName('array.table.table');
	const labelClass = getStyleAsClassName('array.table.label');
	const validationClass = getStyleAsClassName('array.table.validation');
	const { title } =  schema;
	const controlClass = [
			getStyleAsClassName('array.table'),
			convertToValidClassName(controlElement.scope),
	].join(' ');
	const isValid = errors.length === 0;
	const divClassNames = [validationClass]
		.concat(isValid ? '' : getStyleAsClassName('array.table.validation.error'))
		.join(' ');
	const renderChilds = (_child, index) => {
		return <ArrayOption
							key={index}
							index={index}
							childErrors={childErrors}
							getStyleAsClassName={getStyleAsClassName}
							path={path}
							schema={schema}
							removeItems={removeItems}
							_child={_child}
							onChange={handleChange}
						/>;
	};

	const toggleAccordion = () => setExpanded(!expanded);
	return (
		<div className={controlClass} hidden={!visible}>
			{template === 'assets' ?
				<StyledAccordion sx={{ boxShadow: 'none' }} expanded={expanded}>
					<StyledSummary
						expandIcon={<ExpandMoreIcon sx={{ color: '#323232' }} onClick={toggleAccordion} />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						heroImage={heroImage?.position}
					>
						<StyledTitle className={labelClass} >{title}</StyledTitle>
						<Button
							variant='text'
							color='secondary'

							className='text'
							onClick={addItem(path, createDefaultValue(schema))}
							text={'ADD'}
						/>
					</StyledSummary>
					<AccordionDetails sx={{ padding: '8px 0 16px' }}>
						<div className={divClassNames}>{!isValid ? errors : ''}</div>
						<div className={tableClass}>
							{!data || !Array.isArray(data) || data.length === 0 ? (
								<div></div>
							) : (
								data.map(renderChilds)
							)}
						</div>
					</AccordionDetails>
				</StyledAccordion>
				: <>
				<div className={divClassNames}>{!isValid ? errors : ''}</div>
				<div className={tableClass}>
					{!data || !Array.isArray(data) || data.length === 0 ? (
						<div></div>
					) : (
						data.map(renderChilds)
					)}
				</div>
			</>
			}
		</div>
	);
};
ArrayControl.propTypes = {
  addItem: PropTypes.func.isRequired,
  getStyleAsClassName: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  uischema: PropTypes.any.isRequired,
  schema: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  childErrors: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
export default withVanillaControlProps(
  withJsonFormsArrayControlProps(ArrayControl)
);
