import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Interweave} from 'interweave';

import {Container} from 'pages/Main/styles';
import {Button, Col, MarginTopBox, Row, Title, Description} from 'shared/ui';
import BannerImage from '../BannerImage';
import Logo from '../Logo';
import {StyleContext} from 'pages/Main';
import {ClientDataContext} from '../../App';
import {useClientDataForStep} from 'utils/useClientDataForStep';
import mainStyles from 'themes/mainStyles';

const getClassName = (fullSize, smallSize) => {
    const smSizeCl = smallSize ? 'smallSize' : '';
    return `ix-container ${fullSize ? 'fullSize' : smSizeCl}`;
};
const getTitleJustifyContent = (fullSize, smallSize) => {
    return fullSize || smallSize ? 'center' : 'flex-start';
};
const smButtonJustifyContent = (clientWidth, theme) => {
    return (clientWidth < 550 || theme === 'material') ? 'center' : 'start';
};
const lgButtonJustifyContent = (button) => {
    return ['center', 'left', 'right'].includes(button?.placement) ? button?.placement : 'end';
};

function CTAWidgetTemplate({
  openMicrosite,
  handleOpen
}) {
  const {clientData} = useContext(ClientDataContext);
  const {modal, fullSize, smallSize, isMicroSite, theme, logo, heroImage, title, button, styles} = useClientDataForStep('CtaStage', clientData);
  const commonStyles = mainStyles(styles, heroImage, theme);
  const { clientWidth } = useContext(StyleContext);
  const { visible = true } = logo ?? {};
  const renderTitle = !title || title.visible || title.visible === undefined;
  return (
    <Container
        style={commonStyles}
        position={heroImage?.position}
        className={getClassName(fullSize, smallSize)}
    >
    <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize} />
        <Row justifyContent={'space-between'}>
            <Col>
                {visible && <Row justifyContent={'flex-start'} className='ix-logo-div'>
                    <Logo logo={logo} heroImagePosition={heroImage?.position}/>
                </Row>}
                {renderTitle &&
                    <MarginTopBox className={'ix-title-div'} smallSizeMarginTop={'16px'} fullSizeMarginTop={'16px'}>
                        <Row justifyContent={getTitleJustifyContent(fullSize, smallSize)}>
                            <Title
                                variant={isMicroSite && (clientWidth >= 470) ? 'h3' : 'h5'}
                                className={'ix-title'}
                                textAlign={'left'}
                                useCTA={true}
                            >
                                <Interweave content={title?.content || 'Lock in your quote in the blink of an eye '} />
                            </Title>
                        </Row>
                    </MarginTopBox>
                }
                <Description step={'CtaStage'} position='top'/>
                {(clientWidth < 1000 || theme === 'material') && (
                    <MarginTopBox
                        smallSizeMarginTop={smallSize ? '16px' : '10px'}
                        fullSizeMarginTop={'16px'}
                        className={'ix-submit-button-div'}
                    >
                        <Row justifyContent={smButtonJustifyContent(clientWidth, theme)}>
                            <Button
                                variant='contained'
                                color='secondary'
                                useCTA={true}
                                className='secondary'
                                onClick={modal ? handleOpen : openMicrosite}
                                text={button?.content || 'See your offers'}
                                flex={button?.placement === 'fullwidth' || (!button?.placement && clientWidth < 470 && !smallSize)}
                                borderRadius={button?.borderRadius}
                            />
                        </Row>
                    </MarginTopBox>
                )}
            </Col>
            {(clientWidth >= 1000 && theme !== 'material') && (
                <Col>
                    <MarginTopBox
                        smallSizeMarginTop={0}
                        fullSizeMarginTop={0}
                        className={'ix-submit-button-div'}
                    >
                        <Row justifyContent={lgButtonJustifyContent(button)}>
                            <Button
                                variant='contained'
                                color='secondary'
                                useCTA={true}
                                className='secondary'
                                onClick={modal ? handleOpen : openMicrosite}
                                text={button?.content || 'See your offers'}
                                flex={button?.placement === 'fullwidth'}
                                borderRadius={button?.borderRadius}
                            />
                        </Row>
                    </MarginTopBox>
                </Col>
            )}
        </Row>
    </Container>
  );
}
CTAWidgetTemplate.propTypes = {
    openMicrosite: PropTypes.any.isRequired,
    handleOpen: PropTypes.string.isRequired,
};

export default CTAWidgetTemplate;
