import {useState, useEffect} from 'react';
import {schemaToSteps} from './schemaToSteps';

function useSchemaSteps(requestedSchema, stepCategories, labelPlacement, layout) {
  const [processedSchemas, setProcessedSchemas] = useState();
  const [processedUiSchemas, setProcessedUiSchemas] = useState();

  useEffect(() => {
    if (requestedSchema) {
      const [precessedSchemas, processedUiSchemas] = schemaToSteps(requestedSchema, stepCategories, labelPlacement, layout);
      setProcessedSchemas(precessedSchemas);
      setProcessedUiSchemas(processedUiSchemas);
    }
  }, [requestedSchema]);

  return [processedSchemas, processedUiSchemas];
}

export default useSchemaSteps;
