import React, { useCallback, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StageContext } from '..';

import SchemaRenderer from '../../SchemaRenderers';

const Multistep = ({ Template, schema, uiSchema, onSubmit, data, setData, goBack }) => {
    const { currentStageNumber, setStepName, stepName, clientData } = useContext(StageContext);
    const [currentStepNumber, setCurrentStepNumber] = useState(1);

    const toPrevStep = useCallback(() => {
        goBack();
    }, [goBack]);

    const toNextStep = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    useEffect(() => {
        if (stepName) {
            if(currentStageNumber === 2) {
                const stepCategoriesKeys = clientData?.ConfirmationStage && Object.keys(clientData?.ConfirmationStage.stepCategories);
                stepCategoriesKeys?.map((item, i) => {
                    if (item === stepName && (i+1) !== currentStepNumber) {
                        setCurrentStepNumber(i+1);
                    }
                });
            }
        } else {
            setStepName(schema.stepName);
        }
    }, [stepName]);

    return (
        <SchemaRenderer
            stepNumber={currentStageNumber + (currentStepNumber - 1)}
            toPrevStep={toPrevStep}
            toNextStep={toNextStep}
            schema={schema}
            uiSchema={clientData.uiSchema || uiSchema}
            data={data}
            setData={setData}
            template={Template}
        />
    );
};
Multistep.propTypes = {
    Template: PropTypes.any.isRequired,
    schema: PropTypes.any.isRequired,
    uiSchema: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    goBack: PropTypes.func,
};

export default Multistep;