const customTheme = (commonStyles) => {
  return {
    default: {
      ...commonStyles,
      components: {
        ...commonStyles?.components,
        headings: {
          ...commonStyles?.components.headings,
          fontFamily: commonStyles?.components?.headings?.fontFamily || 'Arial',
        },
        paragraphs: {
          ...commonStyles?.components.paragraphs,
          fontFamily: commonStyles?.components?.paragraphs?.fontFamily || 'Arial',
        },
        label: {
          ...commonStyles?.components.label,
        },
        input: {
          ...commonStyles?.components.input,
          boxShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${commonStyles?.colors?.secondary}`,
          dangerShadow: `inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px ${commonStyles?.colors?.danger}`
        },
        container: {
          ...commonStyles?.components.container,
          borderRadius: '20px'
        },
        button: {
          ...commonStyles?.components.button,
          borderRadius: commonStyles?.components.button?.borderRadius || '100px',
        },
        select: {
          image: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45MjMgMC41OTAwODhMNi4zMzMwMSA1LjE3MDA5TDEuNzQzMDEgMC41OTAwODhMMC4zMzMwMDggMi4wMDAwOUw2LjMzMzAxIDguMDAwMDlMMTIuMzMzIDIuMDAwMDlMMTAuOTIzIDAuNTkwMDg4WiIgZmlsbD0iIzk2OTZBOSIvPgo8L3N2Zz4K)',
        }
      },
    },
    straight: {
      ...commonStyles,
      components: {
        ...commonStyles?.components,
        headings: {
          ...commonStyles?.components.headings,
          fontFamily: commonStyles?.components?.headings?.fontFamily || 'Mulish',
        },
        paragraphs: {
          ...commonStyles?.components.paragraphs,
          fontFamily: commonStyles?.components?.paragraphs?.fontFamily || 'Poppins',
        },
        label: {
          ...commonStyles?.components.label,
        },
        input: {
          ...commonStyles?.components.input,
          boxShadow: 'none'
        },
        container: {
          ...commonStyles?.components.container,
          borderRadius: '0'
        },
        button: {
          ...commonStyles?.components.button,
        },
        select: {
          image: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45MjMgMC41OTAwODhMNi4zMzMwMSA1LjE3MDA5TDEuNzQzMDEgMC41OTAwODhMMC4zMzMwMDggMi4wMDAwOUw2LjMzMzAxIDguMDAwMDlMMTIuMzMzIDIuMDAwMDlMMTAuOTIzIDAuNTkwMDg4WiIgZmlsbD0iIzAxMDEwMSIvPgo8L3N2Zz4K)',
        }
      },
    }
  };
};
export default customTheme;
