import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background: ${(props) => props.style?.components.input.background};
    border-radius: ${(props) => props.style?.components.input.borderRadius || '0'};
    border: ${(props) => props.style?.components.input.border};
    &.Mui-error {
      border-color: ${(props) => props.style?.colors.danger};
      -webkit-box-shadow: ${(props) =>
              props.style?.components.input.dangerShadow};
      box-shadow: ${(props) => props.style?.components.input.dangerShadow};
    }
    &.Mui-focused {
      border-color: ${(props) => props.style?.colors.secondary};
      -webkit-box-shadow: ${(props) => props.style?.components.input.boxShadow};
      box-shadow: ${(props) => props.style?.components.input.boxShadow};
      outline: 0;
    }
  }
  .MuiInputBase-input{
    min-height: ${(props) => props.style?.components.input.minHeight || '42px'};
    padding: ${(props) => props.style?.components.input.padding};
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.style?.components.input.fontSize};
    font-family: ${(props) => props.style?.components.paragraphs.fontFamily};
    color: ${(props) => props.style?.components.input.color};
    ${(props) => props.labelPosition === 'inside' && `
      &::placeholder {
        color: ${props.style?.components.input.color};
        opacity: 1;
      }
    `}
  }
  .MuiOutlinedInput-notchedOutline{
    border: none;
  }
  .MuiInputAdornment-root, .MuiButtonBase-root{
    color: ${(props) => props.style?.components.input.color};
  }
`;