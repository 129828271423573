import axios from 'axios';
import {carApiHeader, defaultHeader, herePlatformHeader, sendGridHeader, storageHeader} from './headers';
import {tokenParams} from 'utils/tokenParams';
import {AUTOCOMPLETE_KEY, REACT_APP_CAR_API_SECRET, REACT_APP_CAR_API_TOKEN} from '../env_constants';

const getAuthDataStored = (clientId) => {
	const authData = localStorage.getItem('authorization');
	if(authData) {
		return JSON.parse(authData)[clientId] || {};
	}
	return {};
};
const getCarApiAuthDataStored = () => {
	return localStorage.getItem('carApiToken');
};

const setAuthDataStored = (authData, clientId) => {
	if(authData) {
		const currentAuthData = localStorage.getItem('authorization') || '{}';
		const newAuthData = { ...JSON.parse(currentAuthData), [clientId]: authData };
		localStorage.setItem('authorization', JSON.stringify(newAuthData));
	} else {
		localStorage.setItem('authorization', '{}');
	}
};

const logout = (clientId) => {
	let currentAuthData = localStorage.getItem('authorization') || '{}';
	currentAuthData = JSON.parse(currentAuthData);
	delete currentAuthData[clientId];
	localStorage.setItem('authorization', JSON.stringify(currentAuthData));
	console.log('redirect to login');
};

let failedQueue = [];
let isRefreshing = {};

const processQueue = (error) => {
	failedQueue.forEach((p) => {
		if (error) {
			p.reject(error);
		} else {
			p.resolve();
		}
	});
	failedQueue = [];
};

export const IXAdminApiPublic = axios.create(defaultHeader);
export const IXAdminApiPrivate = axios.create(defaultHeader);
export const IXAdminStorageApi = axios.create(storageHeader);
export const IXHerePlatform = axios.create(herePlatformHeader);
export const IXSendGridApi = axios.create(sendGridHeader);
export const IXCarApi = axios.create(carApiHeader);
IXCarApi.interceptors.request.use(
	(config) => {
		const token = getCarApiAuthDataStored();
		if (token && config.headers) {
			config.headers.authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

IXAdminApiPrivate.interceptors.request.use(
	(config) => {
		if (config.authorization !== false) {
			const {access_token, token_type} = getAuthDataStored(config.clientId);
			if (access_token && token_type && config.headers) {
				config.headers.authorization = `${token_type} ${access_token}`;
				config.headers['Content-Type'] = 'application/json';
			}
		}
		config.headers['Content-Type'] = 'application/json';

		return config;
	},
	(error) => Promise.reject(error)
);

IXAdminApiPrivate.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		originalRequest.headers = JSON.parse(
			JSON.stringify(originalRequest.headers || {})
		);

		// If error, process all the requests in the queue and logout the user.
		const handleError = (error) => {
			processQueue(error);
			logout(originalRequest.clientId);
			return Promise.reject(error);
		};

		// Refresh token conditions
		if (
			(error.response?.status === 401 ||
				error.response?.status === 403) &&
			originalRequest?.url !== '/connect/token' &&
			originalRequest?._retry !== true
		) {
			if (isRefreshing[originalRequest.clientId]) {
				return new Promise(function (resolve, reject) {
					failedQueue.push({resolve, reject});
				})
					.then(() => {
						return IXAdminApiPrivate(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}
			isRefreshing[originalRequest.clientId] = true;
			originalRequest._retry = true;
			if (!originalRequest.clientId) throw new Error('clientId is missing. Make sure you set the clientId property to tokenParams.');
			return getToken({...tokenParams.params, client_id: originalRequest.clientId})
				.then((authData) => {
					setAuthDataStored(authData?.data, originalRequest.clientId);
					processQueue(null);
					return IXAdminApiPrivate(originalRequest);
				}, handleError)
				.finally(() => {
					isRefreshing[originalRequest.clientId] = false;
				});
		}
		if (
			error.response?.status === 401 ||
			error.response?.status === 403
		) {
			return handleError(error);
		}

		return Promise.reject(error);
	}
);

// Token

export const getToken = (params) => {
	return IXAdminApiPublic.post('/connect/token', params);
};

// Schemas

export const getSchemas = (params, clientId) => {
	return IXAdminApiPrivate.get(`/api/Products/${params.productId}/Schemas`, {
		clientId
	});
};

// WidgetDeploys

export const postWidgetDeploys = (data, clientId) => {
	return IXAdminApiPrivate.post('/api/WidgetDeploys', data, {clientId});
};

// ApplicationForms

export const postApplicationForms = (params, config) => {
	return IXAdminApiPrivate.post('/api/ApplicationForms', params, config);
};

export const getApplicationProcess = (applicationFormId, clientId) => {
	return IXAdminApiPrivate.get(`/api/ApplicationForms/${applicationFormId}/applicationprocess`, {clientId});
};

export const getBackApplicationProcess = (applicationFormId, data, clientId) => {
	return IXAdminApiPrivate.post(`/api/ApplicationForms/${applicationFormId}/applicationprocess/back`, data, {clientId});
};

export const getDynamicSchema = (applicationFormId, clientId) => {
	return IXAdminApiPrivate.get(`/api/ApplicationForms/${applicationFormId}/DynamicSchema`, {clientId});
};

export const patchApplicationForm = (applicationFormId, data, config) => {
	return IXAdminApiPrivate.patch(`/api/ApplicationForms/${applicationFormId}`, data, config);
};

export const getWorkflowResult = (id, clientId) => {
	return IXAdminApiPrivate.get(`/api/ApplicationForms/${id}/workflow-result`, {clientId});
};

export const postContactInformation = (data, clientId) => {
	return IXAdminApiPrivate.post('/api/ApplicationForms/ContactInformation', data, {clientId});
};

// SystemGlossary

export const getSystemGlossary = (id, clientId) => {
	return IXAdminApiPrivate.get(`/api/SystemGlossary/fields/${id}`, {clientId});
};

// Workflows

export const getAppFormWorkflows = (id, clientId) => {
	return IXAdminApiPrivate.get(`/api/Workflows/${id}`, {clientId});
};

// Widget configuration

export const getWidget = (id) => {
	return IXAdminStorageApi.get(`/ixplatform-public/Widget-${id}/configuration.json`);
};

// Address Autocomplete

export const smartyStreetsAutocomplete = (query) => {
	return axios.get(`https://smartystreetsapi-proxy.azurewebsites.net/api/ProxyFunction?search=${query}`);
};

export const locationAutocomplete = (textBox) => {
	let params = '?' +
		'q=' + encodeURIComponent(textBox) +
		`&in=${encodeURIComponent('countryCode:USA')}` +
		'&apikey=' + AUTOCOMPLETE_KEY;
	return IXHerePlatform.get('autocomplete' + params);
};
/**
 * Request for validate email
 * @param {Object} data - Request Body
 * @param {string} data.email - The email address that you want to validate.
 */
export const validateEmail = (data) => {
	return IXSendGridApi.post('/v3/validations/email', data);
};
/**
 * To being create the JWT token by calling the Auth API
 */
export const loginAuth = () => {
	return IXCarApi.post('carapiauth', {
		'api_token': REACT_APP_CAR_API_TOKEN,
		'api_secret': REACT_APP_CAR_API_SECRET
	});
};

/**
 * Request for getting car years
 */
export const getCarYears = () => {
	return IXCarApi.get('carapi/years');
};
/**
 * Request for getting car models
 * @param {string} year - Year of the cars
 */
export const getCarMakes = (year) => {
	return IXCarApi.get('carapi/makes', {params: {year: year}});
};
/**
 * Request for getting car models
 * @param {Object} data - Request Body
 * @param {string} data.modelYear - The year
 * @param {string} data.modelMake - The make
 */
export const getCarModels = ({modelMake, modelYear}) => {
	return IXCarApi.get('carapi/models', {params: {make: modelMake, year: modelYear}});
};

/**
 * Request for getting car models
 * @param {Object} data - Request Body
 * @param {string} data.year - The year
 * @param {string} data.make - The make
 * @param {string} data.model - The make
 */
export const getCarTrim = ({make, year, model}) => {
	return IXCarApi.get('carapi/trims', {params: {make, year, model, verbose: 'yes'}});
};

/**
 * Request for getting car models
 * @param {Object} data - Request Body
 * @param {string} data.year - The year
 * @param {string} data.make - The make
 * @param {string} data.model - The make
 * @param {string} data.trim - The trim
 */
export const getCarEngine = ({make, year, model, trim}) => {
	return IXCarApi.get('carapi/engines', {params: {make, year, model, make_model_trim_id: trim}});
};
