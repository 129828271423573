import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { ImageBlock, LogoBlock } from './styles';
import Logo from '../../Logo';
import {StageContext} from '../../Stages';

function ImageHeader({
  heroImage,
  logo,
  fade = false,
}) {
  const { commonStyles } = useContext(StageContext);
  return (
    <ImageBlock position={'top'} fade={fade} className='ix-imageHeader' style={commonStyles}>
      <LogoBlock>
        <Logo logo={logo} heroImagePosition={'contain'}/>
      </LogoBlock>
      {heroImage?.src && <img alt='car' src={heroImage.src} className='background' />}
    </ImageBlock>
  );
}
ImageHeader.propTypes = {
  heroImage: PropTypes.any.isRequired,
  logo: PropTypes.any.isRequired,
  fade: PropTypes.bool.isRequired,
};
export default ImageHeader;