import React, {useContext, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {isDateControl, isDateTimeControl, or, rankWith} from '@jsonforms/core';
import moment from 'moment';
import FormContext from '../../FormContext/FormContext';
import {ErrorMessage} from '../../ErrorMessage/ErrorMessage';
import {StageContext} from '../../Stages';
import { getAttributeDescription } from 'utils/helpers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StyledTextField } from './styles';

const DatePickerControl = ({data, handleChange, path, id, label, uischema: {labelPosition}, required, errors, description}) => {
	const { setAdditionalErrors, errors: additionalErrors, showValidation } = useContext(FormContext);
  const { commonStyles, clientData: {controlsWithDescription} } = useContext(StageContext);

	const isDateOfBirth = path.includes('DateOfBirth');
	const isDateOfLoss = path.includes('DateOfLoss');
	let tomorrow  = moment().add(1,'days').format('MM-DD-YYYY');
	let yesterday  = moment().add(-1,'days').format('MM-DD-YYYY');
	let adult = moment().add(-15, 'years').add(-1, 'days').format('MM-DD-YYYY');
	let minDateOfLoss = moment().add(-3, 'years').add(-1, 'days').format('MM-DD-YYYY');
	let inTwoMonth = moment().add(2, 'months').format('MM-DD-YYYY');

	const lossMinDate = isDateOfLoss ? minDateOfLoss : tomorrow;
	const minDate = isDateOfBirth ? '01-01-1800' : lossMinDate;
	const lossMaxDate = isDateOfLoss ? yesterday : inTwoMonth;
	const maxDate = isDateOfBirth ? adult : lossMaxDate;

	useEffect(() => {
		const date = new Date(moment(data).format('MM-DD-YYYY'));
		const newError = {
			instancePath: `/${path}`,
			message: 'Wrong date',
			schemaPath: '',
			keyword: path,
			params: {},
		};
		const min = new Date(minDate);
		const max = new Date(maxDate);
		

		if(date > max || date < min) {
			setAdditionalErrors((prevErrors) => {
				const errors = prevErrors.filter(item => item.keyword !== path);
				return [...errors, newError];
			});
		} else {
			setAdditionalErrors((prevErrors) => prevErrors.filter(item => item.keyword !== path));
		}
	}, [data]);

	const lossError = isDateOfLoss ? 'date must be within last 3 years' : 'The date must be between tomorrow and 60 days from today';
	const error_message = isDateOfBirth ? 'Enter a valid date' : lossError;

	const placeholder = labelPosition === 'top' ? 'MM-DD-YYYY' : label;

  const attribute_description = getAttributeDescription(path, controlsWithDescription);

	const currentError = useMemo(() => {
		return additionalErrors.length > 0 ? additionalErrors.find((item) => item.keyword === path || item.params?.missingProperty === path || item.instancePath	=== `/${path}`) : null;
	}, [additionalErrors]);

	return (
		<div id={id}>
			{labelPosition === 'top' && <label htmlFor={`${id}-input`}>{label}{required && '*'}</label>}
			{attribute_description && <p className='description'>{description}</p>}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					value={data || ''}
					id={`${id}-input`}
					onChange={(e) => e && handleChange(path, moment(e.toString()).format('YYYY-MM-DD'))}
					renderInput={(params) => 
						<StyledTextField 
							{...params} 
							variant="outlined" 
							error={showValidation && (currentError || errors)} 
							style={commonStyles}
							labelPosition={labelPosition}
							inputProps={{
								...params.inputProps,
								placeholder,
							}}
						/>}
					minDate={minDate}
					maxDate={maxDate}
					format="MM-DD-YYYY"
				/>
			</LocalizationProvider>
			<ErrorMessage
				errors={showValidation && (currentError || errors) ? error_message : ''}
				path={path}
				data={data}
			/>
		</div>

	);
};
DatePickerControl.propTypes = {
  data: PropTypes.any.isRequired,
  uischema: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export const DatePickerControlTester = rankWith(
	4,
	or(isDateControl, isDateTimeControl)
);
export default withJsonFormsControlProps(DatePickerControl);
