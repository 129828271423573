import React from 'react';
import PropTypes from 'prop-types';
import { Row } from './styles';

const ThemedRow = ({
  themeStyles,
  alignItems = 'center',
  justifyContent = 'flex-start',
  children,
  className,
  index,
}) => {
  return (
    <Row
      style={themeStyles}
      alignItems={alignItems}
      justifyContent={justifyContent}
      className={className}
      index={index}
    >
      {children}
    </Row>
  );
};
ThemedRow.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number,
};
export default ThemedRow;
