import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import TitleMaterial from './Material';
import TitleCustom from './Custom';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const Title = (props) => {
  const { commonStylesCTA, commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const selectedCommonStyles = props.useCTA ? commonStylesCTA : commonStyles;
  const themeStyles = theme === 'material'
    ? materialTheme(selectedCommonStyles, clientWidth, { labelPlacement })
    : customTheme(selectedCommonStyles)[theme];

  const ThemedTitle = theme ==='material' ? TitleMaterial : TitleCustom;
  return <ThemedTitle themeStyles={themeStyles} {...props} />;
};
Title.propTypes = {
  useCTA: PropTypes.any,
};

export default Title;
