import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const Line = styled.div`
  width: 100%;
  height: 0px;
  box-sizing: border-box;
  border-top: 1px solid #dadae3;

  @media screen and (min-width: 1921px) {
    border-top: ${px2vw(1)} solid #dadae3;
  }
`;
