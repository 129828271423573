import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleContext} from 'pages/Main';
import {BorderedPaddingBox} from './styles';
import {StageContext} from 'components/Stages';

const BorderedPaddingBoxCustom = ({
  themeStyles,
  children,
  onClick,
  borderRadius = '0px',
  fullSizePadding = '20px',
  smallSizePadding = '16px',
  background = '#ffffff',
}) => {
  const { clientData: { smallSize } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  return (
    <BorderedPaddingBox
      style={themeStyles}
      smallSize={smallSize || clientWidth < 470}
      onClick={onClick}
      borderRadius={borderRadius}
      fullSizePadding={fullSizePadding}
      smallSizePadding={smallSizePadding}
      background={background}
    >
      {children}
    </BorderedPaddingBox>
  );
};
BorderedPaddingBoxCustom.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  borderRadius: PropTypes.string.isRequired,
  fullSizePadding: PropTypes.string.isRequired,
  smallSizePadding: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};
export default BorderedPaddingBoxCustom;
