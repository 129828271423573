export const invokeEvents = (prevStageNumber, currentStageNumber) => {
    let eventName = null;
    switch(currentStageNumber) {
        case 1: {
            eventName = 'movedToFirstScreen';
            break;
        }
        case 2: {
            if (prevStageNumber === 1) eventName = 'onFirstScreenSubmit';
            break;
        }
        case 3: {
            eventName = 'onConfirmationSubmit';
            break;
        }
    }
    if (eventName) {
      const event = new Event(eventName);
      window.dispatchEvent(event);
    }
};