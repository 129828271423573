import React, {useContext} from 'react';

import {Block, Container} from 'pages/Main/styles';

import {Col, MarginTopBox, Paragraph, Row, Title} from 'shared/ui';
import {StyleContext} from 'pages/Main';
import BannerImage from 'components/BannerImage';
import {StageContext} from 'components/Stages';
import {Interweave} from 'interweave';
import Header from 'components/Header/StepperHeader';

function SuccessPageTemplate() {
  const {
    commonStyles,
    clientData: {
      fullSize,
      smallSize,
      isMicroSite,
      heroImage,
      SuccessPage: { title, description } = {},
    }
  } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
  const titlePositionClass = title?.position === 'right' ? 'flex-end' : 'flex-start';
  const justifyContent = title?.position === 'center' ? 'center' : titlePositionClass;
  const textAlign = fullSize || smallSize ? 'center' : 'left';

  return (
    <Container
      style={commonStyles}
      position={heroImage?.position}
      className={fullSize ? 'ix-container fullSize' : smSizeClass}
    >
      {smallSize && heroImage?.position !== 'contain' ? null :
        <BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
      }
      <Block>
        <Header
          stepNumber={1}
        />
        <div className='ix-content' style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Col justifyContent={'start'}>
            <MarginTopBox>
              <Row justifyContent={justifyContent}>
                <Title
                  variant={isMicroSite && (clientWidth >= 470) ? 'h3' : 'h5'}
                  className={'ix-title'}
                  textAlign={textAlign}
                  color={commonStyles.components.headings.color || '#010101'}
                >
                  <Interweave content={title?.content || 'Thank you for your submission'} />
                </Title>
              </Row>
            </MarginTopBox>
            <MarginTopBox>
              <Row justifyContent={'center'}>
                <Paragraph
                  variant={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  className={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
                  bold={isMicroSite}
                  textAlign={'center'}
                  color={commonStyles.components.paragraphs.color || '#2b2c41'}
                >
                  {description || 'One of our partner agent representatives will contact you to review your insurance needs.'}
                </Paragraph>
              </Row>
            </MarginTopBox>
          </Col>
        </div>
      </Block>
    </Container>
  );
}

export default SuccessPageTemplate;
