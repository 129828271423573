import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const StyledLogo = styled.img`
  max-width: auto;
  height: ${props => props.height || '50px'};
  width: ${props => props.width || 'auto'};

  @media screen and (min-width: 1921px) {
    height: ${(props) => px2vw(props.height)};
  }
`;
export const StyledLogoWrapper = styled.div`
  display: flex;
	justify-content: ${(props) => props.justifyContent};
	z-index: 999;
	margin-top: 10px;
`;
