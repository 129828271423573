import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const StyledForm = styled.div`
  .MuiFormHelperText-root {
    color: ${(props) => props.headingsColor};
    ${(props) => !props.showWarnings && 'display: none;'}

    @media screen and (min-width: 1921px) {
      font-size: ${px2vw(12)} !important;
      line-height: ${px2vw(20)} !important;
    }
  }

  .MuiGrid-item {
    margin-bottom: 5px;

    @media screen and (min-width: 1921px) {
      margin-bottom: ${px2vw(5)};
    }
  }
`;
