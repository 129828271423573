import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {List} from './styles';
import {StageContext} from '../../Stages';
import AttributesTree from './AttributesTree';

function AttributesAndValues({ attributesAndValuesObj }) {
  const { commonStyles, clientData: {smallSize, fullSize, showIcon, border = true, attributes}} = useContext(StageContext);
  const entries = attributesAndValuesObj ? Object.entries(attributesAndValuesObj) : null;
  const stepIndex = 0;
  return (
    <List smallSize={smallSize} data-testid="resultPage-list" overlflow="auto">
      {!!entries?.length &&
        entries.map(([ key, value ], index) => (
          <React.Fragment key={value?.DisplayName || key}>
            <AttributesTree 
              key={key} 
              label={key} 
              value={value} 
              border={border} 
              commonStyles={commonStyles} 
              fullSize={fullSize} 
              index={index} 
              showIcon={showIcon} 
              stepIndex={stepIndex} 
              smallSize={smallSize} 
              attributesProps={attributes}
            />
          </React.Fragment>
      ))}
    </List>
  );
}
AttributesAndValues.propTypes = {
  attributesAndValuesObj: PropTypes.any.isRequired,
};

export default AttributesAndValues;
