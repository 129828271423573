import React, {useContext} from 'react';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';
import ThemedFooter from './ThemedFooter';
import {Interweave} from 'interweave';
import FooterContent from './FooterContent';

const Footer = (props) => {
  const {commonStyles, clientData: {labelPlacement, theme = 'default', footer, heroImage}} = useContext(StageContext);
  const {clientWidth} = useContext(StyleContext);
  const visible = footer?.visible;

  const themeStyles = theme === 'material'
    ? materialTheme(commonStyles, clientWidth, {labelPlacement})
    : customTheme(commonStyles)[theme];

  return visible !== false && <ThemedFooter themeStyles={themeStyles} {...props} >
    {footer?.content ? 
      <Interweave
          content={footer?.content}
      /> : <FooterContent style={commonStyles} heroImage={heroImage} />}
  </ThemedFooter>;
};

export default Footer;
