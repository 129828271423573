import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const List = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => props.smallSize ? '10px' : '10px 20px'};
  overflow: ${(props) => props.overflow || 'inherit' };
  gap: 10px;

  p {
    display: flex;
    justify-content: start;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }

  @media screen and (min-width: 1921px) {
    gap: ${px2vw(10)};
    padding: ${px2vw('10px 20px')};
  }
`;
