import {isEmpty} from 'lodash';

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const getCompoundValue = (property, stepVariant) => {
  let compoundValue = {};
  if(property.default && !isEmpty(property.default)) {
    for(let key in property.default) {
      if(isEmpty(property.default[key])) {
        const defaultValue = getDefaultValue(property.properties[key],stepVariant);
        compoundValue[key] = defaultValue;
      } else if (property.default[key]){
        const isJson = isJsonString(property.default[key]);
        compoundValue[key] = isJson ? JSON.parse(property.default[key]) : property.default[key];
      }
    }
  } else {
    for(let key in property.properties) {
      const defaultValue = getDefaultValue(property.properties[key],stepVariant);
      compoundValue[key] = defaultValue;
    }
  }
  return compoundValue;
};

const getDefaultValue = (property, stepVariant) => {
  if (property.type?.includes('array')) {
    return property.items.default?.map(item => ({...item, checked: !(stepVariant === 'coverage' || stepVariant === 'coverages')}));
  } else if (property.type === 'integer') {
    return parseInt(property.default);
  } else if (property.type?.includes('object')) {
    return getCompoundValue(property, stepVariant);
  }
  return property.default;
};

function getUserData(userData, schema, stepVariant) {
  if (schema) {
    let initialData = {};
    for(let key in schema.properties) {
      if(userData?.[key]) {
        initialData[key] = userData[key];
      } else {
        const property = schema.properties[key];
        const defaultValue = getDefaultValue(property,stepVariant);
        if (!schema.properties[key].enum || schema.properties[key].enumg?.includes(defaultValue)) {
          initialData[key] = defaultValue;
        }
      }
    }
    return initialData;
  }
}

export default getUserData;
