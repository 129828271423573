import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import ButtonMaterial from './Material';
import ButtonCustom from './Custom';
import materialTheme from 'themes/materialTheme';
import customTheme from 'themes/customTheme';
import {StyleContext} from 'pages/Main';
import {StageContext} from 'components/Stages';

const Button = (props) => {
  const { commonStylesCTA, commonStyles, clientData: { labelPlacement, theme = 'default' } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);
  const selectedCommonStyles = props.useCTA ? commonStylesCTA : commonStyles;
  const themeStyles = theme === 'material'
    ? materialTheme(selectedCommonStyles, clientWidth, { labelPlacement },)
    : customTheme(selectedCommonStyles)[theme];
  const ThemedButton = theme ==='material' ? ButtonMaterial : ButtonCustom;
  return <ThemedButton themeStyles={themeStyles} {...props} />;
};
Button.propTypes = {
  useCTA: PropTypes.bool,
};

export default Button;
