import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  padding-left: ${({index}) => index ? index * 20 + 'px !important' : 'initial'};
  width: 100%;
	box-sizing: border-box;
`;
