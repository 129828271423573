import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleContext} from 'pages/Main';
import {MarginTopBox} from './styles';
import {StageContext} from 'components/Stages';

const ThemedMarginTopBox = ({
  children,
  fullSizeMarginTop = '24px',
  smallSizeMarginTop = '16px',
  className,
  display = 'block',
}) => {
  const { clientData: { smallSize } } = useContext(StageContext);
  const { clientWidth } = useContext(StyleContext);

  return (
    <MarginTopBox
      smallSize={smallSize || clientWidth < 470}
      fullSizeMarginTop={fullSizeMarginTop}
      smallSizeMarginTop={smallSizeMarginTop}
      className={className}
      display={display}
    >
      {children}
    </MarginTopBox>
  );
};
ThemedMarginTopBox.propTypes = {
  children: PropTypes.any.isRequired,
  fullSizeMarginTop: PropTypes.string,
  smallSizeMarginTop: PropTypes.string,
  display: PropTypes.string,
  className: PropTypes.string,
};
export default ThemedMarginTopBox;
