import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import MaterialModal from './Material';
import CustomModal from './Custom';
import {StageContext} from '../Stages';

function Modal({children, ...props}) {
  const { clientData: { theme } } = useContext(StageContext);
  return theme ==='material' ? <MaterialModal {...props} >{children}</MaterialModal> : <CustomModal {...props} >{children}</CustomModal>;
}
Modal.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Modal;
