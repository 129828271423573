import React from 'react';
import PropTypes from 'prop-types';
import {
  Title,
} from './styles';

const TitleCustom = ({
  themeStyles,
  children,
  textAlign = 'center',
  color,
  variant = 'h5',
  className,
}) => {
  return (
    <Title style={themeStyles} textAlign={textAlign} color={color} variant={variant} className={className}>
      {children}
    </Title>
  );
};
TitleCustom.propTypes = {
  themeStyles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  textAlign: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
export default TitleCustom;
