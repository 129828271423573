import styled from 'styled-components';
import { px2vw } from 'utils/px2vw';

export const ImageBlock = styled.div`
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    ${props => props.position === 'top' && `
        height: ${props.height || '120px'};
    }};
        img {
            width: 100%;
            min-height: 100%;
        }

        @media screen and (min-width: 1921px) {
            margin: -${px2vw(16)} -${px2vw(16)} ${px2vw(16)};
            height: ${px2vw(props.height || 120)};
        }
    `}
    ${props => props.position === 'bottom' && `
        height:  ${props.height || '120px'};
        img {
            width: 100%;
            min-height: 100%;
        }

        @media screen and (min-width: 1921px) {
            margin: ${px2vw(16)} -${px2vw(16)} -${px2vw(16)};
            height:  ${px2vw(props.height || 120)}};
        }
    `}
    ${props => props.position === 'left' && `
        width: ${props.width || '150px'};
        img {
            height: 100%;
            min-width: 100%;
        }

        @media screen and (min-width: 1921px) {
            margin: -${px2vw(16)} ${px2vw(16)} -${px2vw(16)} -${px2vw(16)};
            width: ${px2vw(props.width || 150)};
        }
    `}
    ${props => props.position === 'right' && `
    width: ${props.width || '150px'};
        img {
            height: 100%;
            min-width: 100%;
        }

        @media screen and (min-width: 1921px) {
            margin: -${px2vw(16)} -${px2vw(16)} -${px2vw(16)} ${px2vw(16)};
            width: ${px2vw(props.width || 150)};
        }
    `}
    ${props => props.position === 'contain' && `
        border-radius: ${props.fullSize ? '0' : '12px'};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            img {
                min-height: 100%;
            }
        }
        &:before {
            content: '';
            border-radius: ${props.fullSize ? '0' : '12px'};
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: ${props.style.colors.primary};
            opacity: 0.7;
            z-index: 1;
        }
    `}
`;
