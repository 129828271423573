import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {ClientDataContext} from '../../App';
import FormHelperText from '@mui/material/FormHelperText';

export const ErrorMessage = ({errors, showDescription, description, path}) => {
	const {clientData: {errorMessages, theme}} = useContext(ClientDataContext);
	const currentErrorMessage = errors.startsWith('is a required property')
		? (errorMessages?.[path]?.required || 'Required Field')
		: (errorMessages?.[path]?.custom || errors);

	if (theme === 'material') {
		return (
			<>
				{errors && <FormHelperText error={!!errors}>{currentErrorMessage}</FormHelperText>}
				{!errors && showDescription && <FormHelperText>
					{description}
				</FormHelperText>}
			</>
		);
	}
	return (
		<div className={'validation validation_error'}>
			{errors && currentErrorMessage}
			{!errors && showDescription && description}
		</div>
	);
};
ErrorMessage.propTypes = {
  errors: PropTypes.any.isRequired,
  showDescription: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
