import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, Modal} from '@mui/material';
import {Close} from '@mui/icons-material';
import {ModalBox} from './styles';

function MaterialModal(
  {
    open,
    children,
    handleClose,
  }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <ModalBox>
        <IconButton onClick={handleClose} className="ix-close-btn" >
          <Close />
        </IconButton>
        {children}
      </ModalBox>
    </Modal>
  );
}
MaterialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default MaterialModal;
