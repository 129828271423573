import React from 'react';

import CTAWidgetTemplate from '../CTAWidgetTemplate';

function CTAWidgetCustom(props) {
  return (
    <CTAWidgetTemplate {...props} />
  );
}
export default CTAWidgetCustom;
