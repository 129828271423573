import styled from 'styled-components';
import { Title } from 'shared/ui';
import { AccordionSummary, Accordion } from '@mui/material';

export const StyledTitle = styled(Title)`
  font-size: 14px;
  text-transform: uppercase;
`;

export const Block = styled.div`
  padding: 8px;
	border-width: 1px;
	border-style: solid;
  border-color: ${props => props.error ? 'red' : 'var(--common-colors-grayscale-l-gray, #B9B9C9)'};
  background: ${props => props.heroImage === 'contain' ? 'rgba(255,255,255,0.1)' : 'var(--brand-ixbg-input-field, #FFF)'};
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  .p3 {
    width: 100%;
  }
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const UpdateForm = styled.div`
  margin-bottom: 5px;
  position: absolute;
  background: ${props => props.style.components.container.background};
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 10;
  min-height: 100%;
  box-sizing: border-box;
  h2 {
    margin-bottom: 16px;
  }
`;

export const StyledSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background: ${props => props.heroImage === 'contain' ? 'transparent' : 'var(--ix-platform-gray-bg, #F2F2FC)'};
    &.Mui-expanded {
      min-height: 24px;
    }
    .MuiAccordionSummary-content {
      margin: 12px 0;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .text {
    padding: 0 20px;
  }
`;

export const LabelElement = styled.span`
  position: relative;
  &:after {
    content: ", ";
  }
  &:last-child {
    &:after {
      content: "";
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    background: transparent;
  }
`;

export const Wrapper = styled.div`
  z-index: 1;
  position: relative;
`;
