import { useEffect } from 'react';

const useDefaultValue = (data, formattedData, handleChange, path) => {
	useEffect(() => {
		if(data && formattedData.length) {
			const validValue = formattedData.find((item) => item.name.toLowerCase() === data.toLowerCase());
			if(validValue) {
				handleChange(path, validValue.name);
			} else {
				handleChange(path, formattedData[0].name || undefined);
			}
			return;
		}
		!data && formattedData.length > 0 && handleChange(path, formattedData[0].name);
	}, [formattedData]);

};

export default useDefaultValue;
