/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Multistep from '../Multistep';
import {
	fetchResults,
	updateApplicationForm,
	fetchDynamicSchema,
	fetchBackApplicationProcess
} from '../requests';
import useSchemaSteps from 'utils/useSchemaSteps';
import getUserData from 'utils/getUserData';
import {StageContext} from '..';
import {withPixelTracking} from '../../HOC/withPixelTracking';
import {stepVariantCheck, collectResultData, checkApplicationProcess, openInNewTab} from '../utils';
import CoverageRedirect from 'pages/CoverageRedirect';

const conditionCheck = (o) => o.type?.includes('array') ? !o.items.default.length : !o.default;

const ConfirmationStage = ({schema, applicationFormId, setWorkflowResults, setSecondSchema, setFirstSchema}) => {
	const {
		setError,
		toNextStage,
		toPrevStage,
		setAdditionalDynamicStepsAmount,
		setLoading,
		stagesDataRef,
		clientData,
		setStepName,
		stepName,
	} = useContext(StageContext);
	const {
		labelPlacement = 'top',
		userData,
		template = 'default',
		allowSetDataIfUnableToVerify = false,
		layout,
		ConfirmationStage,
		micrositeRedirect,
		clientId,
		button
	} = clientData;
	const [processedSchemas, processedUiSchemas] = useSchemaSteps(
		schema,
		ConfirmationStage?.stepCategories,
		labelPlacement,
		layout
	);

	const [data, setData] = useState(stagesDataRef.current[2]);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		if (!schema) {
			toNextStage();
		}
		const formData = getUserData(userData, schema, template);
		setData(formData);
	}, [schema, template]);

	const handleSetWorkflowResult = () => {
		setWorkflowResults({
			message: 'We were unable to verify your data. ',
			resultRuleName:
				'Thank you for submitting your information for a quote.',
			link: 'https://www.google.com/',
		});
	};

	useEffect(() => {
		if (processedSchemas?.length) {
			setAdditionalDynamicStepsAmount(processedSchemas.length - 1);

			if (
				!allowSetDataIfUnableToVerify &&
				processedSchemas
					.map((i) => Object.values(i.properties))
					.flat(1)
					.every(conditionCheck)
			) {
				handleSetWorkflowResult();
				toNextStage();
			}
		}
	}, [processedSchemas]);

	const onSubmit = async () => {
		setLoading(true);
		let resultData = collectResultData(data);

		const id = await updateApplicationForm(applicationFormId, resultData, setError, clientId, () => {
			handleSetWorkflowResult();
		});

		if (id) {
			setTimeout(() => {
				checkApplicationProcess({
					id,
					setError,
					onFinished: () => onFinished(id, resultData),
					onWaitingForUserInput: () => {
						if(button?.redirectUrl) {
							openInNewTab(`${button.redirectUrl}?applicationFormId=${id}&client_id=${clientId}`);
							setLoading(false);
						} else {
							onWaitingForUserInput(id);
						}
					},
					onAbandoned,
					clientId
				});
			}, 400);
		}
	};

  const onFinished = async (id, resultData) => {
		const workflowResults = await fetchResults(id, setError, clientId);
		if(micrositeRedirect && workflowResults?.link) {
			openInNewTab(`${workflowResults.link}?applicationFormId=${id}&client_id=${clientId}`);
			setLoading(false);
		} else {
			setWorkflowResults(workflowResults);
			stagesDataRef.current[2] = resultData;
			toNextStage();
			setLoading(false);
			setStepName(undefined);
		}
  };

  const onWaitingForUserInput = async (id, goBack, abandoned) => {
		const dynamicSchema = await fetchDynamicSchema(id, setError, clientId);
		const definitions = Object.keys(dynamicSchema.definitions);
		const found = definitions.find((element) => element.indexOf('TechAttribute') > -1);
		const redirectURL = found && dynamicSchema.definitions[found]?.default?.RedirectURL;

		if(abandoned) {
			stagesDataRef.current[1] = undefined;
			setFirstSchema(dynamicSchema);
			onAbandoned();
		} else if(!abandoned && redirectURL) {
			onMicrositeRedirect(redirectURL);
		} else {
			setSecondSchema(dynamicSchema);
			stagesDataRef.current[1] = data;
			setLoading(false);
	
			const stepCategoriesKeys = ConfirmationStage && Object.keys(ConfirmationStage.stepCategories);
			const step = stepCategoriesKeys?.indexOf(stepName);
			if(goBack) {
				setStepName(stepCategoriesKeys[step-1]);
			} else {
				setStepName(stepCategoriesKeys[step+1]);
			}
		}
  };

	const onAbandoned = () => {
		toPrevStage();
		setLoading(false);
	};

	const goBack = async () => {
		setLoading(true);
		let resultData = collectResultData(data);

		const { id } = await fetchBackApplicationProcess(applicationFormId, () => {}, clientId);

		if (id) {
			setTimeout(() => {
				checkApplicationProcess({
					id,
					setError,
					onFinished: () => onFinished(id, resultData),
					onWaitingForUserInput: () => onWaitingForUserInput(id, true),
					onAbandoned: () => onWaitingForUserInput(id, true, true),
					clientId
				});
			}, 600);
		}
	};

	const onMicrositeRedirect = async (redirectURL) => {
		setLoading(true);
		let resultData = {
			TechAttribute: {
				RedirectURL: redirectURL
			}
		};

		const id = await updateApplicationForm(applicationFormId, resultData, setError, clientId, () => {
			handleSetWorkflowResult();
		});

		if (id) {
			setTimeout(() => {
				checkApplicationProcess({
					id,
					setError,
					onFinished: () => onFinished(id, resultData),
					onWaitingForUserInput: () => {
						setLoading(false);
						openInNewTab(`${redirectURL}?applicationFormId=${id}&client_id=${clientId}`);
						setRedirect(true);
					},
					onAbandoned,
					clientId
				});
			}, 400);
		}
	};

	const StepVariantTemplate = stepVariantCheck(template);

	return redirect ? 
		<CoverageRedirect /> : (
		!!processedSchemas?.stepName && (
			<Multistep
				Template={StepVariantTemplate}
				onSubmit={onSubmit}
				goBack={goBack}
				schema={processedSchemas}
				uiSchema={processedUiSchemas}
				data={data}
				setData={setData}
			/>
		)
	);
};
ConfirmationStage.propTypes = {
  schema: PropTypes.any.isRequired,
  applicationFormId: PropTypes.string.isRequired,
  setWorkflowResults: PropTypes.func.isRequired,
  setSecondSchema: PropTypes.func.isRequired,
  setFirstSchema: PropTypes.func.isRequired,
};

export default React.memo(withPixelTracking(ConfirmationStage));
