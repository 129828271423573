import React, {useContext} from 'react';
import {Interweave} from 'interweave';
import PropTypes from 'prop-types';

import {Block, Container} from 'pages/Main/styles';
import Header from '../Header/StepperHeader';
import BannerImage from '../BannerImage';

import useEnterClick from 'utils/useEnterClick';
import {StyleContext} from 'pages/Main';
import {StageContext} from '../Stages';

import {
	Button, 
	Col, 
	FormWrapper, 
	MarginTopBox, 
	Paragraph, 
	Row, 
	Description, 
	Footer
} from 'shared/ui';

function NoMatchingData({ 
	link, 
	message, 
	toPrevStep, 
	stepNumber, 
	stepName 
}) {
	const {
		commonStyles,
		clientData: {fullSize, smallSize, isMicroSite, button, heroImage, NoMatchingData} = {}
	} = useContext(StageContext);
	const {clientWidth} = useContext(StyleContext);

	const handleClick = () => window.open(link, '_blank');

	useEnterClick(handleClick, [link]);

  const smSizeClass = smallSize ? 'ix-container smallSize' : 'ix-container';
	return (
		<Container
			style={commonStyles}
			position={heroImage?.position || 'top'}
			className={fullSize ? 'ix-container fullSize' : smSizeClass}
		>
			{heroImage?.position !== 'contain' ? null :
				<BannerImage heroImage={heroImage} commonStyles={commonStyles} fullSize={fullSize}/>
			}
			<Block>
				<FormWrapper commonStyles={commonStyles}>
					<Header stepNumber={stepNumber} handleBack={toPrevStep}/>
					<Description step={stepName} position='top'/>
					<div className='ix-content' style={{display: 'flex', flexDirection: 'column'}}>
						<Col>
							<MarginTopBox className={'ix-results-message-div'}>
								<Row justifyContent={'center'}>
									<Paragraph
										variant={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
										className={isMicroSite && (clientWidth >= 470) ? 'p1' : 'p3'}
										bold={isMicroSite}
										textAlign={'center'}
										color={commonStyles.components.paragraphs.color || '#2b2c41'}
									>
										<Interweave
											content={message}
										/>
									</Paragraph>
								</Row>
							</MarginTopBox>
							{link &&
								<>
									<MarginTopBox>
										<Row justifyContent={'center'}>
											<Paragraph textAlign={'center'} color={commonStyles.components.paragraphs.color || '#2b2c41'}>
												Please follow link below to provide your information and get your quote.
											</Paragraph>
										</Row>
									</MarginTopBox>
									<MarginTopBox className={'ix-submit-button-div'}>
										<Row justifyContent={'center'}>
											<Button
												variant='contained'
												color='secondary'
												className='secondary'
												onClick={handleClick}
												text={NoMatchingData?.button?.content || button?.content || 'Get a quote'}
												flex={button?.placement === 'fullwidth'}
											/>
										</Row>
									</MarginTopBox>
								</>
							}
							<Description step={stepName} position='bottom'/>
						</Col>
					</div>
				</FormWrapper>
				<Footer/>
			</Block>
		</Container>
	);
}
NoMatchingData.propTypes = {
  link: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  toPrevStep: PropTypes.func.isRequired,
};

export default NoMatchingData;
